package pitchboard.ui.configuration.fieldeditors

import dk.rheasoft.pitchboard.data.StateValue
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import org.w3c.dom.HTMLDivElement
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier

class StateValueListEditor(model: Model<List<StateValue>>) : ListEditor<StateValue>(model) {
    val widthStateName: String by rerenderOnChange("24rem")
    val widthBackgroundColor: String by rerenderOnChange("12rem")
    val widthColor: String by rerenderOnChange("12rem")

    val widthSampleField: String by rerenderOnChange("12rem")

    override fun createNewElement(): StateValue {
        return StateValue()
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: StateValue, index: Int) {
        // Workflow State name
        val sampleLabel = Label("Sample text").apply {
            cssClassModifier("ms-2 me-2")
            styleModifier {
                backgroundColor = element.backgroundColor
                color = element.color
                width = widthSampleField
            }
        }
        input {
            addClass("form-control")
            withStyle {
                width = widthStateName
            }
            withElement {
                value = element.name
                type = "text"
                onchange = {
                    if (value.isNotBlank()) {
                        currentList[index].name = value
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
                if (index == focusAfterRerender) {
                    delayedFocus()
                }
            }
        }
        // background
        input {
            addClass("form-control")
            withStyle {
                width = widthBackgroundColor
            }
            withElement {
                value = element.backgroundColor
                type = "text"
                onkeydown = { keyEvent ->
                    currentList[index].backgroundColor = value
                    elementKeyHandler(keyEvent, element)
                    sampleLabel.rerender()
                }
            }
        }
        // foreground
        input {
            addClass("form-control")
            withStyle {
                width = widthColor
            }
            withElement {
                value = element.color
                type = "text"
                onkeydown = { keyEvent ->
                    currentList[index].color = value
                    elementKeyHandler(keyEvent, element)
                    sampleLabel.rerender()
                }
            }
        }
        // sample field to show color
        add(sampleLabel.html)
    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        div {
            addClass("form-inline")
            label {
                withElement {
                    style.width = widthStateName
                    style.minWidth = widthStateName
                }
                text("Workflow state")
            }
            label {
                withElement {
                    style.width = widthBackgroundColor
                    style.minWidth = widthBackgroundColor
                }
                text("Baggrundsfarve")
            }
            label {
                withElement {
                    style.width = widthColor
                    style.minWidth = widthColor
                }
                text("Skriftfarve")
            }
            label {
                withElement {
                    style.width = widthSampleField
                    style.minWidth = widthSampleField
                }
                text("Eksempel")
            }
        }
    }
}
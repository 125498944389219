package pitchboard.ui.dashboard

import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.*
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtml
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.messages.Messages.Companion.formatDateNoYear
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.UIMainServices
import kotlin.math.round

class WeekOverviewTable(
    val config: ConfigurationValues,
    val weekDataModel: Model<List<WeekCoverageData>>,
    val ideaDataModel: Model<List<DocumentData>>,
    val currentYear: Int,
    val currentWeek: Int
) : KafffeComponent() {

    var currentElement: DocumentData? = null
    val targetDuration = 120

    private val planningHeight = "50vh"

    override fun KafffeHtmlBase.kafffeHtml() =
        bootstrapRow {
            bootstrapCol(ColWidth(ResponsiveSize.sm, 8)) {
                h4 { text("Ugeoversigt " + UIMainServices.planningYear) }
                div {
                    withStyle {
                        maxHeight = planningHeight
                        overflowY = "scroll"
                        display = "block"
                        padding = "1px"
                    }
                    withElement {
                        id = "weekScroll"
                    }
                    weekTable()
                }
            }
            bootstrapCol(ColWidth(ResponsiveSize.sm, 4)) {
                h4 {
                    add(BootstrapButton(
                        Model.of("Opret"),
                        onClick = { UIMainServices.navigateTo("root/dashboardDocument/new") }
                    ).apply {
                        this.cssClassModifier("me-2")
                        iconClasses = "fas fa-folder-plus"
                        color = BasicColor.primary
                    }.html)
                    text("Ideer")
                }
                div {
                    addClass("bg-secondary")
                    withStyle {
                        maxHeight = planningHeight
                        overflowY = "scroll"
                        display = "block"
                        padding = "1px"
                    }
                    withElement {
                        id = "ideaScroll"
                    }
                    ideaList()
                }
            }
        }

    private fun KafffeHtml<HTMLDivElement>.ideaList() {
        div {
            withStyle {
                minHeight = planningHeight
            }
            withElement {
                ondragenter = {
                    true
                }
                ondragover = { dragEvent ->
                    dragEvent.preventDefault()
                }
                ondrop = { dragEvent ->
                    currentElement?.let {
                        UIMainServices.backend.getDocument(currentElement!!.id) { doc ->
                            val yearWeekDuration = YearWeekDuration(
                                currentYear,
                                0,
                                doc.firstBroadcast?.durationMinutes ?: 0
                            )
                            doc.firstBroadcast = yearWeekDuration
                            doc.typedData(
                                PredefinedFields.firstBroadcastYearWeek.fieldId,
                                YearWeekDurationData()
                            ).value =
                                listOf(yearWeekDuration)
                            UIMainServices.backend.saveDocument(doc) {
                                this@WeekOverviewTable.parentOfType(DashBoard::class)?.loadData()
                            }
                        }
                    }
                }
            }

            for (document in ideaDataModel.data) {
                div {
                    addClass("align-middle")
                    withElement {
                        draggable = true
                        ondragstart = {
                            document.also { currentElement = it }
                        }
                        ondblclick = {
                            UIMainServices.navigateTo("root/dashboardDocument/${document.id}")
                        }
                        ondragenter = {
                            true
                        }
                        ondragexit = { dragEvent ->

                        }
                        ondragover = { dragEvent ->
                            dragEvent.preventDefault()
                        }
                        ondrop = { dragEvent ->
                        }
                    }
                    addClass("custom-div-for-idea-table")
                    // TODO find default duration
                    val duration = if (document.duration != 0) document.duration else 60
                    val colors = config.findColorsFromState(document.state)
                    val width = round(((duration * 100).toDouble() / targetDuration))
                    withStyle {
                        color = colors.first
                        backgroundColor = colors.second
                        maxWidth = "${width}%"
                        minWidth = "${width}%"
                        borderColor = "#FFF"
                        borderWidth = "1px"
                        borderStyle = "solid"
                        paddingLeft = "1rem"
                        paddingRight = "1rem"
                    }
                    strong {
                        text(document.name)
                    }
                    withElement {
                        title = document.state
                    }
                    br()
                    text(document.category)
                }

            }

        }
    }


    private fun KafffeHtml<HTMLDivElement>.weekTable() {
        table {
            addClass("table table-striped table-bordered csaware-hover")

            tbody {
                for (dataElement in weekDataModel.data) {
                    tr {
                        if (dataElement.weekNumber == currentWeek) {
                            withStyle {
                                outline = "thin solid"
                            }
                        }
                        withElement {
                            ondragenter = {
                                true
                            }
                            ondragexit = {

                            }
                            ondragover = { dragEvent ->
                                dragEvent.preventDefault()
                            }
                            ondrop = { dragEvent ->
                                currentElement?.let {
                                    UIMainServices.backend.getDocument(currentElement!!.id) { doc ->

                                        val yearWeekDuration = YearWeekDuration(
                                            currentYear,
                                            dataElement.weekNumber,
                                            doc.firstBroadcast?.durationMinutes ?: 60
                                        )
                                        if (yearWeekDuration.durationMinutes <= 0) yearWeekDuration.durationMinutes = 60
                                        doc.firstBroadcast = yearWeekDuration
                                        doc.typedData(
                                            PredefinedFields.firstBroadcastYearWeek.fieldId,
                                            YearWeekDurationData()
                                        ).value =
                                            listOf(yearWeekDuration)
                                        UIMainServices.backend.saveDocument(doc) {
                                            this@WeekOverviewTable.parentOfType(DashBoard::class)?.loadData()
                                        }
                                    }
                                }
                            }
                            id = "week-${dataElement.weekNumber}"
                        }
                        td {
                            withStyle { width = "12rem" }
                            div {
                                text(dataElement.weekNumber.toString())
                                text (": ")
                                val weekStart = weekStart(currentYear, dataElement.weekNumber)
                                text(weekStart.formatDateNoYear() + "-" + weekStart.plusDays(6).formatDateNoYear())
                            }
                        }
                        td {
                            withStyle {
                                padding = "1px"
                            }
                            for (document in dataElement.documentList) {
                                div {
                                    addClass("align-middle")
                                    withElement {
                                        draggable = true
                                        ondragstart = {
                                            document.also { currentElement = it }
                                        }
                                        ondblclick = {
                                            UIMainServices.navigateTo("root/dashboardDocument/${document.id}")
                                        }
                                        ondragenter = {
                                            true
                                        }
                                        ondragexit = { dragEvent ->

                                        }
                                        ondragover = { dragEvent ->
                                            dragEvent.preventDefault()
                                        }
                                        ondrop = { dragEvent ->
                                        }
                                    }
                                    addClass("custom-div-for-coverage-table")
                                    val duration = document.duration
                                    val colors = config.findColorsFromState(document.state)
                                    val width = round(((duration * 100).toDouble() / targetDuration))
                                    withStyle {
                                        color = colors.first
                                        backgroundColor = colors.second
                                        maxWidth = "${width}%"
                                        minWidth = "${width}%"
                                        borderColor = "#FFF"
                                        borderWidth = "1px"
                                        borderStyle = "solid"
                                        paddingLeft = "1rem"
                                        paddingRight = "1rem"
                                    }
                                    strong {
                                        text(document.name)
                                    }
                                    withElement {
                                        title = document.state
                                    }
                                    br()
                                    text(document.category)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}



package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.FdeText
import dk.rheasoft.pitchboard.data.ValueList
import kafffe.bootstrap.form.DropdownString
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLDivElement

class TextEditor(val fieldId: String, model: Model<List<String>>, val fdExt: FdeText) : ListEditor<String>(model) {

    val width1 : String by rerenderOnChange("100%")

    override fun createNewElement(): String {
        return fdExt.startValue;
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: String, index: Int) {
        if (fdExt.valueList.isEnum) {
            val valueModel = Model.ofGetSet({ element }, { value -> currentList[index] = value })
            val choices = fdExt.valueList.values
            val dropdown = DropdownString("col_1_${fieldId}", valueModel, Model.of(choices))
            dropdown.apply {
                // we will need to keep the model in sync for document editors
                onchange { updateValueModel() }
                modifiers.add(StyleModifier { width = width1 })
            }
            add(dropdown.html)
        } else {
            val valueModel = Model.ofGetSet({ element }, { value -> currentList[index] = value })
            val input = InputWithValueList("col_1_$index", valueModel, fdExt.valueList ?: ValueList())
            input.apply {
                onCtrlEnterKey = {  addElement(index + 1) }
                onMoveDownKey = {  onMoveKey(it, element, 1) }
                onMoveUpKey = { onMoveKey(it, element, -1) }

                modifiers.add(StyleModifier { width = width1 })
            }
            if (index == focusAfterRerender) {input. delayedFocus()}
            add(input.html)
        }
    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
    }

}

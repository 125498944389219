package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

enum class RoleType(val groupRole: Boolean) {
    /**
     * The System admin is allowed to access all groups and create new and delete groups.
     * Users can be assigned groupadmin, edit or view role to any group
     */
    SYSADMIN(groupRole = false),
    /**
     * The Groups admin is allowed to access only the group where the user has this role assigned.
     * The group can be edited, and user can be granted or revoked the group admin, edit or view role to the given group.
     * Document types can be viewed and edited.
     */
    GROUPADMIN(groupRole = true),
    /**
     * The edit role gives access to document create and edit, view and publish on a group.
     * The group can be edited, and user can be granted or revoked the group admin, edit or view role to the given group.
     */
    EDIT(groupRole = true),
    /**
     * The view role gives access to document view and publish on a group.
     * The group can be edited, and user can be granted or revoked the group admin, edit or view role to the given group.
     */
    VIEW(groupRole = true)
}

@Serializable
data class User(
    var id: Long = -1L,
    var firstName: String = "",
    var lastName: String = "",
    var email: String = "",
    @Contextual
    var created: Timestamp  = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp  = timestampNow(),
    var updatedBy: String = "system",
    var systemAdmin: Boolean = false
) {

}

@Serializable
data class Group(
    var id: Long = -1L,
    var groupName: String = "",
    var description: String = "",
    @Contextual
    var created: Timestamp = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp = timestampNow(),
    var updatedBy: String  = "system"
) {

}
/**
 * Group json serialization
 */
fun Group.toJsonString() = RheaJson.toJsonString(this, Group.serializer())
fun Group.Companion.fromJsonString(jsonStr: String): Group =
    RheaJson.fromJsonString(jsonStr, serializer())


@Serializable
data class UsersGroupsRolesRelation(
    var id: Long,
    var userId: Long,
    var groupId: Long,
    var roleType: RoleType,
    @Contextual
    var created: Timestamp = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp = timestampNow(),
    var updatedBy: String
) {

}

/**
 * Holds information of current user
 */
@Serializable
data class UserInformation(
    val user: User,
    val groups: List<Group>,
    val groupid2Role: Map<Long, Set<RoleType>>,
    val selectedGroupId: Long
) {
    fun hasRole(role: RoleType): Boolean = role in roles

    val selectedGroup: Group
        get() = groups.find { selectedGroupId == it.id } ?: Group()
    val roles: Set<RoleType>
        get() = groupid2Role[selectedGroupId] ?: setOf()
}


/**
 * Json of UserInformation serialization
 */
fun UserInformation.toJsonString() = RheaJson.toJsonString(this, UserInformation.serializer())
fun UserInformation.Companion.fromJsonString(jsonStr: String): UserInformation =
    RheaJson.fromJsonString(jsonStr, serializer())

package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Serializable

@Serializable
data class AuthProvider(var name: String, var url: String)

@Serializable
data class AuthProviders(var providers : List<AuthProvider>)

/**
 * Group json serialization
 */
fun AuthProviders.toJsonString() = RheaJson.toJsonString(this, AuthProviders.serializer())
fun AuthProviders.Companion.fromJsonString(jsonStr: String): AuthProviders =
    RheaJson.fromJsonString(jsonStr, serializer())


package pitchboard.ui.dashboard

import dk.rheasoft.pitchboard.data.*
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import pitchboard.ui.UIMainServices
import pitchboard.ui.backend.ServerUpdateAware
import kotlin.js.Date
import kotlin.math.round

class DashBoard : KafffeComponent(), ServerUpdateAware {
    private var weekCoverage: WeekCoverage? = null
    private var ideaCoverage: IdeaCoverage? = null
    private var categoryCoverage: CategoryCoverage? = null
    private var configurationValues = UIMainServices.configurationService.current()!!
    val categoryLabel = addChild(Label(Model.of("Kategori dækning")))
    val currentWeek: Int
        get() = if (currentYear == timestampNow().getFullYear()) timestampNow().weekNumber() else 1
    val currentYear: Int
        get() = UIMainServices.planningYear

    fun formatDuration(minutes: Int): String {
        if (minutes < 0) return ""
        val hours = minutes / 60
        val remainMenutes = minutes % 60
        return hours.toString().padStart(2, '0') + ":" + remainMenutes.toString().padStart(2, '0')
    }

    private var filterInput: HTMLInputElement? = null

    init {
        loadData()
    }

    val categoryOverviewPanel = addChild(
        CategoryOverviewPanel(configurationValues,
        Model.ofGet { categoryCoverage?.categoryMap?: mapOf() }
    ))

    val weekOverviewTable = addChild(
        WeekOverviewTable(configurationValues,
        Model.ofGet { weekCoverage?.weekList ?: listOf() },
        Model.ofGet { ideaCoverage?.ideaList?: listOf() },
        currentYear, currentWeek))

    val statusLegendPanel = addChild(
        pitchboard.ui.dashboard.StatusLegend(configurationValues).apply {
            this.cssClassModifier("mt-2 mb-2")
        }
    )

    fun loadData() {
        UIMainServices.alerts.clearAlerts()
        // TODO how do we handle year
        val filer = filterInput?.value ?: ""

        UIMainServices.backend.getCoverage(currentYear, 0, 1000, this::receiveCoverageData, filer)
    }

    private fun receiveCoverageData(response: QueryResult<CategoryCoverageRecordFromDatabase>) {
        val categoryCoverageList = response.result
        val plannedList = categoryCoverageList.filter { it.year > 0 && it.week > 0 }
        weekCoverage = buildWeekList(currentYear, plannedList)
        ideaCoverage = buildIdeaList(categoryCoverageList)
        categoryCoverage = buildCategoryList(configurationValues, plannedList)
        console.log(categoryCoverage.toString())
        val scrollTopValue = document.getElementById("weekScroll")?.let {
             (it as HTMLElement).scrollTop
        }
        val scrollIdea = document.getElementById("ideaScroll")?.let {
            (it as HTMLElement).scrollTop
        }
        weekOverviewTable.rerender()
        categoryOverviewPanel.rerender()
        document.getElementById("weekScroll")?.let {
            if (scrollTopValue !=  null)
                (it as HTMLElement).scrollTop = scrollTopValue
        }
        document.getElementById("ideaScroll")?.let {
            if (scrollIdea != null)
                (it as HTMLElement).scrollTop = scrollIdea
        }
    }

    private fun buildIdeaList(categoryList: List<CategoryCoverageRecordFromDatabase>): IdeaCoverage {
        var ideaCoverage: IdeaCoverage = IdeaCoverage()
        categoryList.filter {
            it.week <= 0 || it.year <= 0 }.forEach {
            ideaCoverage.ideaList.add(
                DocumentData(
                    it.id,
                    it.name,
                    it.category,
                    it.state,
                    it.duration
                )
            )
        }

        return ideaCoverage
    }

    private fun buildWeekList(year: Int, categoryList: List<CategoryCoverageRecordFromDatabase>): WeekCoverage {
        var weekCoverage: WeekCoverage = WeekCoverage()
        for (index in minWeekNumber..maxWeekNumber) {
            weekCoverage.weekList.add(WeekCoverageData(year, index, mutableListOf()))
        }

        categoryList.forEach { coverageRecord ->
            weekCoverage.weekList.get(coverageRecord.week - 1).documentList.add(
                DocumentData(
                    coverageRecord.id,
                    coverageRecord.name,
                    coverageRecord.category,
                    coverageRecord.state,
                    coverageRecord.duration
                )
            )
        }
        return weekCoverage
    }

    private fun buildCategoryList(configurationValues: ConfigurationValues, categoryCoverageList: List<CategoryCoverageRecordFromDatabase>): CategoryCoverage {

        val groupByCategory = categoryCoverageList.groupBy { it.category }

        val categoryMap: Map<String, Map<String, Int>> = groupByCategory.mapValues {
            val groupByState = it.value.groupBy { it.state }
            groupByState.mapValues { it.value.sumBy { it.duration } }
        }

        return CategoryCoverage(categoryMap)
    }

    private fun buildCategoryMap(configurationValues: ConfigurationValues): MutableMap<String, MutableMap<String, Long>>  {

        val map = mutableMapOf<String, MutableMap<String, Long>>()
        for (category in configurationValues.categoryValueList) {
            val stateMap = mutableMapOf<String, Long>()
            for (status in configurationValues.stateValueList) {
                stateMap.put(status.name, 0L)
            }
            stateMap.put("Unknown", 0)
            map.put(category.name, stateMap)
        }
        val stateMap = mutableMapOf<String, Long>()
        for (status in configurationValues.stateValueList) {
            stateMap.put(status.name, 0L)
        }
        stateMap.put("Unknown", 0)
        map.put("Unknown", stateMap)
        return map
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            addClass("rp-content mt-2")
            add(categoryOverviewPanel.html)
            add(statusLegendPanel.html)
            add(weekOverviewTable.html)

            window.setTimeout({
                var weekNumber  = currentWeek - 2
                if (weekNumber < 1) weekNumber = 1
                if (weekNumber > maxWeekToScrollTo) {
                    weekNumber = maxWeekToScrollTo
                }
                val weekId = """week-$weekNumber"""
                var currentWeek = document.getElementById(weekId)
                currentWeek?.scrollIntoView()
            }, 300)
        }
    }

    companion object {
        const val minWeekNumber = 1
        const val maxWeekNumber = 53
        const val pageSize = 15
        const val maxWeekToScrollTo = maxWeekNumber - pageSize
    }

    override fun onServerUpdate(event: ServerUpdateEvent) {
        if (event is DocumentSaveEvent) {
            loadData()
        }
    }
}

package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.ValueList
import kafffe.bootstrap.form.FormInput
import kafffe.core.*
import kafffe.core.modifiers.HtmlElementModifier
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.dom.events.KeyboardEvent
import org.w3c.dom.events.MouseEvent
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass

/**
 * Holds an editor thats allows selection of predefined values from a list, either by keyboard or by mouse.
 * Based on SingleEditSelect but configurable to use list and possible allow other values.
 * The input control will hold the current value.
 * TODO support for not using list and validate to list
 */
class InputWithValueList(
    override val htmlId: String,
    valueModel: Model<String>,
    private val valueList: ValueList
) : KafffeComponentWithModel<String>(valueModel), FormInput {

    var updateModelOnChange: Boolean = true
    var matchInside: Boolean = true

    /**
     * Modifiers to tweak HTMLInputElement
     */
    val modifiersInputControl = mutableListOf<HtmlElementModifier>()

    var currentValue = model.data
        set(value) {
            field = value
            if (updateModelOnChange) {
                updateValueModel()
            }
        }

    override fun updateValueModel() {
        model.data = currentValue
    }

    private lateinit var inputControl: HTMLInputElement
    private lateinit var dropdown: HTMLDivElement

    fun delayedFocus() {
        window.setTimeout({ inputControl.focus() }, 300)
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("")
            withElement {
                with(style) {
                    display = "inline-block"
                    position = "relative"
                    width = "1%"
                }
            }
            val container = this.element!!
            input {
                addClass("form-control")
                withElement {
                    inputControl = this
                    id = htmlId
                    style.width = "100%"
                    autocomplete = "off"
                    type = "text"
                    value = currentValue
                    onblur = {
                        window.setTimeout({
                            hideDropdown();
                        }, 300)
                        it
                    }
                    onkeydown = { onkey(it) }
                    oninput = {
                        currentValue = value
                        if (valueList.autocomplete) {
                            renderMatches()
                        }
                    }
                    modifiersInputControl.forEach { it.modify(this) }
                }
            }
            div {
                addClass("sd_dropdown bg-light text-dark")
                withElement {
                    dropdown = this
                    style.width = "100%"
                }
            }
        }

    private fun hideDropdown() {
        dropdown.style.display = "none"
    }

    private fun showDropdown() {
        dropdown.style.display = "block"
    }

    /**
     * Called on CTRL-Enter
     */
    var onCtrlEnterKey: (keyEvent: KeyboardEvent) -> Unit = {}

    /**
     * Called on CTRL-ArrowUp
     */
    var onMoveUpKey: (keyEvent: KeyboardEvent) -> Unit = {}
    /**
     * Called on CTRL-ArrowDown
     */
    var onMoveDownKey: (keyEvent: KeyboardEvent) -> Unit = {}

    protected fun onkey(keyEvent: KeyboardEvent) {
        if (keyEvent.ctrlKey) {
            when (keyEvent.key) {
                // Escape
                "ArrowDown" -> onMoveDownKey(keyEvent)
                "ArrowUp" -> onMoveUpKey(keyEvent)
                "Enter" -> onCtrlEnterKey(keyEvent)
            }
        } else
            when (keyEvent.key) {
                // Escape
                "ArrowDown" -> selectNext()
                "ArrowUp" -> selectPrev()
                "Enter" -> {
                    if (valueList.autocomplete) {
                        val m = matches();
                        if (selectIndex in 0 until m.size) {
                            updateChoice(m[selectIndex])
                        }
                    }
                    keyEvent.preventDefault()
                }
            }
    }

    val maxMatches: Int = 7
    var selectIndex: Int = -1
    fun selectNext() {
        if (maxMatches >= selectIndex + 1) {
            ++selectIndex
        }
        select(selectIndex)
    }

    fun selectPrev() {
        if (selectIndex > 0) {
            --selectIndex
        }
        select(selectIndex)
    }

    fun select(index: Int) {
        val matches = matches()
        if (index in 0 until matches.size) {
            // set and remove "sd_selected" class
            dropdown.children.asList().forEachIndexed { i, element ->
                if (i == index) {
                    element.addClass("sd_selected")
                } else {
                    element.removeClass("sd_selected")
                }
            }
        }
    }

    fun matches(): List<String> {
        val txt = inputControl.value ?: ""
        return if (txt.length > 0) {
            matchesText(txt)
        } else {
            valueList.values.take(maxMatches).toList()
        }
    }

    protected fun matchesText(txt: String): List<String> {
        return if (matchInside) {
            (valueList.values
                .filter { it.startsWith(txt, ignoreCase = true) }
                .union(valueList.values.filter { it.contains(txt, ignoreCase = true) })
                    )
                .take(maxMatches).toList()
        } else {
            valueList.values
                .filter { it.startsWith(txt, ignoreCase = true) }
                .take(maxMatches).toList()

        }
    }

    fun renderMatches() {
        selectIndex = -1
        dropdown.innerHTML = ""
        val htmlConsumer = KafffeHtml(dropdown)
        val matches = matches()

        for (match in matches) {
            htmlConsumer.div {
                addClass("sd_dropdown_item")
                text(match)
                withElement {
                    onclick = { evt: MouseEvent ->
                        updateChoice(match)
                        evt.preventDefault()
                    }
                }
            }
        }

        if (matches.isEmpty()) hideDropdown() else showDropdown()
    }

    private fun updateChoice(newChoice: String) {
        currentValue = newChoice
        inputControl.value = currentValue
        window.setTimeout({ hideDropdown() }, 200)
    }

    private fun isSelected(choice: String): Boolean = currentValue == choice

    override fun component(): KafffeComponent = this

    override fun validate(): Boolean =
        (!valueList.isEnum) || valueList.values.contains(currentValue)

    override var validationMessageModel: Model<String> = Model.of("")

}

package pitchboard.ui.ideas

import dk.rheasoft.pitchboard.data.PitchBoardDocument
import dk.rheasoft.pitchboard.data.QueryResult
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.Messages.Companion.formatDateTime
import pitchboard.ui.UIMainServices
import pitchboard.ui.documents.SearchBar
import kotlin.math.ceil

class IdeaList : KafffeComponent() {
    val titleLabel = addChild(Label(Model.of("Ideer")))

    val searchBar = addChild(SearchBar(::loadData))

    val table = addChild(BootstrapTable.create<PitchBoardDocument>(listOf()) {
        rowClickHandler = { document, _ -> showIdea(document) }

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))

        col(Model.of("Id"), { Label(it.id.toString()) })
        col(Model.of("Titel"), { Label(it.name) })
        col(Model.of("Seneste opdatering"), { Label(it.lastUpdate.formatDateTime()) })
        col(Model.of("Opdateret af"), { Label(it.updatedBy) })
        col(Model.of("Type"), { Label(UIMainServices.documentTypeService[it.typeId]?.name ?: "") })
        col(Model.of("Kategori"), { Label(it.category.text1) })
        col(Model.of("Beskrivelse"), { Label(it.description) })
        col(Model.of("Oprettet"), { Label(it.created.formatDateTime()) })
    })

    var pageSize: Int = 25

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))

    init {
        pager.changeListeners.add({ loadData() })
        loadData()
    }

    fun loadData(filter: String = "") {
        UIMainServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        UIMainServices.backend.getIdeas(offset, pageSize, this::receiveData, filter)
    }

    private fun receiveData(response: QueryResult<PitchBoardDocument>) {
        table.data = response.result
        val pageCount = ceil(table.data.size.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
    }

    fun showIdea(doc: PitchBoardDocument) {
        UIMainServices.navigateTo("root/idea/${doc.id}")
    }

    fun showNewIdea() {
        UIMainServices.navigateTo("root/idea/new")
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            div {
                addClass("rp-content mt-2")
                div {
                    addClass("h4 rp-content-header d-flex gap-2")
                    add(BootstrapButton(Model.of("Opret"), onClick = { showNewIdea() }).apply {
                        iconClasses = "fas fa-folder-plus"
                        color = BasicColor.primary
                    }.html)
                    add(titleLabel.html)
                    div {
                        withStyle { flexGrow = "1" }
                        add(searchBar.html)
                     }
                }
                add(table.html)
                div {
                    addClass("rp-content-footer")
                    add(paginator.html)
                }
            }
        }
    }
}
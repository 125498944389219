package pitchboard.ui.documents.fieldeditors

import EasyMDE
import EasyMDE.Companion.cleanBlock
import EasyMDE.Companion.drawHorizontalRule
import EasyMDE.Companion.drawTable
import EasyMDE.Companion.drawLink
import EasyMDE.Companion.redo
import EasyMDE.Companion.toggleBlockquote
import EasyMDE.Companion.toggleBold
import EasyMDE.Companion.toggleCodeBlock
import EasyMDE.Companion.toggleFullScreen
import EasyMDE.Companion.toggleHeadingBigger
import EasyMDE.Companion.toggleHeadingSmaller
import EasyMDE.Companion.toggleItalic
import EasyMDE.Companion.toggleOrderedList
import EasyMDE.Companion.togglePreview
import EasyMDE.Companion.toggleSideBySide
import EasyMDE.Companion.toggleStrikethrough
import EasyMDE.Companion.toggleUnorderedList
import EasyMDE.Companion.undo
import kafffe.bootstrap.external.jsCreate
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import kotlinx.browser.window

class MarkDownInput(val htmlId: String, model: Model<String>) : KafffeComponentWithModel<String>(model),
    FormValueProvider {
    private lateinit var mde: EasyMDE
    private var initValue = model.data

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        textarea {
            addClass("form-control")
        }

    override fun attach() {
        super.attach()
        // await rendered in document
        window.setTimeout(::applyMde, 100)
    }

    override fun detach() {
        initValue = mde.value()
        super.detach()
    }

    private fun tb(initialize: EasyMDE.ToolbarIcon.() -> Unit) = jsCreate<EasyMDE.ToolbarIcon>().apply { initialize() }

    var toolbarButtons: Array<dynamic> = arrayOf(
        tb {
            name = "bold"
            action = toggleBold
            className = "fa fa-bold"
            title = "Fed"
        },
        tb {
            name = "italic"
            action = toggleItalic
            className = "fa fa-italic"
            title = "Kursiv"
        },
        tb {
            name = "strikethrough"
            action = toggleStrikethrough
            className = "fa fa-strikethrough"
            title = "Gennemstregning"
        },
        "|",
        tb {
            name = "heading"
            action = toggleHeadingSmaller
            className = "fa fa-header fa-heading"
            title = "Overskrift"
        },
        tb {
            name = "heading-smaller"
            action = toggleHeadingSmaller
            className = "fa fa-header fa-heading header-smaller"
            title = "Mindre overskrift"
        },
        tb {
            name = "heading-bigger"
            action = toggleHeadingBigger
            className = "fa fa-header fa-heading header-bigger"
            title = "Større overskrift"
        },
        /*
        tb {
            name = "heading-1"
            action = toggleHeading1
            className = "fa fa-header fa-heading header-1"
            title = "Big Heading"
        },
        tb {
            name = "heading-2"
            action = toggleHeading2
            className = "fa fa-header fa-heading header-2"
            title = "Medium Heading"
        },
        tb {
            name = "heading-3"
            action = toggleHeading3
            className = "fa fa-header fa-heading header-3"
            title = "Small Heading"
        },*/
        "|",
        tb {
            name = "code"
            action = toggleCodeBlock
            className = "fa fa-code"
            title = "Kodeblok"
        },
        tb {
            name = "quote"
            action = toggleBlockquote
            className = "fa fa-quote-left"
            title = "Citeringsblok"
        },
        tb {
            name = "unordered-list"
            action = toggleUnorderedList
            className = "fa fa-list-ul"
            title = "Liste uden nummerering"
        },
        tb {
            name = "ordered-list"
            action = toggleOrderedList
            className = "fa fa-list-ol"
            title = "Liste med nummerering"

        },
        tb {
            name = "clean-block"
            action = cleanBlock
            className = "fa fa-eraser"
            title = "Fjern overskrift- og listeformatering"
        },
        "|",
        tb {
            name = "link"
            action = drawLink
            className = "fa fa-link"
            title = "Create Link"

        },
        /*
        tb {
            name = "image"
            action = drawImage
            className = "fa fa-image"
            title = "Insert Image"

        },

         */
        tb {
            name = "table"
            action = drawTable
            className = "fa fa-table"
            title = "Tabel"
        },
        tb {
            name = "horizontal-rule"
            action = drawHorizontalRule
            className = "fa fa-minus"
            title = "Vandret linie"
        },
        "|",
        tb {
            name = "preview"
            action = togglePreview
            className = "fa fa-eye"
            noDisable = true
            title = "Toggle Preview"

        },
        tb {
            name = "side-by-side"
            action = toggleSideBySide
            className = "fa fa-columns"
            noDisable = true
            noMobile = true
            title = "Toggle Side by Side"

        },
        tb {
            name = "fullscreen"
            action = toggleFullScreen
            className = "fa fa-arrows-alt"
            noDisable = true
            noMobile = true
            title = "Toggle Fuldskærm"

        },
        "|",
        tb {
            name = "guide"
            action = "https://www.markdownguide.org/basic-syntax/"
            className = "fa fa-question-circle"
            noDisable = true
            title = "Markdown Guide (Formatterings tegn)"

        },
        "|",

        tb {
            name = "undo"
            action = undo
            className = "fa fa-undo"
            noDisable = true
            title = "Undo"
        },
        tb {
            name = "redo"
            action = redo
            className = "fa fa-repeat fa-redo"
            noDisable = true
            title = "Redo"
        }
    )

    private fun applyMde() {
        val options: EasyMDE.Options = jsCreate()
        options.apply {
            element = html
            autoDownloadFontAwesome = false
            initialValue = initValue
            toolbar = toolbarButtons
            minHeight = "auto"
            spellChecker = false
        }
        mde = EasyMDE(options)
    }

    override fun updateValueModel() {
        model.data = mde.value()
    }
}


package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.FieldDefinition
import dk.rheasoft.pitchboard.data.FieldType
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.documenttypes.fielddefinition.FieldDefinitionDialog

class FieldDefinitionsEditor(
    model: Model<List<FieldDefinition>>,
    val headerLabels: List<String> = listOf(
        "Id",
        "Navn",
        "Type",
        "Beskrivelse",
        "Hjælp",
        "Under overskrifter"
    )
) : ListEditor<FieldDefinition>(model) {

    val widthId: String by rerenderOnChange("10%")
    val widthLabel: String by rerenderOnChange("10%")
    val widthType: String by rerenderOnChange("10%")
    val widthDescription: String by rerenderOnChange("30%")
    val widthHelp: String by rerenderOnChange("25%")

    override fun createNewElement(): FieldDefinition {
        return FieldDefinition(
            id =  "",
            label = "",
            type =  FieldType.TextOneline,
            description =  "",
            help =  ""
        )
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: FieldDefinition, index: Int) {
        input {
            addClass("form-control")
            withElement {
                value = element.id
                type = "text"
                style.width = widthId
                onchange = {
                    if (value.isNotBlank()) {
                        element.id = value
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
                if (index == focusAfterRerender) {
                    delayedFocus()
                }
            }
        }
        input {
            addClass("form-control")
            withElement {
                value = element.label
                type = "text"
                style.width = widthLabel
                onchange = {
                    if (value.isNotBlank()) {
                        element.label = value
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }
        add(FieldTypeDropdown("type$index", Model.of(element.type), { element.type = it }).apply {
            styleModifier { width = widthType }
        }.html)
        textarea {
            addClass("form-control")
            withElement {
                value = element.description
                style.width = widthDescription
                rows = 1
                onchange = {
                    if (value.isNotBlank()) {
                        element.description = value
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }
        textarea {
            addClass("form-control")
            withElement {
                value = element.help
                style.width = widthHelp
                rows = 1
                onchange = {
                    if (value.isNotBlank()) {
                        element.help = value
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }
        button {
            addClass("btn btn-info")
            i {
                addClass("fas fa-pencil-alt")
            }
            withElement {
                title = "Edit feltdefinition"
            }
            withElement {
                onclick = {
                    FieldDefinitionDialog(
                        Model.of(
                            element
                        ), isNew = false
                    ).apply {
                        onSubmitOk = {
                            this@FieldDefinitionsEditor.rerender()
                        }
                        attach()
                    }
                }
            }
        }

    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        if (headerLabels.size >= 6) {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = widthId
                        style.minWidth = widthId
                    }
                    text(headerLabels[0])
                }
                label {
                    withElement {
                        style.width = widthLabel
                        style.minWidth = widthLabel
                    }
                    text(headerLabels[1])
                }
                label {
                    withElement {
                        style.width = widthType
                        style.minWidth = widthType
                    }
                    text(headerLabels[2])
                }
                label {
                    withElement {
                        style.width = widthDescription
                        style.minWidth = widthDescription
                    }
                    text(headerLabels[3])
                }
                label {
                    withElement {
                        style.width = widthHelp
                        style.minWidth = widthHelp
                    }
                    text(headerLabels[4])
                }
            }
        }
    }

}

package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.FdeYearWeekDuration
import dk.rheasoft.pitchboard.data.YearWeekDuration
import dk.rheasoft.pitchboard.data.ValueList
import dk.rheasoft.pitchboard.data.timestampNow
import kafffe.bootstrap.form.Dropdown
import kafffe.bootstrap.form.DropdownString
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLDivElement

class YearWeekDurationListEditor(
    val fieldId: String,
    model: Model<List<YearWeekDuration>>,
    val fdExt: FdeYearWeekDuration
) :
    ListEditor<YearWeekDuration>(model) {

    var minutesStep: Int by rerenderOnChange(fdExt.stepMinutes)

    // Leave room for buttons,
    // TODO maybe put these in FdeYearWeekDuration to allow config
    var widthYear: String by rerenderOnChange("6rem")
    var widthWeek: String by rerenderOnChange("4.2rem")
    var widthDuration: String by rerenderOnChange("6rem") // * 2 (from and to)
    var spacing:String by rerenderOnChange("0.5rem")

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        div {
            addClass("form-inline")
            label {
                withStyle {
                    width = widthYear
                    marginRight = spacing
                }
                text(fdExt.labelYear)
            }
            label {
                withStyle {
                    width = widthWeek
                    marginRight = spacing
                }
                text(fdExt.labelWeek)
            }
            label {
                withStyle {
                    width = widthDuration
                    marginRight = spacing
                }
                text(fdExt.labelDuration)
            }
        }
    }

    fun formatDuration(minutes: Int): String {
        if (minutes < 0) return ""
        val hours = minutes / 60
        val remainMinutes = minutes % 60
        return hours.toString().padStart(2, '0') + ":" + remainMinutes.toString().padStart(2, '0')
    }

    fun parseDuration(duration: String): Int {
        val hourMinutes = duration.split(":")
        if (hourMinutes.size < 2) {
            return 0
        }
        try {
            val totalMinutes = hourMinutes[0].toInt() * 60 + hourMinutes[1].toInt()
            return totalMinutes
        } catch (e: Exception) {
            return 0
        }
    }

    private val durationValueList: ValueList
        get() = ValueList().apply {
            autocomplete = true
            values = listOf("") + (fdExt.minimumMinutes .. fdExt.maximumMinutes step fdExt.stepMinutes).map { formatDuration(it) }
        }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: YearWeekDuration, index: Int) {
        val year = DropdownIntSpaceZero("${fieldId}_year_$index", Model.of(element).property(YearWeekDuration::year), Model.of(listOf(0) + fdExt.years))
        val week = DropdownIntSpaceZero("${fieldId}_week_$index", Model.of(element).property(YearWeekDuration::week), Model.of((0..53).toList()))

        val durationStringModel: Model<String> = Model.of(element).func(
            {formatDuration(it.data.durationMinutes)},
            {m, v -> m.data.durationMinutes = parseDuration(v)}
        )
        val durationInput = DropdownString(
            "${fieldId}_duration_$index",
            durationStringModel,
            Model.of(durationValueList.values)
        )

        year.apply {
            modifiers.add(StyleModifier{width = widthYear; marginRight = spacing })
            onchange {
                updateValueModel()
                if (model.data != 0 && element.week == 0) {
                    element.week = 1
                    week.rerender()
                }
                if (model.data != 0 && element.durationMinutes == 0) {
                    element.durationMinutes = fdExt.durationDefaultMinutes
                    durationInput.rerender()
                }
            }
        }

        week.apply {
            modifiers.add(StyleModifier{width = widthWeek; marginRight = spacing })
            onchange {
                updateValueModel()
                if (model.data != 0 && element.year == 0) {
                    element.year = timestampNow().getFullYear()
                    year.rerender()
                }
                if (model.data != 0 && element.durationMinutes == 0) {
                    element.durationMinutes = fdExt.durationDefaultMinutes
                    durationInput.rerender()
                }
            }
        }
        durationInput.apply {
            modifiers.add(StyleModifier { width = widthDuration; marginRight = spacing })
            onchange { updateValueModel() }
        }

        add(year.html)
        add(week.html)
        add(durationInput.html)
    }

    private lateinit var newValue: () -> YearWeekDuration

    override fun createNewElement(): YearWeekDuration {
        return YearWeekDuration(timestampNow().getFullYear(), 1, fdExt.durationDefaultMinutes)
    }

}

class DropdownIntSpaceZero(idInput: String, valueModel: Model<Int>, choiceModel: Model<List<Int>>) :
    Dropdown<Int>(idInput, valueModel, choiceModel) {

    override fun display(choice: Int) = if (choice == 0) "" else choice.toString()
    override fun valueToString(value: Int) = value.toString()
    override fun valueFromString(strValue: String) = strValue.toInt()
}
package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.DocumentType
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel
import pitchboard.ui.UIMainServices

class DocumentTypeService {
    val types: Model<List<DocumentType>> = RefreshingCacheModel<List<DocumentType>>(::refresh, listOf(), timeToLiveSeconds = 60 * 60)

    public fun expireModels() {

    }
    fun refresh() {
        refresh(types)
    }

    fun refresh(model: Model<List<DocumentType>>) {
        UIMainServices.backend.getDocumentTypes(0, 1000) { model.data = it.result }
    }

    operator fun get(typeId: Long): DocumentType? = types.data.find { typeId == it.id }

}
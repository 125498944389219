package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FdeText
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.property

class TextExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeText>) :
    ExtEditor<FdeText>(parentConsumer, extModel) {
    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeText::startValue.name -> "Start værdi"
                        FdeText::valueList.name -> "Værdier"
                        else -> key
                    }
                )
        }

        input(FdeText::startValue)
        addValueListEditor(model.property(FdeText::valueList), "")
    }
}

package pitchboard.ui

import dk.rheasoft.pitchboard.data.Timestamp
import kafffe.bootstrap.Alert
import kafffe.bootstrap.AlertsPanel
import kafffe.bootstrap.BootstrapLevel
import kafffe.core.NavigationElement
import kafffe.core.NavigationPath
import kafffe.core.ServiceRegistry
import kafffe.messages.Messages_da
import kafffe.messages.Messages_en
import pitchboard.ui.backend.Backend
import pitchboard.ui.documenttypes.DocumentTypeService

object UIMainServices {
    // alerts registered by main it is a UI component.
    val alerts by lazy { ServiceRegistry.findByType(AlertsPanel::class)!! }

    val backend = Backend(::exceptionHandler)

    val documentTypeService = DocumentTypeService()

    val configurationService = ConfigurationService()
    var planningYear: Int  = Timestamp().getFullYear()

    private fun exceptionHandler(@Suppress("UNUSED_PARAMETER") status: Int, message: String) {
        alerts.addAlertWithDismiss(Alert(message, BootstrapLevel.error), 10)
    }

    fun init() {
        ServiceRegistry.register("lang_da", Messages_da())
        ServiceRegistry.register("lang_en", Messages_en())
        ServiceRegistry.register("DocumentTypeService", documentTypeService)
        documentTypeService.refresh()
        configurationService.refresh()
    }

    var rootNavigation: NavigationElement? = null
    fun navigateTo(path: NavigationPath) = rootNavigation?.navigateTo(path)
    fun navigateTo(path: String) = navigateTo(NavigationPath.fromString(path))
}
package pitchboard.ui.documents

import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.modifiers.onclick

class SearchBar(val onSearch: (search: String) -> Unit) : KafffeComponent() {

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("input-group sd_search_bar ms-auto")
            withStyle { width = "fit-content" }
            val inputElm = input {
                val inputElm = element!!
                addClass("sd_search_input bg-info text-white")
                withElement {
                    type = "text"
                    onfocus = { it }
                    oninput = {
                        // Todo limit time treshold filter before reload
                        onSearch(value)
                    }
                    onkeydown = {
                        when (it.key) {
                            "Escape" -> inputElm.blur() // TODO CLEAR FILTER
                            "Enter" -> inputElm.blur() // todo filter
                        }
                        it
                    }
                }
            }.element!!
            button {
                addClass("sd_search_icon input-text btn btn-secondary")
                onclick {
                    inputElm.focus()
                }
                i {
                    addClass("fas fa-search")
                }
            }
        }

}
package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Serializable

@Serializable
sealed class ServerUpdateEvent  {
    abstract val userId: Long
    abstract val userEmail: String
    abstract val groupId: Long
}

fun ServerUpdateEvent.toJsonString() = RheaJson.toJsonString(this, ServerUpdateEvent.serializer())
fun ServerUpdateEvent.Companion.fromJsonString(jsonStr: String): ServerUpdateEvent =
    RheaJson.fromJsonString(jsonStr, serializer())

@Serializable
data class ConfigurationSaveEvent(
    override val userId: Long,
    override val userEmail: String,
    override val groupId: Long
) : ServerUpdateEvent()

@Serializable
data class DocumentTypeSaveEvent(
    override val userId: Long,
    override val userEmail: String,
    override val groupId: Long,
    val documentTypeId: Long
) : ServerUpdateEvent()

@Serializable
data class DocumentTypeDeleteEvent(
    override val userId: Long,
    override val userEmail: String,
    override val groupId: Long,
    val documentTypeId: Long
) : ServerUpdateEvent()

@Serializable
data class DocumentSaveEvent(
    override val userId: Long,
    override val userEmail: String,
    override val groupId: Long,
    val documentId: Long
) : ServerUpdateEvent()

@Serializable
data class DocumentDeleteEvent(
    override val userId: Long,
    override val userEmail: String,
    override val groupId: Long,
    val documentId: Long
) : ServerUpdateEvent()

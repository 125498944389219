package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier
import kafffe.core.func
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.getDate
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.timestampInputModifier

class TimeAndDurationListEditor(
    val fieldId: String,
    model: Model<List<TimeAndDuration>>,
    val fdExt: FdeTimeAndDuration
) :
    ListEditor<TimeAndDuration>(model) {

    var minutesStep: Int by rerenderOnChange(fdExt.stepMinutes)

    // Leave room for buttons
    var widthTime: String by rerenderOnChange("10rem")
    var widthDuration: String by rerenderOnChange("10rem") // * 2 (from and to)


    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        div {
            addClass("form-inline")
            label {
                withElement {
                    style.width = widthTime
                }
                text("Tidspunkt")
            }
            label {
                withElement {
                    style.width = widthDuration
                }
                text("Varighed")
            }
        }
    }

    fun formatDuration(minutes: Int): String {
        if (minutes < 0) return ""
        val hours = minutes / 60
        val remainMinutes = minutes % 60
        return hours.toString().padStart(2, '0') + ":" + remainMinutes.toString().padStart(2, '0')
    }

    fun parseDuration(duration: String): Int {
        val hourMinutes = duration.split(":")
        if (hourMinutes.size < 2) {
            return -1
        }
        try {
            val totalMinutes = hourMinutes[0].toInt() * 60 + hourMinutes[1].toInt()
            return totalMinutes
        } catch (e: Exception) {
            return -1
        }
    }

    private val durationValueList: ValueList
        get() = ValueList().apply {
            autocomplete = true
            values = (fdExt.minimumMinutes .. fdExt.maximumMinutes step fdExt.stepMinutes).map { formatDuration(it) }
        }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: TimeAndDuration, index: Int) {
        input {
            addClass("form-control")
            withElement {
                type = "text"
                style.width = widthTime
                timestampInputModifier(minutesStep).modify(this)
                value = DatePickerHelper.format(element.time)
                onchange = {
                    getDate()?.let { element.time = it }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }

        val durationStringModel: Model<String> = Model.of(element).func(
            {formatDuration(it.data.durationMinutes)},
            {m, v -> m.data.durationMinutes = parseDuration(v)}
        )
        val durationInput = InputWithValueList(
            "${fieldId}_place_$index",
            durationStringModel,
            durationValueList
        )
        durationInput.modifiers.add(StyleModifier {
            width = widthDuration
        })
        durationInput.matchInside = false
        durationInput.onCtrlEnterKey = { addElement(index + 1)}
        durationInput.onMoveDownKey = { onMoveKey(it, element, 1) }
        durationInput.onMoveUpKey = { onMoveKey(it, element, -1) }
        if (index == focusAfterRerender) {
            durationInput.delayedFocus()
        }
        add(durationInput.html)

    }

    override fun createNewElement(): TimeAndDuration {
        return TimeAndDuration(timestampNow(), fdExt.durationDefaultMinutes)
    }

}

package pitchboard.ui

import dk.rheasoft.pitchboard.data.ConfigurationValues
import kafffe.core.Model
import kafffe.core.RefreshingCacheModel

class ConfigurationService {
    var configuration: Model<List<ConfigurationValues>> =
        RefreshingCacheModel<List<ConfigurationValues>>(::refresh, listOf(), timeToLiveSeconds = 60 * 60)

    fun refresh() {
        refresh(configuration)
    }

    fun refresh(model: Model<List<ConfigurationValues>>) {
        UIMainServices.backend.getConfigurationValues(0, 1000) {
            model.data = it.result
        }
    }

    fun current() : ConfigurationValues? {
        return configuration.data.find { UserInformationService.current.selectedGroupId == it.owner}
    }

    operator fun get(ownerId: Long): ConfigurationValues? = configuration.data.find { ownerId == it.owner }
}

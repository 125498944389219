package pitchboard.ui.gruppe

import dk.rheasoft.pitchboard.data.EmailRole
import dk.rheasoft.pitchboard.data.ValueList
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.documents.fieldeditors.InputWithValueList

class UserRoleListEditor(val fieldId: String, model: Model<List<EmailRole>>, val userEmails: Model<List<String>>) : ListEditor<EmailRole>(model) {
    init {
        modelChangedStandardBehavior = {
            currentList = model.data.toMutableList()
            rerender()
        }
    }

    val width1 : String by rerenderOnChange("32rem")
    val width2 : String by rerenderOnChange("16Rem")

    override fun createNewElement(): EmailRole {
        return EmailRole()
    }

    private val emailValueList : ValueList get() =
        ValueList(values = userEmails.data, autocomplete = true, isEnum = false)

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: EmailRole, index: Int) {
        val emailInput = InputWithValueList("${fieldId}_em_$index", Model.of(element).property(EmailRole::email), emailValueList)
        emailInput.modifiers.add(StyleModifier {
            width = width1
        })
        emailInput.onMoveDownKey = {  onMoveKey(it, element, 1) }
        emailInput.onMoveUpKey = { onMoveKey(it, element, -1) }
        if (index == focusAfterRerender) {emailInput. delayedFocus()}
        add(emailInput.html)
        val roleTypeDropdown = RoleTypeDropdown("${fieldId}_role_$index", Model.of(element).property(EmailRole::roleType))
        roleTypeDropdown.onchange { roleTypeDropdown.updateValueModel() }
        roleTypeDropdown.modifiers.add(StyleModifier {
            width = width2
        })
        add(roleTypeDropdown.html)
    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = width1
                        style.minWidth = width1
                    }
                    text("Bruger email")
                }
                label {
                    withElement {
                        style.width = width2
                        style.minWidth = width2
                    }
                    text("Adgang")
                }
            }
    }

}

package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.Timestamp
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kafffe.core.Model
import org.w3c.dom.HTMLInputElement
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.format
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.getDate
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.timestampInputModifier

class TimestampInput(
    val htmlId: String,
    model: Model<Timestamp>
) : KafffeComponentWithModel<Timestamp>(model), FormValueProvider {

    var minutesStep: Int by rerenderOnChange(15)

    override fun updateValueModel() {
        // TODO validation ??
        inputElement?.getDate()?.let { model.data = it }
    }


    private var inputElement: HTMLInputElement? = null

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut = div {
        addClass("input-group")
        input {
            addClass("form-control")
            withElement {
                inputElement = this
                id = htmlId
                name = htmlId
                type = "text"
                value = format(model.data)
                style.maxWidth = "10rem"
                timestampInputModifier(minutesStep).modify(this)
            }
        }
        div {
            addClass("input-group-append input-group-text")
            i {
                addClass("fas fa-clock")
            }
        }
    }

}



package pitchboard.ui.dashboard

import dk.rheasoft.pitchboard.data.Timestamp
import kotlin.js.Date
import kotlin.math.round

fun Timestamp.weekNumber(): Int {
    val date = Date(this.getFullYear(), this.getMonth(), this.getDate())
    date.asDynamic().setDate(date.getDate() + 3 - (date.getDay() + 6) % 7)
    // January 4 is always in week 1.
    val week1 = Date(date.getFullYear(), 0, 4)
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7).toInt()
}

fun weekStart(year: Int, weekNo: Int) : Timestamp {
    // Function partlu copied from some javascript and traslate to Kotlin/JS
    var calcDate = Date(year, 2, 10, 12, 0, 0, 0);
    var daysPastMonday = calcDate.getDay()- 1;
    calcDate.asDynamic().setDate(calcDate.getDate() - daysPastMonday);
    var weekNoToday = calcDate.weekNumber();
    var weeksInTheFuture = weekNo - weekNoToday;
    calcDate.asDynamic().setDate(calcDate.getDate() + ( 7 * weeksInTheFuture ));
    var startWeek = Date(calcDate.getTime());
    return startWeek;
}
package pitchboard.ui.dashboard

import dk.rheasoft.pitchboard.data.ConfigurationValues
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase

class StatusLegend(val config: ConfigurationValues) : KafffeComponent() {

    override fun KafffeHtmlBase.kafffeHtml() =
        div {
            withStyle {
                display = "grid"
                this.setProperty("gap", "1rem")
                this.setProperty("grid-auto-flow", "column")
            }
            for (state in config.stateValueList) {
                div {
                    withStyle {
                        backgroundColor = state.backgroundColor
                        color = state.color
                        paddingLeft = "0.5em"
                    }
                    text(state.name)
                }
            }
        }
}
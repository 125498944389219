package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.Modal
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.FormValueProvider
import kafffe.core.KafffeComponentWithModel
import kafffe.core.Label
import kafffe.core.Model

class ExtensionEditor(val formConsumer: FormComponentConsumer<FieldDefinition>, model: Model<FieldDefinition>) :
    KafffeComponentWithModel<FieldDefinition>(model), FormValueProvider {
    private var currentType: FieldType = model.data.type
    var currentExtension: FieldDefinitionExtension = model.data.extension ?: FdeText()

    init {
        changeType(model.data.type, initialisation = true )
    }

    private inline fun <reified T : FieldDefinitionExtension> assureExtensionType(defaultExtension: T): Model<T> {
        when (defaultExtension) {
            is FdeText -> {
                val ext: FdeText = model.data.typedExtension() ?: defaultExtension
                currentExtension = ext.copy()
            }
            is FdeTextPair -> {
                val ext: FdeTextPair = model.data.typedExtension() ?: defaultExtension
                currentExtension = ext.copy()
            }
            is FdeTimeAndPlace -> {
                val ext: FdeTimeAndPlace = model.data.typedExtension() ?: defaultExtension
                currentExtension = ext.copy()
            }
            is FdeYearWeekDuration -> {
                val ext: FdeYearWeekDuration = model.data.typedExtension() ?: defaultExtension
                currentExtension = ext.copy()
            }
            is FdeChainedTextPair -> {
                val ext: FdeChainedTextPair = model.data.typedExtension() ?: defaultExtension
                currentExtension = ext.copy()
            }
        }
        return Model.of(currentExtension as T)
    }

    fun changeType(newType: FieldType, initialisation: Boolean = false) {
        if (newType != currentType || initialisation) {
            currentType = newType
            when (newType) {
                FieldType.TextOneline,
                FieldType.TextTagList,
                FieldType.TextList -> {
                    val fdeModel = assureExtensionType(FdeText())
                    replaceContent(TextExtEditor(formConsumer, fdeModel))
                }

                FieldType.TextPlain,
                FieldType.TextMarkdown -> replaceContent(Label(""))

                FieldType.Time -> replaceContent(Label("Time extension editor comes here"))
                FieldType.TimeAndDuration,
                FieldType.TimeAndDurationList ->  {
                    val fdeModel = assureExtensionType(FdeTimeAndDuration())
                    replaceContent(TimeAndDurationExtEditor(formConsumer, fdeModel))
                }
                FieldType.TimeAndPlace,
                FieldType.TimeAndPlaceList ->  {
                    val fdeModel = assureExtensionType(FdeTimeAndPlace())
                    replaceContent(TimeAndPlaceExtEditor(formConsumer, fdeModel))
                }
                FieldType.TextPair,
                FieldType.TextPairList -> {
                    val fdeModel = assureExtensionType(FdeTextPair())
                    replaceContent(TextPairExtEditor(formConsumer, fdeModel))
                }
                FieldType.ChainedTextPair -> {
                    val fdeModel = assureExtensionType(FdeChainedTextPair())
                    replaceContent(ChainedTextPairExtEditor(formConsumer, fdeModel))
                }
                FieldType.YearWeekDuration -> {
                    val fdeModel = assureExtensionType(FdeYearWeekDuration())
                    replaceContent(YearWeekDurationExtEditor(formConsumer, fdeModel))
                }
            }
        }
    }

    override fun updateValueModel() {
        model.data.extension = currentExtension
    }
}

package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.FieldType
import kafffe.bootstrap.form.Dropdown
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.Model
import org.w3c.dom.HTMLSelectElement

class FieldTypeDropdown(htmlId: String, model: Model<FieldType>, val onValueChange: (value: FieldType) -> Unit = {}) : Dropdown<FieldType>(htmlId, model, Model.of(FieldType.values().toList())) {
    init {
        modifiers.add(HtmlElementModifier.create {
            (this as HTMLSelectElement).apply {
                onchange = {_ ->
                    onValueChange(currentChoice())
                }
            }
        })
    }

    fun currentChoice() = valueFromString((html as HTMLSelectElement).value)

    override fun display(choice: FieldType): String =
        toDisplay(choice)




    override fun valueFromString(strValue: String): FieldType = FieldType.valueOf(strValue)

    override fun valueToString(value: FieldType): String = value.toString()

    companion object {
        fun toDisplay(choice: FieldType): String {
            return when (choice) {
                FieldType.TextOneline -> "Tekst (en linie)"
                FieldType.TextPlain -> "Tekst"
                FieldType.TextMarkdown -> "Tekst med markdown formatering"
                FieldType.TextList -> "Tekst list"
                FieldType.TextTagList -> "Etiketter (keywords fri)"
                FieldType.Time -> "Dato og tid"
                FieldType.TimeAndDuration -> "Tidspunkt og varighed"
                FieldType.TimeAndDurationList -> "Tidspunkt og varighed liste"
                FieldType.TimeAndPlace -> "Dato tid og sted"
                FieldType.TimeAndPlaceList -> "Dato tid og sted liste"
                FieldType.TextPair -> "Tekst par"
                FieldType.TextPairList -> "Tekst par liste"
                FieldType.ChainedTextPair -> "Valg i 2 niveauer"
                FieldType.YearWeekDuration -> "År, uge og varighed"
            }
        }
    }
}


package pitchboard.ui.publish

import dk.rheasoft.pitchboard.data.*
import kafffe.core.*
import kafffe.messages.Messages.Companion.formatDate
import kafffe.messages.Messages.Companion.formatTime
import marked
import org.w3c.dom.HTMLTableCellElement
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService

class PublishDocumentView(
    docId: Long,
    val layoutName: String
) : KafffeComponentWithModel<PitchBoardDocument>(Model.of(PitchBoardDocument(docId, 1, ""))) {
    init {
        setModelChangedRerender()
        UIMainServices.backend.getDocument(docId) {
            model.data = it
        }
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =

        div {
            val configuration = UIMainServices.configurationService[UserInformationService.current.selectedGroupId]

//            button {
//                addClass("btn btn-info")
//                text("PDF")
//                withElement {
//                    onclick {
//                        var pdf = js("jsPDF()")
//                        js("""pdf.html(document.getElementById('layoutOutput'), {'callback': function (doc) { doc.save();} })""")
//                    }
//                }
//            }
            addClass("rp-content")
            div {
                element?.id = "layoutOutput"
                addClass("card-body bg-white text-dark")
                val doc = model.data
                val docType = UIMainServices.documentTypeService[doc.typeId]
                val layout = docType?.layout?.find { layoutName == it.name }
                layout?.let { publishLayout ->
                    table {
                        addClass("table table-striped table-hover w-auto")
                        publishLayout.sections.forEach { section ->
                            thead {
                                tr { th { h2 { text(section.name) } }; th { text(section.description) } }
                            }
                            tbody {
                                section.fields.forEach { fieldRef ->
                                    val fieldDef = docType.lookupFieldDefinition(fieldRef, configuration!!)
                                    fieldDef?.let { fd ->
                                        tr { td { text(fd.label) }; td { renderFieldValue(doc, fd, fieldRef) } }
                                    }
                                }
                                tr { td {}; td {} }
                            }
                        }
                    }

                }
            }
        }

    private fun KafffeHtml<HTMLTableCellElement>.renderFieldValue(
        document: PitchBoardDocument,
        fieldDefinition: FieldDefinition,
        @Suppress("UNUSED_PARAMETER") fieldReference: FieldReference
    ) {
        // TODO render different base on field type and publish type of fieldRef
        val dataElement = document.data[fieldDefinition.id]

        try {
            when (fieldDefinition.type) {
                FieldType.TextOneline -> {
                    if (dataElement is StringData) {
                        text(dataElement.value.firstOrNull() ?: "")
                    }
                }

                FieldType.TextPlain -> {
                    if (dataElement is StringData) {
                        div {
                            element?.style?.whiteSpace = "pre-wrap"
                            text(dataElement.value.firstOrNull() ?: "")
                        }
                    }
                }

                FieldType.TextMarkdown -> {
                    if (dataElement is StringData) {
                        div {
                            val mdText: String = dataElement.value.firstOrNull() ?: ""
                            val html: String = marked(mdText)
                            element?.innerHTML = html
                        }
                    }
                }

                FieldType.TextList -> {
                    if (dataElement is StringData) {
                        ul {
                            for (value in dataElement.value) {
                                li { text(value) }
                            }
                        }
                    }
                }

                FieldType.TextTagList -> {
                    if (dataElement is StringData) {
                        text(dataElement.value.joinToString(", "))
                    }
                }

                FieldType.TimeAndPlaceList -> {
                    if (dataElement is TimeAndPlaceData) {
                        // TODO publicering include heading and comment ?
                        table {
                            addClass("table-borderless")
                            for (value in dataElement.value) {
                                tr {
                                    val fromDate = value.from.formatDate()
                                    val fromTime = value.from.formatTime()
                                    val toDate = value.to.formatDate()
                                    val toTime = value.to.formatTime()
                                    td {
                                        text(fromDate)
                                        text(" ")
                                        text(fromTime)
                                        text("-")
                                        if (toDate != fromDate) text(toDate)
                                        text(toTime)
                                    }
                                    td { text(value.place) }
                                    td { text(value.comment) }
                                }
                            }
                        }
                    }
                }

                FieldType.Time -> text(dataElement.toString())
                FieldType.TimeAndPlace -> text(dataElement.toString())
                FieldType.TextPair, FieldType.TextPairList -> {
                    if (dataElement is TextPairData) {
                        // TODO publicering include heading and comment ?
                        table {
                            addClass("table-borderless")
                            for (value in dataElement.value) {
                                tr {
                                    td { text(value.text1) }
                                    td { text(value.text2) }
                                }
                            }
                        }
                    }
                }

                FieldType.TimeAndDuration -> text("TODO TimeAndDuration")
                FieldType.TimeAndDurationList -> text("TODO TimeAndDurationList")
                FieldType.ChainedTextPair -> text("TODO ChainedTextPair")
                FieldType.YearWeekDuration -> text("TODO YearWeekDuration")
            }
        } catch (e: Exception) {
            console.log("Exception: $e")
        }
    }

}
package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.Timestamp
import kafffe.bootstrap.external.jsCreate
import kafffe.core.modifiers.HtmlElementModifier
import org.w3c.dom.Element
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement


object DatePickerHelper {
    fun format(time: Timestamp): String {
        val year = time.getFullYear().toString().padStart(4, '0')
        val month = (time.getMonth() + 1).toString().padStart(2, '0')
        val day = time.getDate().toString().padStart(2, '0')
        val hour = time.getHours().toString().padStart(2, '0')
        val minutes = time.getMinutes().toString().padStart(2, '0')
        return "$day-$month-$year $hour:$minutes"
    }

    fun parse(time: String): Timestamp {
        val dateTime = time.split(' ')
        val dayMonthAndYear = dateTime[0].split('-')
        val year = dayMonthAndYear[2].toInt()
        val month = dayMonthAndYear[1].toInt()
        val day = dayMonthAndYear[0].toInt()

        val timeOfDay = dateTime[1].split(':')
        val hour = timeOfDay[0].toInt()
        val minutes  = timeOfDay[1].toInt()
        return Timestamp(year, month -1, day, hour, minutes)
    }

    fun HTMLInputElement.getDate(): Timestamp? {
        if (value.isNullOrBlank()) return null
        return parse(value)
    }

    fun HTMLInputElement.setDate(date: Timestamp){
        value = DatePickerHelper.format(date)
    }

    fun timestampInputModifier(minutesStep: Int = 15) = HtmlElementModifier.create {
        @Suppress("DEPRECATION")
        val jqElement = connectJquery(this).unsafeCast<DatetimepickerJQuery>()
        val options: DatetimepickerOption = jsCreate()
        // setlocale
        js("""jQuery.datetimepicker.setLocale('da')""")
        options.apply {
            format = "d-m-Y H:i"
            step = minutesStep
            dayOfWeekStart = 1
        }
        jqElement.datetimepicker(options)
    }

    fun connectJquery(element: HTMLElement) : DatetimepickerJQuery =
        jQuery(element).datetimepicker()

}

external interface JQuery {
    fun datetimepicker() : DatetimepickerJQuery
}

external interface JQueryStatic {
    @nativeInvoke
    operator fun invoke(element: Element): JQuery
    val datetimepicker : DatetimepickerJQuery
}

//@JsModule("jquery")
//@JsNonModule
external val jQuery: JQueryStatic = definedExternally

//@JsModule("datetimepicker")
//@JsNonModule
//external val datetimepickerFactory : DatetimepickerJQuery = definedExternally


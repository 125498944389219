package dk.rheasoft.pitchboard.data

import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.plus

object RheaJson {
    var jsonSerializer = Json {
        ignoreUnknownKeys = true
        prettyPrint = true
        classDiscriminator = "_kt"
        serializersModule = serializationModulePitchBoardDocument + serializationModuleDocumentType
    }

    public inline fun <reified T : Any> toJsonString(value: T, serializer: SerializationStrategy<T>) : String
            = jsonSerializer.encodeToString(serializer, value)

    public inline fun <reified T : Any> toJsonString(value: T) : String
            = jsonSerializer.encodeToString(value)

    public inline fun <reified T : Any> fromJsonString(value: String, serializer: DeserializationStrategy<T>) : T
            = jsonSerializer.decodeFromString(serializer, value)

    public inline fun <reified T : Any> fromJsonString(value: String) : T
            = jsonSerializer.decodeFromString(value)

}
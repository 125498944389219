package pitchboard.ui.adapters

import dk.rheasoft.pitchboard.data.*
import kafffe.core.Model

// --- StringData ----
fun docToStringData(document: Model<PitchBoardDocument>, fieldId: String) : StringData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is StringData) {
        document.data.data[fieldId] = StringData()
    }
    return document.data.data[fieldId] as StringData
}

fun docToStringModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<String> =
    modelString { docToStringData(document, fieldId) }

fun modelString(dataFunc: () -> StringData) : Model<String> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: ""},
        setter = { dataFunc().value = listOf(it) }
    )

fun docToStringListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<String>> =
    modelStringList { docToStringData(document, fieldId) }

fun modelStringList(dataFunc: () -> StringData) : Model<List<String>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )


// --- TextPairData ----
fun docToTextPairModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<TextPair> =
    modelTextPair { docToTextPairData(document, fieldId) }

fun docToTextPairListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<TextPair>> =
    modelTextPairList { docToTextPairData(document, fieldId) }

fun docToTextPairData(document: Model<PitchBoardDocument>, fieldId: String) : TextPairData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is TextPairData) {
        document.data.data[fieldId] = TextPairData()
    }
    return document.data.data[fieldId] as TextPairData
}

fun modelTextPair(dataFunc: () -> TextPairData) : Model<TextPair> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: TextPair("", "") },
        setter = { dataFunc().value = listOf(it) }
    )

fun modelTextPairList(dataFunc: () -> TextPairData) : Model<List<TextPair>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )

// --- TimeAndDuration ----
fun docToTimeAndDurationModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<TimeAndDuration> =
    modelTimeAndDuration{ docToTimeAndDurationData(document, fieldId) }

fun docToTimeAndDurationListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<TimeAndDuration>> =
    modelTimeAndDurationList{ docToTimeAndDurationData(document, fieldId) }

fun docToTimeAndDurationData(document: Model<PitchBoardDocument>, fieldId: String) : TimeAndDurationData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is TimeAndDurationData) {
        document.data.data[fieldId] = TimeAndDurationData()
    }
    return document.data.data[fieldId] as TimeAndDurationData
}

fun modelTimeAndDuration(dataFunc: () -> TimeAndDurationData) : Model<TimeAndDuration> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: TimeAndDuration(timestampNow(), 120) },
        setter = { dataFunc().value = listOf(it) }
    )

fun modelTimeAndDurationList(dataFunc: () -> TimeAndDurationData) : Model<List<TimeAndDuration>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )

// --- TimeAndPlace ----
fun docToTimeAndPlaceModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<TimeAndPlace> =
    modelTimeAndPlace{ docToTimeAndPlaceData(document, fieldId) }

fun docToTimeAndPlaceListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<TimeAndPlace>> =
    modelTimeAndPlaceList{ docToTimeAndPlaceData(document, fieldId) }

fun docToTimeAndPlaceData(document: Model<PitchBoardDocument>, fieldId: String) : TimeAndPlaceData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is TimeAndPlaceData) {
        document.data.data[fieldId] = TimeAndPlaceData()
    }
    return document.data.data[fieldId] as TimeAndPlaceData
}

fun modelTimeAndPlace(dataFunc: () -> TimeAndPlaceData) : Model<TimeAndPlace> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: TimeAndPlace(timestampNow(), timestampNow().plusHours(1), "", "") },
        setter = { dataFunc().value = listOf(it) }
    )

fun modelTimeAndPlaceList(dataFunc: () -> TimeAndPlaceData) : Model<List<TimeAndPlace>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )

// --- YearWeekDuration ----
fun docToYearWeekDurationModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<YearWeekDuration> =
    modelYearWeekDuration{ docToYearWeekDurationData(document, fieldId) }

fun docToYearWeekDurationListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<YearWeekDuration>> =
    modelYearWeekDurationList{ docToYearWeekDurationData(document, fieldId) }

fun docToYearWeekDurationData(document: Model<PitchBoardDocument>, fieldId: String) : YearWeekDurationData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is YearWeekDurationData) {
        document.data.data[fieldId] = YearWeekDurationData()
    }
    return document.data.data[fieldId] as YearWeekDurationData
}

fun modelYearWeekDuration(dataFunc: () -> YearWeekDurationData) : Model<YearWeekDuration> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: YearWeekDuration(timestampNow().getFullYear(), 1, 0) },
        setter = { dataFunc().value = listOf(it) }
    )

fun modelYearWeekDurationList(dataFunc: () -> YearWeekDurationData) : Model<List<YearWeekDuration>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )

// --- Timestamp ----
fun docToTimestampModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<Timestamp> =
    modelTimestamp { docToTimestampData(document, fieldId) }

fun docToTimestampListModel(document: Model<PitchBoardDocument>, fieldId: String) : Model<List<Timestamp>> =
    modelTimeStampList { docToTimestampData(document, fieldId) }

fun docToTimestampData(document: Model<PitchBoardDocument>, fieldId: String) : TimestampData {
    val dataElement = document.data.data[fieldId]
    if (dataElement == null || dataElement !is TimestampData) {
        document.data.data[fieldId] = TimestampData()
    }
    return document.data.data[fieldId] as TimestampData
}

fun modelTimestamp(dataFunc: () -> TimestampData) : Model<Timestamp> =
    Model.ofGetSet(
        getter = {dataFunc().value.firstOrNull() ?: timestampNow()},
        setter = { dataFunc().value = listOf(it) }
    )

fun modelTimeStampList(dataFunc: () -> TimestampData) : Model<List<Timestamp>> =
    Model.ofGetSet(
        getter = {dataFunc().value},
        setter = { dataFunc().value = it.toList() }
    )

package pitchboard.ui

import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.*
import kafffe.bootstrap.navigation.Nav
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kotlinx.browser.window
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.get
import pitchboard.ui.UIMainServices.navigateTo
import pitchboard.ui.UserInformationService.hasAnyRole
import pitchboard.ui.auth.ChooseAuthenticationProvider
import pitchboard.ui.backend.Backend
import pitchboard.ui.backend.ServerUpdateAware
import pitchboard.ui.dashboard.DashBoard
import pitchboard.ui.documents.DocumentList
import pitchboard.ui.documents.NewDocumentDialog
import pitchboard.ui.documenttypes.DocumentTypeEdit
import pitchboard.ui.documenttypes.DocumentTypeList
import pitchboard.ui.configuration.ConfigEditForm
import pitchboard.ui.documents.DocumentEditBase
import pitchboard.ui.gruppe.GroupAdmin
import pitchboard.ui.ideas.IdeaList
import pitchboard.ui.publish.PublishDocumentView

lateinit var bootstrapRoot: BootstrapRoot

fun chooseProvider() {
    val container = BootstrapContainer.fluid()
    val alerts = AlertsPanel()
    ServiceRegistry.register("alerts", alerts)
    UIMainServices.init()

    window.onload = {
        bootstrapRoot = BootstrapRoot()
        bootstrapRoot.apply {
            addChild(alerts)
            attach()
            UIMainServices.backend.getAuthProviders { providers ->
                addChild(ChooseAuthenticationProvider(providers))
                rerenderRecursive()
            }
        }
    }
}


fun pitchBoardInitMain() {

    val container = BootstrapContainer.fluid()
    val alerts = AlertsPanel()
    ServiceRegistry.register("alerts", alerts)
    UIMainServices.init()

    window.onload = {
        UserInformationService.loadCurrent {
            val rootNavigation: NavigationElement = createNavigation()
            UIMainServices.rootNavigation = rootNavigation

            bootstrapRoot = BootstrapRoot().apply {
                createNavigationMenu(rootNavigation, container)
                addChild(alerts)
                addChild(container)
                attach()
                Backend.updateListeners.add(::onServerUpdated)
            }

            if (hasAnyRole(RoleType.VIEW, RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                UIMainServices.navigateTo("root/dashboard")
            } else {
                UIMainServices.navigateTo("root/about")
            }
        }
    }
}

fun onServerUpdated(msg: String) {
    val event: ServerUpdateEvent = ServerUpdateEvent.fromJsonString(msg);
    if (event.groupId == UserInformationService.current.selectedGroupId) {
        bootstrapRoot.visitChildrenRecusive {
            if (this is ServerUpdateAware) {
                onServerUpdate(event)
            }
        }
    }
}

val menusToToggleWhenEditing = mutableListOf<KafffeComponent>()
fun menuDisplay() {
    menusToToggleWhenEditing.forEach {
        it.rerender()
    }
}

fun menuHide(placeHolderText: String ) {
    menusToToggleWhenEditing.forEachIndexed{
     ix, comp  ->
        if (ix == 0) {
            comp.html = KafffeHtml.start.h3 { text(placeHolderText)}.element!!
        } else {
            comp.html = KafffeHtml.start.span { }.element!!
        }
    }
}

private fun BootstrapRoot.createNavigationMenu(rootNavigation: NavigationElement, container: BootstrapContainer) {
    rootNavigation.componentNavigator = { container.replaceContent(it) }
    addChild(Nav.create(rootNavigation) {
        addExpand(ResponsiveSize.md)
        cssClassModifier("mb-3") // some space under nav bar
        style = ColorStrength.normal
        brand(
            "",
            "images/Logo_Pitchboard 1.png",
            path = NavigationPath.fromString("root/dashboard")
        ).apply {
            modifiers.add(HtmlElementModifier.create {
                (this.getElementsByTagName("img")[0] as HTMLImageElement).style.maxHeight = "3rem"
            })
        }
        toggle("menuToggle").also { menuItem -> menusToToggleWhenEditing.add(menuItem)
            menuItem.cssClassModifier("navbar-light bg-light")
        }
        toggleBlock("menuToggle") {
            if (hasAnyRole(RoleType.VIEW, RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                dropdown(Model.ofGet { "Ugeoversigt " + UIMainServices.planningYear.toString()},
                    NavigationPath.fromString("root/dashboard"),
                    "fas fa-tachometer-alt align-middle me-2",
                    "wrapped align-middle d-inline-block"
                ).apply {
                    val yearDropdown = this
                    yearDropdown.styleModifier {
                        whiteSpace = "nowrap"
                    }
                    val firstYear = Timestamp().getFullYear() - 1
                    val lastYear =  firstYear + 3
                    for (year in  firstYear..lastYear) {
                        item(Model.of(year.toString()), NavigationPath.fromString(""), "" )
                            .apply {
                                modifiers.add(HtmlElementModifier.create {
                                    onclick = {
                                        UIMainServices.planningYear = year
                                        yearDropdown.rerender()
                                        navigateTo(NavigationPath.fromString("root/dashboard"))
                                    }
                                })
                            }
                    }

                }
            }
            if (hasAnyRole(RoleType.VIEW, RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                item(Model.of("Ideer"), NavigationPath.fromString("root/ideas"), "fas fa-lightbulb")
            }
            if (hasAnyRole(RoleType.VIEW, RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                item(Model.of("Tilrettelagte"), NavigationPath.fromString("root/documents"), "fas fa-file-video")
            }
            if (hasAnyRole(RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                item(Model.of("Typer"), NavigationPath.fromString("root/doctypes"), "fas fa-wrench")
            }
            if (hasAnyRole(RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                item(Model.of("Konfiguration"), NavigationPath.fromString("root/groupconfig"), "fas fa-cog")
            }
            if (hasAnyRole(RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                item(Model.of("Gruppe"), NavigationPath.fromString("root/groupadmin"), "fas fa-users")
            }
            item(Model.of("Om"), NavigationPath.fromString("root/about"), "fas fa-question")
        }.also { menuItem -> menusToToggleWhenEditing.add(menuItem) }

        dropdown(
            Model.ofGet { "${UserInformationService.current.user.email}\n${UserInformationService.current.selectedGroup.groupName}" },
            NavigationPath.fromString("root/signout"),
            "fas fa-user-alt align-middle me-2",
            "wrapped align-middle d-inline-block"
        ).apply {
            val dropdown = this
            dropdown.styleModifier {
                whiteSpace = "nowrap"
            }
            cssClassModifier("me-auto")
            for (group in UserInformationService.current.groups) {
                item(
                    Model.of(group.groupName),
                    NavigationPath.fromString(""),
                    if (group.id == UserInformationService.current.selectedGroupId) "fas fa-check" else ""
                )
                    .apply {
                        modifiers.add(HtmlElementModifier.create {
                            onclick = {
                                UserInformationService.selectGroup(group)
                                UIMainServices.configurationService.refresh()
                            }
                        })
                    }

            }
            divider()
            item(
                Model.of("Gå til logud"),
                NavigationPath.fromString("root/signout"),
                "fas fa-sign-out-alt"
            ).apply { cssClassModifier("me-auto") }
        }.also { menuItem -> menusToToggleWhenEditing.add(menuItem) }
    })
}

private fun createNavigation(): NavigationElement {
    return NavigationElement.create("root") {
        component("dashboard") {
            menuDisplay()
            DashBoard()
        }
        sub("dashboardDocument") {
            if (hasAnyRole(RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                component("new") { _ ->
                    documentNewBase("root/dashboard", "Ny redigering")
                }
                dynamicAll("documentId") { path ->
                    documentEditBase(path, "root/dashboard", "Tilrettelæggelses-redigering")
                }
            }
        }
        component("documents") {
            menuDisplay()
            DocumentList()
        }
        sub("document") {
            if (hasAnyRole(RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                component("new") { _ ->
                    documentNewBase("root/documents", "Ny tilrettelæggelses-redigering")
                }
                dynamicAll("documentId") { path ->
                    documentEditBase(path, "root/documents", "Tilrettelæggelses-redigering")
                }
            }
        }
        sub("publish") {
            componentDynamicAll("documentId", documentPublish)
        }
        component("ideas") {
            menuDisplay()
            IdeaList()
        }
        sub("idea") {
            if (hasAnyRole(RoleType.EDIT, RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
                component("new") { _ ->
                    documentNewBase("root/ideas", "Ny ide-redigering")
                }
                dynamicAll("ideaId") { path ->
                    documentEditBase(path, "root/ideas", "Ide-redigering")
                }
            }
        }
        if (hasAnyRole(RoleType.GROUPADMIN, RoleType.SYSADMIN)) {
            component("doctypes") { DocumentTypeList() }
            sub("doctype") {
                component("new", documentTypeNew)
                componentDynamicAll("typeId", documentTypeEdit)
            }
            component("groupadmin") { GroupAdmin() }
            component("groupconfig") { ConfigEditForm() }
        }
        component("about") { About() }
    }
}



//private val dashboard: NavigateTo = { path ->
//    val comp = DashBoard()
//}


private fun documentNewBase(returnTarget: String, placeHolderText: String): DocumentEditBase {
    val newDocument = PitchBoardDocument(
        id = -1,
        typeId = UIMainServices.documentTypeService.types.data.last().id,
        name = "",
        description = "",
        owner = UserInformationService.current.selectedGroupId
    )
    val newDocumentEdit = DocumentEditBase(returnTarget, Model.of(newDocument))
    val selectType = NewDocumentDialog(newDocumentEdit.model).apply {
        onSubmitOk = { newDocumentEdit.documentLoaded() }
    }.attach()
    menuHide(placeHolderText)
    return newDocumentEdit
}


private fun NavigationElement.documentEditBase(path: NavigationPath, returnTarget: String, placeHolderText: String) {
    val documentId = path.toString().toLong()
    UIMainServices.backend.getDocument(documentId) {
        val comp = DocumentEditBase(returnTarget, Model.of(it))
        menuHide(placeHolderText)
        navigateToComponent(comp)
        comp.documentLoaded()
    }
}

private val documentPublish: NavigateToComponent = { path ->
    val docId = path.elements[0].toLong()
    val layoutName = path.elements[1]
    PublishDocumentView(docId, layoutName)
}

private val documentTypeNew: NavigateToComponent = { _ ->
    DocumentTypeEdit().apply {
        val newDocType = DocumentType(-1, "", "").apply {
            // TODO add required layouts - what layout should there be ??
            layout.add(DocumentLayout("Editor", sections = mutableListOf(Section("Sektion 1"))))
            layout.add(DocumentLayout("Ide", sections = mutableListOf(Section("Section 1"))))
            layout.add(DocumentLayout("Dokumentation", sections = mutableListOf(Section("Section 1"))))
//            layout.add(
//                DocumentLayout(
//                    "Slots- og Kulturstyrelse",
//                    sections = mutableListOf(Section("Sektion 1"))
//                )
//            )
//            layout.add(DocumentLayout("EPG", sections = mutableListOf(Section("Sektion 1"))))
        }
        newDocType.owner = UserInformationService.current.selectedGroupId
        model.data = newDocType
        window.setTimeout({ documentLoaded() }, 200)
    }
}

private val documentTypeEdit: NavigateToComponent = { path: NavigationPath ->
    val typeId = path.toString().toLong()
    DocumentTypeEdit().apply {
        // TODO reload to make sure up-to- date
        UIMainServices.documentTypeService[typeId]?.let {
            model.data = it
            window.setTimeout({ documentLoaded() }, 200)
        }
    }
}

fun main() {
    if (kotlinx.browser.window.location.pathname.contains("choose"))
        chooseProvider()
    else
        pitchBoardInitMain()
}
package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FdeColumnWithValueList
import dk.rheasoft.pitchboard.data.FdeTextPair
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.property

class TextPairExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeTextPair>) :
    ExtEditor<FdeTextPair>(parentConsumer, extModel) {
    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeColumnWithValueList::label.name -> "Navn"
                        FdeColumnWithValueList::startValue.name -> "Start værdi"
                        FdeColumnWithValueList::width.name -> "Bredde"
                        FdeColumnWithValueList::valueList.name -> "Værdier"
                        else -> key
                    }
                )
        }

        row {
            col(ColWidth(ResponsiveSize.sm, 6)) {
                addColumnEditor("1",  Model.of("Kolonne 1"), model.property(FdeTextPair::column1))
            }
            col(ColWidth(ResponsiveSize.sm, 6)) {
                addColumnEditor("2", Model.of("Kolonne 2"), model.property(FdeTextPair::column2))
            }
        }
    }

    private fun FormComponentConsumer<*>.addColumnEditor(idSuffix: String, groupLabel: Model<String>, columnModel: Model<FdeColumnWithValueList>) {
        group(columnModel) {
            useBorderedLegend(groupLabel)
            row {
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    input(FdeColumnWithValueList::label).apply { idInput = idInput + idSuffix}
                    input(FdeColumnWithValueList::startValue).apply { idInput = idInput + idSuffix}
                    input(FdeColumnWithValueList::width).apply { idInput = idInput + idSuffix}
                }
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    addValueListEditor(model.property(FdeColumnWithValueList::valueList), idSuffix)
                }
            }
        }
    }
}

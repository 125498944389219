package dk.rheasoft.pitchboard.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class QueryLimit(val offset: Int = 0, val size: Int = 20)

@Serializable
@SerialName("QueryResult")
data class QueryResult<T : Any>(val nofResult: Int, val offset: Int, val sizeRequested: Int, val result: List<T>) {
    constructor(nofResult: Int, result: List<T>, limit: QueryLimit) : this(nofResult, limit.offset, limit.size, result)

    companion object {}
}

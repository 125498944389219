package pitchboard.ui.backend

import dk.rheasoft.pitchboard.data.*
import org.w3c.dom.WebSocket
import org.w3c.dom.events.Event
import org.w3c.xhr.XMLHttpRequest
import kotlinx.browser.window
import dk.rheasoft.pitchboard.data.fromPitchBoardDocumentJsonString
import org.w3c.dom.CloseEvent

class Backend(val exceptionHandler: (errorCode: Int, errorText: String) -> Unit) {
    fun getAuthProviders(receiver: (AuthProviders) -> Unit) {
        getTxt("/auth/providers") {
            val providers = AuthProviders.fromJsonString(it)
            receiver(providers)
        }
    }

    fun selectGroup(user: User, group: Group, receiver: () -> Unit) {
        getTxt("/session/set?user=${user.id}&group=${group.id}") {
            receiver()
        }
    }

    fun getCurrentUserInformation(receiver: (UserInformation) -> Unit) {
        getTxt("/user/current") {
            val x: UserInformation = UserInformation.fromJsonString(it)
            receiver(x)
        }
    }

    /**
     * TODO filter available to current user
     */
    fun getDocuments(offset: Int, pagesize: Int, receiver: (QueryResult<PitchBoardDocument>) -> Unit, filter: String) {
        getTxt("/document/list?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<PitchBoardDocument> = fromPitchBoardDocumentJsonString(it)
            receiver(x)
        }
    }

    fun getIdeas(offset: Int, pagesize: Int, receiver: (QueryResult<PitchBoardDocument>) -> Unit, filter: String) {
        getTxt("/document/list?type=idea&offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<PitchBoardDocument> = fromPitchBoardDocumentJsonString(it)
            receiver(x)
        }
    }

    fun getPlanned(offset: Int, pagesize: Int, receiver: (QueryResult<PitchBoardDocument>) -> Unit, filter: String) {
        getTxt("/document/list?type=planned&offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<PitchBoardDocument> = fromPitchBoardDocumentJsonString(it)
            receiver(x)
        }
    }


    fun getCoverage(year: Int, offset: Int, pagesize: Int, receiver: (QueryResult<CategoryCoverageRecordFromDatabase>) -> Unit, filter: String) {
        getTxt("/document/coverage?year=$year&offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<CategoryCoverageRecordFromDatabase> = fromCategoryCoverageRecordsJsonString(it)
            receiver(x)
        }
    }

    fun getConfigurationValues(offset: Int, pagesize: Int, receiver: (QueryResult<ConfigurationValues>) -> Unit) =
        getConfigurationValues(offset, pagesize, "", receiver)

    fun getConfigurationValues(offset: Int, pagesize: Int, filter: String, receiver: (QueryResult<ConfigurationValues>) -> Unit) {
        getTxt("/configuration/list?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<ConfigurationValues> =  fromConfigurationValuesJsonString(it)
            receiver(x)
        }
    }

    fun getConfigurationValues(owner: Long, receiver: (ConfigurationValues) -> Unit) {
        getTxt("/configuration?owner=${owner.toString()}") {
            val x : ConfigurationValues
            if (it.length <= 0) {
                x = ConfigurationValues(
                    -1, owner, timestampNow(),
                    timestampNow(), "", -1,
                    -1, mutableListOf(), mutableListOf()
                )
            } else {
                x = RheaJson.fromJsonString(it)
            }
            receiver(x)
        }
    }

    fun saveConfigurationValues(configurationValues: ConfigurationValues, receiver: (ConfigurationValues) -> Unit) {
        try {
            val json = configurationValues.toJsonString()
            sendJsonTxt("/configuration/save", "POST", json) {
                val x: ConfigurationValues = RheaJson.fromJsonString(it)
                receiver(x)
            }
        } catch (e: Exception) {
            println(e)
        }
    }

    fun deleteConfigurationValues(configurationValues: ConfigurationValues, receiver: (String) -> Unit) {
        try {
            val json = configurationValues.toJsonString()
            sendJsonTxt("/configuration/delete", "POST", json) {
                receiver(it)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun getDocument(id: Long, receiver: (PitchBoardDocument) -> Unit) {
        getTxt("/document/$id") {
            val x: PitchBoardDocument =  RheaJson.fromJsonString(it)
            receiver(x)
        }
    }

    fun saveDocument(document: PitchBoardDocument, receiver: (PitchBoardDocument) -> Unit) {
        try {
            val json = document.toJsonString()
            sendJsonTxt("/document/save", "POST", json) {
                val x: PitchBoardDocument =  RheaJson.fromJsonString(it)
                receiver(x)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }

    }

    fun deleteDocument(document: PitchBoardDocument, reveicer: (String) -> Unit ) {
        try {
            val json = document.toJsonString()
            sendJsonTxt("/document/delete", "POST", json) {
                reveicer(it)
            }

        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun countDocumentsInGroup(groupId: Long, receiver: (Int) -> Unit) {
        try {
            getTxt("/document/countDocumentsInGroup/$groupId") {
                val numberDelete = it.toInt()
                receiver(numberDelete)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun deleteDocumentsInGroup(groupId: Long, reveiver: (Int) -> Unit) {
        try {
            sendJsonTxt("""/document/deleteAllInGroup/$groupId""", "DELETE", "") {
                val numberDeleted = it.toInt()
                reveiver(numberDeleted)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun getDocumentTypes(offset: Int, pagesize: Int, receiver: (QueryResult<DocumentType>) -> Unit) =
        getDocumentTypes(offset, pagesize, "", receiver)

    fun getDocumentTypes(offset: Int, pagesize: Int, filter: String, receiver: (QueryResult<DocumentType>) -> Unit) {
        getTxt("/document/type/list?offset=$offset&limit=$pagesize&filter=$filter") {
            val x: QueryResult<DocumentType> =  fromDocumentTypeJsonString(it)
            receiver(x)
        }
    }

    fun countDocumentTypesInGroup(groupId: Long, receiver: (Int) -> Unit ) {
        try {
            getTxt("/document/type/countDocumentTypesInGroup/$groupId") {
                val numberOfTypes = it.toInt()
                receiver(numberOfTypes)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun saveDocumentType(documentType: DocumentType, receiver: (DocumentType) -> Unit) {
        try {
            val json = documentType.toJsonString()
            sendJsonTxt("/document/type/save", "POST", json) {
                val x: DocumentType = DocumentType.fromJsonString(it)
                receiver(x)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun exportDocumentType(documentType: DocumentType, receiver: (DocumentType) -> Unit) {
        try {
            val json = documentType.toJsonString()
            sendJsonTxt("/document/type/export", "POST", json) {
                val x: DocumentType = DocumentType.fromJsonString(it)
                receiver(x)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun deleteDocumentType(documentType: DocumentType, receiver: (String) -> Unit) {
        try {
            val json = documentType.toJsonString()
            sendJsonTxt("/document/type/delete", "POST", json) {
                receiver(it)
            }

        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

//    fun deleteDocumentTypesInGroup(groupId: Long, receiver: (Int) -> Unit) {
//        try {
//            getTxt("/document/type/deleteTypesInGroup/$groupId") {
//                val numberOfTypes = it.toInt()
//                receiver(numberOfTypes)
//            }
//        } catch (e: Exception) {
//            exceptionHandler(500, e.message ?: e.toString())
//        }
//    }

    fun allUserEmails(receiver: (UserEmails) -> Unit) {
        try {
            getTxt("/user/emails") {
                val emails = UserEmails.fromJsonString(it)
                receiver(emails)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun groupUserRoles(group: Group, receiver: (EmailRoles) -> Unit) {
        try {
            getTxt("/user/emailAndRoles?group=${group.id}") {
                val emails = EmailRoles.fromJsonString(it)
                receiver(emails)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun saveGroup(group: Group, receiver: (Group) -> Unit) {
        try {
            val json = group.toJsonString()
            sendJsonTxt("/group/save", "POST", json) {
                val x : Group = Group.fromJsonString(it)
                receiver(x)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

//    fun deleteUsersInGroup(group: Group, receiver: (String) -> Unit) {
//        try {
//            val json = group.toJsonString()
//            sendJsonTxt("/group/users/delete", "POST", json) { result ->
//                receiver(result)
//            }
//        } catch (e: Exception) {
//            exceptionHandler(500, e.message ?: e.toString())
//        }
//    }

    fun deleteGroup(group: Group, receiver: (String) -> Unit) {
        try {
            val json = group.toJsonString()
            sendJsonTxt("/group/deleteAll", "POST", json) { result ->
                receiver(result)
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }

    fun saveGroupUsers(group: Group, data: EmailRoles, receiver: () -> Unit) {
        try {
            val json = data.toJsonString()
            sendJsonTxt("/group/${group.id}/roles/save", "POST", json) {
                receiver()
            }
        } catch (e: Exception) {
            exceptionHandler(500, e.message ?: e.toString())
        }
    }



    // ---- private/impl --------------
    private class WebSocketReconnect(val endpoint: String, val messageReceiver: (String) -> Unit) {
        val reconnectInterval = 10 * 1000

        init {
            open()
        }

        fun open() {
            try {
                println("WebSocket open")
                WebSocket(endpoint).apply {
                    onmessage = {
                        messageReceiver(it.data as String)
                    }

                    onerror = {
                        console.info("onerror event: " +  it)
                        reopenHandler()
                    }
                    onclose = {
                        if (it is CloseEvent) {
                            console.info("onclose event code: " +  it.code + " reason: " + it.reason)
                        }
                        reopenHandler()
                    }

                }
            } catch (e: Throwable) {
                window.setTimeout({ open() }, reconnectInterval)
            }
        }

        private fun reopenHandler() {
            println("WebSocket connection error/closed - reconnect attempt in a few seconds")
            window.setTimeout({ open() }, reconnectInterval)
        }

    }

    companion object {
        val loc = window.location
        var serverEndpoint = """${loc.protocol}//${loc.host}""" // kafffenv.csawareBackend
        var serverWsEndpoint = """${if (loc.protocol == "https:") "wss" else "ws"}://${loc.host}/updates"""
        var updateListeners = mutableListOf<(msg: String) -> Unit>()

        init {
            WebSocketReconnect(serverWsEndpoint) {
                for (l in updateListeners) {
                    l(it)
                }
            }
        }
    }

    private var requestHeaders = mapOf(
        "Content-Type" to "application/json"
    )

    private fun getTxt(path: String, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest()
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open("GET", "${serverEndpoint}${path}")
            for (header in requestHeaders) {
                setRequestHeader(header.key, header.value)
            }

        }
        req.send()
    }

    private fun sendJsonTxt(path: String, method: String, json: String, bodyReceiver: (body: String) -> Unit) {
        val req = XMLHttpRequest()
        with(req) {
            onerror = { event -> handleError(this, event) }
            onloadend = { event ->
                when (status) {
                    in (200..299) -> bodyReceiver(responseText)
                    else -> handleError(this, event)
                }
            }
            open(method, "${serverEndpoint}${path}")
            for (header in requestHeaders) {
                setRequestHeader(header.key, header.value)
            }

        }
        req.send(json)
    }

    private fun handleError(request: XMLHttpRequest, @Suppress("UNUSED_PARAMETER") event: Event) {
        if (request.status.toInt() == 0 && request.statusText.isEmpty()) {
            exceptionHandler(0, "Unable to communicate with backend server")
        } else {
            if (request.status.toInt() in setOf(401)) {
                window.location.replace("${serverEndpoint}/login")
            } else {
                if (request.responseText != null && request.responseText.length > 0)
                    exceptionHandler(request.status.toInt(), request.statusText + ": " + request.responseText)
                else
                    exceptionHandler(request.status.toInt(), request.statusText)
            }
        }
    }
}
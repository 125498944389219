package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.DocumentType
import dk.rheasoft.pitchboard.data.QueryResult
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.Messages.Companion.formatDateTime
import org.w3c.dom.HTMLInputElement
import pitchboard.ui.UIMainServices
import kotlinx.dom.addClass
import pitchboard.ui.UserInformationService
import pitchboard.ui.documenttypes.import.DocumentTypeImportDlg
import kotlin.math.ceil

class DocumentTypeList : KafffeComponent() {
    val titleLabel = addChild(Label(Model.of("Dokumenttyper")))

    val table = addChild(BootstrapTable.create<DocumentType>(listOf()) {
        rowClickHandler = {documentType, _ -> rowClick(documentType)}

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))

        col(Model.of("Id"), { Label(it.id.toString()) })
        col(Model.of("Navn"), { Label(it.name) })
        col(Model.of("Beskrivelse"), { Label(it.description) })
        col(Model.of("Seneste opdatering"), { Label(it.lastUpdate.formatDateTime()) })
        col(Model.of("Opdateret af"), { Label(it.updatedBy) })
        col(Model.of("Oprettet"), { Label(it.created.formatDateTime()) })
    })

    var pageSize: Int = 20

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))
    private var filterInput: HTMLInputElement? = null

    init {
        pager.changeListeners.add({ loadData() })
        loadData()
    }

    fun loadData() {
        UIMainServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        val filter = filterInput?.value ?: ""
        UIMainServices.backend.getDocumentTypes(offset, pageSize, filter, this::receiveData)
    }

    private fun receiveData(response: QueryResult<DocumentType>) {
        val pageCount = ceil(response.nofResult.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            div {
                addClass("rp-content")
                div {
                    addClass("h4")
                    addClass("rp-content-header")
                    add(BootstrapButton(Model.of("Opret"), onClick = ::newDocument).apply {
                        iconClasses = "fas fa-folder-plus"
                        color = BasicColor.primary
                    }.html).also {
                        it.addClass( "me-2")
                    }
                    add(BootstrapButton(Model.of("Import"), onClick = ::importDocumentType).apply {
                        iconClasses = "fas fa-file-import"
                        color = BasicColor.primary
                    }.html)
                    add(titleLabel.html.also { it.addClass("ms-2  me-auto") })
                }
                add(table.html)
                div {
                    addClass("rp-content-footer")
                    add(paginator.html)
                }
            }
        }

    private fun importDocumentType (btn: BootstrapButton) {
        DocumentTypeImportDlg().apply {
            onSubmitOk = {
                val newType = DocumentType.fromJsonString(fileData.data)
                newType.id = -1
                newType.owner = UserInformationService.current.selectedGroup.id
                UIMainServices.backend.saveDocumentType(newType) {
                    UIMainServices.documentTypeService.refresh()
                    UIMainServices.navigateTo("root/doctypes")
                    UIMainServices.alerts.infoAdd(buildAlertMessage(it))
                }
            }
            attach()
        }
    }

    private fun buildAlertMessage(documentType: DocumentType) : String {
        return """Dokument type: ${documentType.name} (Id: ${documentType.id}) er gemt"""
    }

    private fun newDocument(@Suppress("UNUSED_PARAMETER") btn: BootstrapButton) {
        UIMainServices.navigateTo("root/doctype/new")
    }

    private fun rowClick(documentType: DocumentType) {
        UIMainServices.navigateTo("root/doctype/${documentType.id}")
    }

}
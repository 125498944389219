package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.FieldDefinition
import dk.rheasoft.pitchboard.data.FieldReference
import dk.rheasoft.pitchboard.data.PredefinedFields
import kafffe.bootstrap.form.SingleEditSelect
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.FunctionalModel
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.documenttypes.fielddefinition.FieldDefinitionDialog
import kotlinx.browser.window

class SectionFieldsEditor(model: Model<List<FieldReference>>, val definitionsModel: Model<List<FieldDefinition>>,  val headerLabels: List<String> = listOf("Feltreference", "Publicering")) : ListEditor<FieldReference>(model) {

    val widthReference : String by rerenderOnChange("50%")
    val widthPublication : String by rerenderOnChange("30%")

    override fun createNewElement(): FieldReference {
        return FieldReference( "")
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: FieldReference, index: Int) {
        // TODO reclaim focus
        val definitionRefModel: Model<String?> = FunctionalModel<String?>(
            {element.fieldId},
            {v -> v?.let {element.fieldId = it} }
        )
        FieldDefinitionSelector("ref$index", definitionRefModel, definitionsModel, {keyboardEvent -> elementKeyHandler(keyboardEvent, element) }).also {
            it.styleModifier {
                width = widthReference
            }
            add(it.html)
            if (index == focusAfterRerender) {  window.setTimeout({ it.focusClaim() }, 300)}
            it.changeListeners.add{ _, value: String? ->
                // hack to sync currentChoice to model.data
                it.updateValueModel()
                this@SectionFieldsEditor.updateValueModel()
            }
        }
        input {
            addClass("form-control")
            withElement {
                value = ""
                type = "text"
                style.width = widthPublication
            }
        }.element!!
        button {
            addClass("btn btn-info")
            i {
                addClass("fas fa-pencil-alt")
            }
            withElement {
                title = "Edit feltdefinition"
            }
            withElement {
                disabled = element.fieldId in PredefinedFields.fieldIds()
                onclick = {
                    val fieldDefinition = fieldDefinitionFromRef(element)
                    val defModel = Model.of(fieldDefinition)

                    FieldDefinitionDialog(
                        defModel,
                        isNew = false
                    ).apply {
                        onSubmitOk = {
                            println(defModel.data.toString())
                            this@SectionFieldsEditor.rerender()
                        }
                        attach()
                    }
                }
            }
        }

    }

    private fun fieldDefinitionFromRef(element: FieldReference) =
        definitionsModel.data.find { it.id == element.fieldId } ?: FieldDefinition("-1", "")

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        if (headerLabels.size >= 2) {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = widthReference
                        style.minWidth = widthReference
                    }
                    text(headerLabels[0])
                }
                label {
                    withElement {
                        style.width = widthPublication
                        style.minWidth = widthPublication
                    }
                    text(headerLabels[1])
                }
            }
        }
    }

}

package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Contextual
import kotlinx.serialization.Serializable

@Serializable
data class StateValue(var name: String = "", var backgroundColor: String = "", var color: String = "") {

    companion object {
        val MandatoryValue = StateValue("Ikke valgt", "#FFFFFF", "#000")
    }
}

@Serializable
data class CategoryValue(var weight: Int = 0, var name: String = "", var subCategories: List<String> = listOf()) {

    companion object {
        val MandatoryValue = CategoryValue(0, "Ikke valgt", listOf())
    }
}

@Serializable
data class ConfigurationValues(
    var id: Long = -1,
    var owner: Long = -1,
    @Contextual
    var created: Timestamp = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp = timestampNow(),
    var updatedBy: String = "",
    var broadcastWeeksPrYear: Int = -1,
    var broadcastMinutesPrWeek: Int = -1,
    var stateValueList: MutableList<StateValue> = mutableListOf(),
    var categoryValueList: MutableList<CategoryValue> = mutableListOf()

) {
    val categoryFieldExtension: FieldDefinitionExtension get() {
        val categories: MutableList<ChainedValue> = mutableListOf()
        for (category in categoryValueList) {
            categories.add(ChainedValue(category.name, category.subCategories))
        }
        return FdeChainedTextPair("Kategori", "Underkategori", "45%", "45%", categories)
    }
    val yearWeekDurationExtension: FdeYearWeekDuration get() {
        return FdeYearWeekDuration(
            labelYear = "År",
            labelWeek ="Uge",
            labelDuration = "Varighed",
            years = (2019..2030).toList(),
            durationDefaultMinutes = 60,
            stepMinutes = 15,
            minimumMinutes = 15,
            maximumMinutes = 8 * 60
        )
    }

    fun minutesPrYear() = broadcastWeeksPrYear * broadcastMinutesPrWeek

    fun categoryTotalWeight(): Int =
        categoryValueList.sumOf { it.weight }


    fun minutesPrYearForCategory(categoryName: String): Int {
        return minutesPrYear() * (categoryValueList.find { it.name.equals(categoryName) }?.weight
            ?: 0) / categoryTotalWeight()
    }

    fun toJsonString() = RheaJson.toJsonString(this, serializer())

    companion object {
        fun fromJsonString(jsonString: String) = RheaJson.fromJsonString(jsonString, serializer())
    }

    fun findColorsFromState(state: String): Pair<String, String> {
        val stateValue = stateValueList.find { state.equals(it.name, true) } ?: StateValue.MandatoryValue
        return Pair(stateValue.color, stateValue.backgroundColor)
    }

    fun findColorFromState(state: String): String {
        val stateValue = stateValueList.find { state.equals(it.name, true) } ?: StateValue.MandatoryValue
        return stateValue.color
    }

    fun findBackgroundColorFromState(state: String): String {
        val stateValue = stateValueList.find { state.equals(it.name, true) } ?: StateValue.MandatoryValue
        return stateValue.backgroundColor
    }

    fun stateValueNames(): List<String> {
        val tempList: MutableList<String> = mutableListOf()
        for (element in stateValueList) {
            tempList.add(element.name)
        }
        return tempList
    }
}

private val qrConfigValue = QueryResult.serializer((ConfigurationValues.serializer()))
fun QueryResult<ConfigurationValues>.toJsonString() =
    RheaJson.toJsonString(this, qrConfigValue)

fun fromConfigurationValuesJsonString(jsonString: String): QueryResult<ConfigurationValues> =
    RheaJson.fromJsonString(jsonString, qrConfigValue)

package pitchboard.ui.dashboard

data class DocumentData(val id: Long, val name: String, val category: String, val state: String, val duration: Int)

data class WeekCoverageData(val year: Int, val weekNumber: Int, val documentList: MutableList<DocumentData>)

data class IdeaCoverageData(val ideaList: MutableList<DocumentData>)

data class WeekCoverage(val weekList: MutableList<WeekCoverageData>) {
    constructor() : this(mutableListOf())
}

data class IdeaCoverage(val ideaList: MutableList<DocumentData>) {
    constructor() : this(mutableListOf())
}
package pitchboard.ui.auth

import dk.rheasoft.pitchboard.data.AuthProviders
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.KafffeHtmlOut
import kotlinx.browser.window

class ChooseAuthenticationProvider(val providers: AuthProviders) : KafffeComponent() {
    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("rp-content mx-auto mt-4")
            withElement {
                style.maxWidth = "40em"
            }
            div {
                addClass("rp-content-body bg-secondary p-4")
                div {
                    addClass("text-center")
                    withStyle { fontFamily = "Roboto"; fontSize= "2em" }
                    img {
                        withElement {
                            src = "images/BrandLogo.png"
                        }
                        withStyle {
                            maxHeight = "4em"
                            maxWidth = "4em"
                        }

                    }
                    span {
                        text("Rhea")
                    }
                    span {
                        withStyle { fontWeight = "bold" }
                        text("Pitchboard")
                    }
                }
                h3 {
                    addClass("mt-4 ms-2")
                    +"Vælg login"
                }
                table {
                    addClass("table table-hover table-bordered")
                    tbody {
                        for (provider in providers.providers) {
                            tr {
                                withElement {
                                    onclick = { window.location.replace(provider.url) }
                                    style.cursor = "hand"
                                }
                                td {
                                    text(provider.name)
                                }
                            }
                        }
                    }
                }
            }
        }


}
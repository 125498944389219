package dk.rheasoft.pitchboard.data

import kotlinx.serialization.*
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass

@Serializable
data class DocumentType(
    var id: Long,
    var name: String,
    var description: String,
    var help: String = "",
    @Contextual
    var created: Timestamp = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp = timestampNow(),
    var updatedBy: String = "",
    var owner: Long = -1,
    var fields: MutableList<FieldDefinition> = PredefinedFields.createList(),
    var layout: MutableList<DocumentLayout> = mutableListOf()
) {
    fun toJsonString() = RheaJson.toJsonString(this, serializer())

    private fun lookupFieldDefinition(fieldRef: FieldReference): FieldDefinition? = fields.find { it.id == fieldRef.fieldId }

    fun lookupFieldDefinition(fieldRef: FieldReference, configValues: ConfigurationValues): FieldDefinition? {
        return when (fieldRef.fieldId) {
            PredefinedFields.category.fieldId -> {
                val field = PredefinedFields.category.fieldDefinition
                field.extension = configValues.categoryFieldExtension
                field
            }
            PredefinedFields.firstBroadcastYearWeek.fieldId -> {
                val field = PredefinedFields.firstBroadcastYearWeek.fieldDefinition.copy()
                field.extension = configValues.yearWeekDurationExtension.copy()
                val custom = lookupFieldDefinition(fieldRef)
                if (custom != null) {
                    if (custom.label.isNotBlank()) {
                        field.label = custom.label
                    }
                    if (custom.description.isNotBlank()) {
                        field.description = custom.description
                    }
                    if (custom.help.isNotBlank()) {
                        field.help = custom.help
                    }
                    val customExt: FdeYearWeekDuration? = custom.typedExtension()
                    val ext = field.extension as FdeYearWeekDuration
                    if (customExt != null) {
                        ext.durationDefaultMinutes = customExt.durationDefaultMinutes
                        // TODO other extension fields
                    }
                }
                field
            }
            else -> {
                lookupFieldDefinition(fieldRef)
            }
        }
    }

    companion object {
        fun fromJsonString(jsonStr: String) = RheaJson.fromJsonString(jsonStr, serializer())
    }
}


/**
 * QueryResult of DocumentType serialization
 */
fun QueryResult<DocumentType>.toJsonString() =
    RheaJson.toJsonString(this, QueryResult.serializer(DocumentType.serializer()))

fun fromDocumentTypeJsonString(jsonStr: String): QueryResult<DocumentType> =
    RheaJson.fromJsonString(jsonStr, QueryResult.serializer((DocumentType.serializer())))

@Serializable
data class ValueList(
    var values: List<String> = listOf(),
    /**
     * IsEnum indicates whether this is limited to the values.
     * * true: only allow values from the list validate.
     * * false: values are suggestions, other values are allowed
     */
    var isEnum: Boolean = false,
    /**
     * Indicates whether this will use hte list for autocompletion
     * * true: use autocomplete
     * * false:
     */
    var autocomplete: Boolean = false
)

@Serializable
data class ChainedValue(
    var value: String = "",
    var values: List<String> = listOf(),
)


@Serializable
data class FdeColumnWithValueList(
    var label: String = "",
    var width: String = "50%",
    var startValue: String = "",
    var valueList: ValueList = ValueList()
)

@Serializable
data class FdeColumn(
    var label: String = "",
    var width: String = "50%"
)


@Serializable
sealed class FieldDefinitionExtension {}

@Serializable
@SerialName("fde_text")
data class FdeText(
    var startValue: String = "",
    var valueList: ValueList = ValueList()
) : FieldDefinitionExtension()


@Serializable
@SerialName("fde_textpair")
data class FdeTextPair(
    var column1: FdeColumnWithValueList = FdeColumnWithValueList(width = "45%"),
    var column2: FdeColumnWithValueList = FdeColumnWithValueList(width = "45%")
) : FieldDefinitionExtension() {
    fun haveHeaders(): Boolean = !(column1.label.isNullOrEmpty() && column2.label.isNullOrBlank())
}

@Serializable
@SerialName("fde_chainedtextpair")
data class FdeChainedTextPair(
    var label1: String = "",
    var label2: String = "",
    var width1: String = "45%",
    var width2: String = "45%",
    var values: List<ChainedValue> = listOf(),
) : FieldDefinitionExtension() {
    fun haveHeaders(): Boolean = !(label1.isNullOrEmpty() && label2.isNullOrBlank())
}

@Serializable
@SerialName("fde_timeduration")
data class FdeTimeAndDuration(
    var durationDefaultMinutes: Int = 120,
    var stepMinutes: Int = 15,
    var minimumMinutes: Int = 15,
    var maximumMinutes: Int = 8 * 60
) : FieldDefinitionExtension()

@Serializable
@SerialName("fde_yearweekduration")
data class FdeYearWeekDuration(
    var labelYear: String = "År",
    var labelWeek: String = "Uge",
    var labelDuration: String = "Varighed",
    var years: List<Int> = (2019..2030).toList(),
    var durationDefaultMinutes: Int = 60,
    var stepMinutes: Int = 15,
    var minimumMinutes: Int = 15,
    var maximumMinutes: Int = 8 * 60
) : FieldDefinitionExtension() {
    fun haveHeaders(): Boolean = !(labelYear.isNullOrEmpty() && labelWeek.isNullOrBlank() && labelDuration.isNullOrBlank())
}

@Serializable
@SerialName("fde_timeplace")
data class FdeTimeAndPlace(
    var from: FdeColumn = FdeColumn(label = "Fra", width = "10rem"),
    var to: FdeColumn = FdeColumn(label = "Til", width = "10rem"),
    var place: FdeColumnWithValueList = FdeColumnWithValueList(label = "Sted", width = "30%"),
    var comment: FdeColumnWithValueList = FdeColumnWithValueList(label = "Kommentar", width = "40%")
) : FieldDefinitionExtension()


@Serializable
data class FieldDefinition(
    var id: String,
    var label: String,
    var type: FieldType = FieldType.TextOneline,
    var description: String = "",
    var help: String = "",
    /**
     * Possible extended information depending on the type
     */
    var extension: FieldDefinitionExtension? = null
) {
    // TODO field access on document
    // access condtional typed extension
    inline fun <reified T : FieldDefinitionExtension> typedExtension(): T? {
        extension?.let { if (it is T) return it }
        return null
    }

}

@Serializable
enum class FieldType {
    TextOneline,
    TextPlain,
    TextMarkdown,
    TextList,
    /**
     * A text list represented in single input field
     */
    TextTagList,
    // Enumeration ?
    Time,
    TimeAndDuration,
    TimeAndDurationList,
    TimeAndPlace,
    TimeAndPlaceList,
    TextPair,
    TextPairList,
    //
    ChainedTextPair,
    YearWeekDuration,
    // ...
}

@Serializable
data class DocumentLayout(
    /**
     * "enumerated" set of names: Editor, KulturMinisteriet, EPG, ...
     */
    val name: String,
    var sections: MutableList<Section> = mutableListOf()
) {
    companion object {
        val emptyLayout = DocumentLayout("empty", mutableListOf())
    }
}

@Polymorphic
interface LayoutElement

@Serializable
@SerialName("field_ref")
data class FieldReference(var fieldId: String) : LayoutElement

@Serializable
@SerialName("layout_section")
data class Section(
    var name: String,
    var description: String = "",
    var help: String = "",
    var fields: MutableList<FieldReference> = mutableListOf()
) : LayoutElement


val serializationModuleDocumentType = SerializersModule {
    polymorphic(LayoutElement::class) {
        subclass(Section::class)
    }

    polymorphic(FieldDefinitionExtension::class) {
        subclass(FdeText::class)
        subclass(FdeTextPair::class)
        subclass(FdeTimeAndDuration::class)
        subclass(FdeTimeAndPlace::class)
        subclass(FdeChainedTextPair::class)
        subclass(FdeYearWeekDuration::class)
    }
    // already registered for Document: contextual(Timestamp::class, TimestampSerializer)
}
package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FdeTimeAndDuration
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.core.LabelStrategy
import kafffe.core.Model

class TimeAndDurationExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeTimeAndDuration>) :
    ExtEditor<FdeTimeAndDuration>(parentConsumer, extModel) {
    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeTimeAndDuration::durationDefaultMinutes.name -> "Start værdi"
                        FdeTimeAndDuration::maximumMinutes.name -> "Maximum"
                        FdeTimeAndDuration::minimumMinutes.name -> "Minimum"
                        FdeTimeAndDuration::stepMinutes.name -> "Trin i valg"
                        else -> key
                    }
                )
        }

        group {
            legend(Model.of("Varighed i minuter"))
            inputNum(FdeTimeAndDuration::durationDefaultMinutes)
            inputNum(FdeTimeAndDuration::minimumMinutes)
            inputNum(FdeTimeAndDuration::maximumMinutes)
            inputNum(FdeTimeAndDuration::stepMinutes)
        }
    }

}


package pitchboard.ui.gruppe

import dk.rheasoft.pitchboard.data.EmailRole
import dk.rheasoft.pitchboard.data.EmailRoles
import dk.rheasoft.pitchboard.data.Group
import dk.rheasoft.pitchboard.data.RoleType
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.Modal
import kafffe.bootstrap.form.BootstrapForm
import kafffe.core.LabelStrategy
import kafffe.core.Model
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService

class GroupUsersForm(
    val groupModel: Model<Group>, model: Model<List<EmailRole>>,
    val userEmails: Model<List<String>>
) :
    BootstrapForm<List<EmailRole>>(model) {

    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        Group::groupName.name -> "Navn"
                        Group::description.name -> "Beskrivelse"
                        else -> key
                    }
                )
        }
        group {
            useBorderedLegend(Model.of("Ret eller tilføj brugere til gruppe"))
            row {
                submit(Model.of("Gem")).apply {
                    iconClasses = "fas fa-save"
                    color = BasicColor.primary
                }
            }
            addChild(UserRoleListEditor("roles", model, userEmails))
        }
        onSubmitOk = ::submit
    }

    private fun submit() {
        val emailRoles = model.data
        val newUserMails = emailRoles.map { it.email }
            .filter { it !in userEmails.data }
            .sorted().toSet()
        if (!UserInformationService.hasRole(RoleType.SYSADMIN)) {
            // check remove of own groupadmin
            val stillGroupAdmin = emailRoles
                .filter { it.email == UserInformationService.current.user.email }
                .filter { it.roleType == RoleType.GROUPADMIN }
            if (stillGroupAdmin == null) {
                UIMainServices.alerts.warnSet("Du kan ikke fjerne din egen administrations rettighed!")
                return
            }
        }
        if (newUserMails.isEmpty()) {
            save(emailRoles)
        } else {
            Modal.confirm(
                Model.of("Opret nye brugere"),
                Model.of("""Følgende emails er nye: ${newUserMails.joinToString(", ")}""")
            ) {
                save(emailRoles)
            }
        }
    }

    private fun save(emailRoles: List<EmailRole>) {
        UIMainServices.alerts.infoSet("Gemmer...")
        UIMainServices.backend.saveGroupUsers(groupModel.data, EmailRoles(emailRoles)) {
            UIMainServices.alerts.infoSet("Brugere gemt")
            parentOfType(GroupAdmin::class)?.rerenderRecursive()
        }
    }
}
package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FdeYearWeekDuration
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.core.LabelStrategy
import kafffe.core.Model

class YearWeekDurationExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeYearWeekDuration>) :
    ExtEditor<FdeYearWeekDuration>(parentConsumer, extModel) {
    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeYearWeekDuration::labelYear.name -> "År"
                        FdeYearWeekDuration::labelWeek.name -> "Uge"
                        FdeYearWeekDuration::labelDuration.name -> "Varighed"
                        FdeYearWeekDuration::durationDefaultMinutes.name -> "Start værdi"
                        FdeYearWeekDuration::maximumMinutes.name -> "Maximum"
                        FdeYearWeekDuration::minimumMinutes.name -> "Minimum"
                        FdeYearWeekDuration::stepMinutes.name -> "Trin i valg"
                        else -> key
                    }
                )
        }
        group {
            legend(Model.of("Underfelt ledetekster - kan udelades"))
            input(FdeYearWeekDuration::labelYear)
            input(FdeYearWeekDuration::labelWeek)
            input(FdeYearWeekDuration::labelDuration)
        }
        group {
            legend(Model.of("Varighed i minuter"))
            inputNum(FdeYearWeekDuration::durationDefaultMinutes)
            inputNum(FdeYearWeekDuration::minimumMinutes)
            inputNum(FdeYearWeekDuration::maximumMinutes)
            inputNum(FdeYearWeekDuration::stepMinutes)
        }
    }

}


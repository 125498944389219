package pitchboard.ui.components

import kafffe.bootstrap.Modal
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier
import marked
import org.w3c.dom.HTMLDivElement
import kotlinx.dom.addClass

class HelpMarkdownDialog(val title: String, val markdown: String) : Modal(Model.of(title)) {
    init {
        modifiersContent.add(CssClassModifier("bg-secondary text-dark"))
        modifiersModal.add(StyleModifier {
            maxWidth = "80vw"
            width = "200rem"
        })
    }

    override fun KafffeHtml<HTMLDivElement>.createHeader() {
        addClass("bg-info")
        h4 {
            i {addClass("fas fa-question me-2")}
            text(model.data)
        }
        button {
            withElement {
                addClass("btn btn-info")
                onclick = { detach() }
            }
            i {addClass("fas fa-times")}
        }
    }

    override fun KafffeHtml<HTMLDivElement>.createBody() {
        val mdHtml = marked(markdown)
        div {
            withElement {
                className = "text-dark"
                style.overflowY = "auto"
                style.maxHeight = "80vh"
                innerHTML = mdHtml
            }
        }
    }

}
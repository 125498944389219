package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.FieldDefinition
import kafffe.bootstrap.form.SingleEditSelect
import kafffe.core.KafffeHtml
import kafffe.core.Model
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.KeyboardEvent

class FieldDefinitionSelector(
    htmlId: String,
    model: Model<String?>,
    val definitionsModel : Model<List<FieldDefinition>>,
    val keyHandler: (keyEvent: KeyboardEvent) -> Unit = {}
) : SingleEditSelect<String>(htmlId, model, Model.ofGet{definitionsModel.data.map{it.id}}) {

    private fun fieldDefinition(definitionId: String?) = definitionsModel.data.find{definitionId == it.id}

    init {
        valueCssClasses = {_ -> "" }
    }

    override fun <H : HTMLElement> KafffeHtml<H>.choiceRender(choice: String?) {
        withElement { style.width = "98%" }
        fieldDefinition(choice)?.let { definition ->
                span {
                    text(definition.label)
                }
                span {
                    addClass("float-right")
                    text("(${definition.id})")
                }
                withElement {
                    title = definition.description
                }
        }
    }

    fun display(choice: FieldDefinition?): String =
        choice?.let { "${choice.label}  -- (${choice.id})"  } ?: ""

    override fun display(choice: String?): String =
        display(fieldDefinition(choice))

    override fun onkey(keyEvent: KeyboardEvent) {
        keyHandler(keyEvent)
        if (!keyEvent.defaultPrevented) {
            super.onkey(keyEvent)
        }
    }
}
package pitchboard.ui.documents

import bootstrap.Tooltip
import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.Modal
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.external.createTooltip
import kafffe.bootstrap.external.jsCreate
import kafffe.bootstrap.form.*
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.HtmlElementModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.messages.Messages.Companion.formatDateTime
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.get
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService
import pitchboard.ui.adapters.*
import pitchboard.ui.components.HelpMarkdownDialog
import pitchboard.ui.documents.fieldeditors.*

class DocumentEditBase(val listDocumentTarget: String, model: Model<PitchBoardDocument> = Model.of(PitchBoardDocument(-1, 1, ""))) :
    KafffeComponentWithModel<PitchBoardDocument>(model) {

    val typeModel: Model<DocumentType?> = Model.ofNullable(null)

    // alias
    private val docModel: Model<PitchBoardDocument> get() = model

    private val stateValueChoiseList: List<String> = UIMainServices.configurationService.current()!!.stateValueNames()

    private val configuration = UIMainServices.configurationService[UserInformationService.current.selectedGroupId]
    /**
     * To be called when the document is ready laoded or user have slected the type for a new document.
     */
    fun documentLoaded() {
        typeModel.data = UIMainServices.documentTypeService[docModel.data.typeId]
        selectTab(firstTab())
        rerenderRecursive()
    }

    val tabs = addChild(SectionTabs())

    fun isIdea() : Boolean = model.data.isIdea()

    open fun editorLayout(): DocumentLayout  =
        typeModel.data?.layout?.find { it.name == if (isIdea()) "Ide" else "Editor" } ?: DocumentLayout.emptyLayout

    private fun firstTab() = editorLayout().sections.first()

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            div {
                addClass("rp-content")
                buildToolbar()
                div {
                    addClass("rp-content-body")
                    add(mainForm.html)
                    add(tabs.html)
                    add(sectionForm.html)
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.buildToolbar() {
        div {
            addClass("btn-group mb-4")
            add(BootstrapButton(Model.of("Gem og afslut"), onClick = ::save).apply {
                iconClasses = "fas fa-save"
            }.html)
            add(BootstrapButton(Model.of("Gem"), onClick = ::saveOnly).apply {
                iconClasses = "fas fa-archive"
            }.html)
            add(BootstrapButton(Model.of("Slet"), onClick = ::delete).apply {
                iconClasses = "fas fa-trash"
            }.html)
            div {
                addClass("btn-group")
                button {
                    addClass("btn btn-secondary")
                    withElement {
                        id = "btnGroupDrop1"
                        type = "button"
                        setAttribute("data-toggle", "dropdown")
                    }
                    text("Publicering... ")
                    i { addClass("fas fa-file-export") }
                }
                div {
                    addClass("dropdown-menu")
                    element?.setAttribute("aria-labelledby", "btnGroupDrop1")
                    typeModel.data?.layout?.let { layouts ->
                        for (layout in layouts) {
                            a {
                                addClass("dropdown-item")
                                text(layout.name)
                                withElement {
                                    onclick =
                                        { UIMainServices.navigateTo("root/publish/${model.data.id}/${layout.name}") }
                                }
                            }
                        }
                    }
                }
            }
            add(BootstrapButton(Model.of("Annuller")) { UIMainServices.navigateTo(listDocumentTarget) }.apply {
                iconClasses = "fas fa-undo"
            }.html)

        }
    }

    private fun export(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
    }

    private fun delete(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        sectionForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                Modal.confirm(
                    Model.of("Vil du slette dokument"),
                    Model.of("""Dokument: ${model.data.name}""")
                ) {
                    UIMainServices.backend.deleteDocument(model.data) { response: String ->
                        UIMainServices.navigateTo(listDocumentTarget)
                        if (response.equals("OK")) UIMainServices.alerts.infoAdd("""Har slettet dokument: ${model.data.name} Id: (${model.data.id})""")
                        else UIMainServices.alerts.errorAdd("""Fejl ved sletning af dokument: ${model.data.name} Id: ${model.data.id} Error: ${response}""")
                    }
                }
            })
        })
    }

    private fun save(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        sectionForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                UIMainServices.backend.saveDocument(model.data) { doc: PitchBoardDocument ->
                    UIMainServices.navigateTo(listDocumentTarget)
                    UIMainServices.alerts.infoAdd(buildAlertMessage(doc))
                }
            }
            )
        })
    }

    private fun saveOnly(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        sectionForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                UIMainServices.backend.saveDocument(model.data) { doc: PitchBoardDocument ->
                    model.data = doc
                    rerenderRecursive()
                    UIMainServices.alerts.infoAdd(buildAlertMessage(doc))
                }
            })
        })
    }

    private fun buildAlertMessage(document: PitchBoardDocument): String {
        return """Dokument: ${document.name} (Id: ${document.id}) er gemt"""
    }

    private var currentTab: Section? = null
    private fun selectTab(tab: Section) {
        // Sync values and render current tab
        sectionForm.processForm(onOk = {
            currentTab = tab
            sectionForm.rebuild()
            tabs.rerender()
        })
    }

    val mainForm = addChild(MainForm())

    inner class MainForm() : BootstrapForm<PitchBoardDocument>(model) {
        init {
            cssClassModifier("ps-4 pe-4")
            modifiers.add(StyleModifier {
                fontSize = "smaller"
                border = "1px solid darkcyan"
            })
            row {
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    input("name", Model.of("Titel"), model.property(PitchBoardDocument::name)).apply {
                        readOnly = true
                        modifiers.add(
                            CssClassModifier("csaware-field form-control-sm")
                        )
                    }

                    row {
                        col(ColWidth(ResponsiveSize.sm, 5)) {
                            dropdown(
                                "state",
                                Model.of("Workflow tilstand"),
                                model.property(PitchBoardDocument::state),
                                Model.of(stateValueChoiseList)
                            ).modifiers.add(CssClassModifier("form-control-sm"))
                        }
//                        col(ColWidth(ResponsiveSize.sm, 5)) {
//                            input(
//                                "state",
//                                Model.of("Workflow tilstand"),
//                                model.property(PitchBoardDocument::state)
//                            ).modifiers.add(CssClassModifier("form-control-sm"))
//                        }
                        col(ColWidth(ResponsiveSize.sm, 7)) {
                            input(
                                "assignedTo",
                                Model.of("Ansvarlig"),
                                Model.of("")
                            ).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                    }
                }
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    input("type", Model.of("Type"), Model.ofGet { typeModel.data?.name ?: "" }).apply {
                        readOnly = true
                        modifiers.add(
                            CssClassModifier("csaware-field form-control-sm")
                        )
                    }
                    row {
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "changed",
                                Model.of("Ændret"),
                                Model.ofGet { model.data.lastUpdate.formatDateTime() }).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "changedBy",
                                Model.of("Ændret af"),
                                model.property(PitchBoardDocument::updatedBy)
                            ).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "created",
                                Model.of("Oprettet"),
                                Model.ofGet { model.data.created.formatDateTime() }).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }

                    }
                }
            }
        }
    }

    val sectionForm = addChild(SectionForm())

    inner class SectionForm() : BootstrapForm<Section>(Model.ofGet { currentTab ?: Section("") }) {
        fun clear() {
            removeAllChildren()
        }

        fun build() {
            textArea("Beskrivelse", Model.of("Beskrivelse"), Model.of(currentTab?.description ?: "")).apply {
                readOnly = true
                modifiers.add(CssClassModifier("csaware-field"))
            }
            currentTab?.let { addCompositeLayout(it.fields) }
        }

        private fun FormComponentConsumer<Section>.addCompositeLayout(layoutElements: Iterable<LayoutElement>) {
            for (layoutElement in layoutElements) {
                when (layoutElement) {
                    is Section -> addCompositeLayout(layoutElement.fields)
                    is FieldReference -> addField(layoutElement)
                }
            }
        }

        private fun FormComponentConsumer<Section>.addField(fieldRef: FieldReference) {
            typeModel.data?.lookupFieldDefinition(fieldRef, configuration!!)?.also { fieldDefinition ->
                inputDecorator = { labelModel, inputComp ->
                    FormInputGroupDecorator(labelModel, inputComp).apply {
                        applyLabelModifiers(modifiersLabel, fieldDefinition)
                    }
                }
                when (fieldDefinition.type) {
                    FieldType.TextOneline -> {
                        val extension: FdeText = fieldDefinition.typedExtension() ?: FdeText()
                        val fieldModel = docToStringListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data = listOf(extension.startValue)
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TextEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                            }
                        )
                    }
                    FieldType.TextList -> {
                        val extension: FdeText = fieldDefinition.typedExtension() ?: FdeText()
                        val fieldModel = docToStringListModel(docModel, fieldDefinition.id)
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TextEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            )
                        )
                    }

                    FieldType.TextPlain -> textArea(
                        fieldDefinition.id,
                        Model.of(fieldDefinition.label),
                        docToStringModel(docModel, fieldDefinition.id)
                    )
                    FieldType.TextMarkdown -> {
                        val inp = MarkDownInput(
                            fieldDefinition.id,
                            docToStringModel(docModel, fieldDefinition.id)
                        )
                        decorateAndAddComponent(Model.of(fieldDefinition.label), inp)
                    }

                    FieldType.TextTagList -> {
                        editMultiple(
                            fieldDefinition.id,
                            Model.of(fieldDefinition.label),
                            docToStringListModel(docModel, fieldDefinition.id)
                        )
                    }
                    FieldType.Time -> {
                        val inp = TimestampInput(
                            fieldDefinition.id,
                            docToTimestampModel(docModel, fieldDefinition.id)
                        )
                        decorateAndAddComponent(Model.of(fieldDefinition.label), inp)
                    }

                    FieldType.YearWeekDuration -> {
                        val extension: FdeYearWeekDuration = fieldDefinition.typedExtension() ?: FdeYearWeekDuration()
                        val fieldModel = docToYearWeekDurationListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data = listOf(
                                YearWeekDuration(
                                    0,
                                    0,
                                    0
                                )
                            )
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            YearWeekDurationListEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                                haveHeaders = extension.haveHeaders();
                            }
                        )
                    }

                    FieldType.TimeAndDuration -> {
                        val extension: FdeTimeAndDuration = fieldDefinition.typedExtension() ?: FdeTimeAndDuration()
                        val fieldModel = docToTimeAndDurationListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data = listOf(
                                // TODO allow empty timestamps (null)
                                TimeAndDuration(
                                    timestampNow(),
                                    extension.durationDefaultMinutes
                                )
                            )
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TimeAndDurationListEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                            }
                        )
                    }

                    FieldType.TimeAndDurationList -> {
                        val extension: FdeTimeAndDuration = fieldDefinition.typedExtension() ?: FdeTimeAndDuration()
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TimeAndDurationListEditor(
                                fieldDefinition.id,
                                docToTimeAndDurationListModel(docModel, fieldDefinition.id),
                                extension
                            )
                        )
                    }

                    FieldType.TimeAndPlace -> {
                        val extension: FdeTimeAndPlace = fieldDefinition.typedExtension() ?: FdeTimeAndPlace()
                        val fieldModel = docToTimeAndPlaceListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data = listOf(
                                // TODO allow empty timestamps (null)
                                TimeAndPlace(
                                    timestampNow(),
                                    timestampNow().plusHours(1),
                                    extension.place.startValue,
                                    extension.comment.startValue
                                )
                            )
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TimeAndPlaceListEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                            }
                        )
                    }

                    FieldType.TimeAndPlaceList -> {
                        val extension: FdeTimeAndPlace = fieldDefinition.typedExtension() ?: FdeTimeAndPlace()
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TimeAndPlaceListEditor(
                                fieldDefinition.id,
                                docToTimeAndPlaceListModel(docModel, fieldDefinition.id),
                                extension
                            )
                        )
                    }

                    FieldType.TextPair -> {
                        // example of using a list editor for single element, make sure this exactly one element and remove buttons and create from the editor
                        val extension: FdeTextPair = fieldDefinition.typedExtension() ?: FdeTextPair()
                        val fieldModel = docToTextPairListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data =
                                listOf(TextPair(extension.column1.startValue, extension.column2.startValue))
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TextPairsEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                                haveHeaders = extension.haveHeaders();
                            }
                        )
                    }

                    FieldType.TextPairList -> {
                        val extension: FdeTextPair = fieldDefinition.typedExtension() ?: FdeTextPair()
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            TextPairsEditor(
                                fieldDefinition.id,
                                docToTextPairListModel(docModel, fieldDefinition.id),
                                extension
                            )
                        )
                    }

                    FieldType.ChainedTextPair -> {
                        // example of using a list editor for single element, make sure this exactly one element and remove buttons and create from the editor
                        val extension: FdeChainedTextPair = fieldDefinition.typedExtension() ?: FdeChainedTextPair()
                        val fieldModel = docToTextPairListModel(docModel, fieldDefinition.id)
                        // assure one element
                        if (fieldModel.data.isEmpty()) {
                            fieldModel.data = listOf(TextPair("", ""))
                        } else if (fieldModel.data.size > 1) {
                            fieldModel.data = listOf(fieldModel.data.first())
                        }
                        decorateAndAddComponent(
                            Model.of(fieldDefinition.label),
                            // TODO new editor
                            ChainedTextPairsEditor(
                                fieldDefinition.id,
                                fieldModel,
                                extension
                            ).apply {
                                onlyEdit()
                                haveHeaders = extension.haveHeaders();
                            }
                        )
                    }
                }
            }
        }

        private fun applyLabelModifiers(
            modifiers: MutableList<HtmlElementModifier>,
            fieldDefinition: FieldDefinition
        ) {
            if (fieldDefinition.description.isNotEmpty()) {
                modifiers.add(BootstrapTooltipModifier(Model.of(fieldDefinition.description)).apply {
                    options.trigger = "hover focus"
                })
                modifiers.add(CssClassModifier("tooltip-holder"))
            }
            if (fieldDefinition.help.isNotEmpty()) {
                modifiers.add(HtmlElementModifier.create {
                    this.appendChild(
                        // TODO better way to show help
                        htmlStart.span {
                            addClass("text-info ms-2")
                            i {
                                addClass("fas fa-question-circle")
                                element?.style?.cursor = "pointer"
                            }
                            withElement {
                                onclick = {
                                    HelpMarkdownDialog(
                                        "Hjælp til ${fieldDefinition.label}",
                                        fieldDefinition.help
                                    ).attach()
                                }
                            }
                        }.element!!
                    )
                })
            }
        }

        fun rebuild() {
            clear()
            build()
            rerender()
        }
    }

    inner class SectionTabs : KafffeComponent() {
        private val tabTooltips = mutableListOf<Tooltip>()
        override fun KafffeHtmlBase.kafffeHtml() = ul {
            addClass("nav nav-tabs nav-fill mt-5")
            val tabs = editorLayout().sections
            tabTooltips.forEach { tooltip -> tooltip.dispose() }
            tabTooltips.clear()
            for (tab in tabs) {
                li {
                    addClass("nav-item")
                    a {
                        addClass("nav-link tooltip-holder")
                        if (tab == currentTab) {
                            addClass("active")
                        }
                        text(tab.name)
                        withElement {
                            onclick = {
                                tabTooltips.forEach { tooltip -> tooltip.hide() }
                                selectTab(tab)
                                it.preventDefault()
                            }
                            val options: Tooltip.Options = jsCreate()
                            options.trigger = "hover focus"
                            element!!.title = tab.description
                            tabTooltips.add(createTooltip(element!!, options))
                        }
                    }
                }
            }
        }
    }
}

package pitchboard.ui.configuration

import dk.rheasoft.pitchboard.data.ConfigurationValues
import dk.rheasoft.pitchboard.data.StateValue
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.form.BootstrapForm
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.core.property
import kotlinx.browser.window
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService
import pitchboard.ui.configuration.fieldeditors.CategoryValueListEditor
import pitchboard.ui.configuration.fieldeditors.StateValueListEditor
import pitchboard.ui.configuration.import.ConfigurationImportDlg

class ConfigEditForm() : BootstrapForm<ConfigurationValues>(Model.of(ConfigurationValues())) {

    // Componenter is added as children in init block
    val stateEditor = StateValueListEditor(model.property(ConfigurationValues::stateValueList))
    val categoryEditor = CategoryValueListEditor(model.property(ConfigurationValues::categoryValueList))

    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        ConfigurationValues::broadcastMinutesPrWeek.name -> "Broadcast minutter per uge"
                        ConfigurationValues::broadcastWeeksPrYear.name -> "Broadcast uger per år"
                        ConfigurationValues::categoryValueList.name -> "Kategori"
                        ConfigurationValues::stateValueList.name -> "Workflow status"
                        else -> key
                    }
                )
        }
        group {
            useBorderedLegend(Model.of("Editer konfiguration"))
            group {
                submit(Model.of("Gem")).apply {
                    iconClasses = "fas fa-save"
                    color = BasicColor.primary
                    cssClassModifier("ms-2")
                }
                submit(Model.of("Eksport"), onOk = {export()}).apply {
                    iconClasses = "fas fa-file-export"
                    color = BasicColor.primary
                    cssClassModifier("ms-2")
                }
                addChild(BootstrapButton(Model.of("Import"), onClick = ::importConfiguration).apply {
                    iconClasses = "fas fa-file-import"
                    color = BasicColor.primary
                    cssClassModifier("ms-2")
                })
            }
            inputNum(ConfigurationValues::broadcastWeeksPrYear)
            inputNum(ConfigurationValues::broadcastMinutesPrWeek)
            decorateAndAddComponent(Model.of(""), stateEditor)
            decorateAndAddComponent(Model.of(""), categoryEditor)
        }
        onSubmitOk = ::submit

        loadConfigurationValues()
    }

    private fun loadConfigurationValues() {
        val configurationValues = UIMainServices.configurationService[UserInformationService.current.selectedGroupId]
        model.data = configurationValues!!
        validateModelValues()
        stateEditor.reloadListFromModel()
        categoryEditor.reloadListFromModel()
        rerenderRecursive()
    }

    private fun validateModelValues() {
        if (model.data.stateValueList.isEmpty()) {
            model.data.stateValueList.add(StateValue.MandatoryValue)
        }
        if (model.data.broadcastWeeksPrYear > WeeksPrYearMaxValue) {
            model.data.broadcastWeeksPrYear = WeeksPrYearMaxValue
        }
        if (model.data.broadcastWeeksPrYear < WeeksPrYearMinValue) {
            model.data.broadcastWeeksPrYear = WeeksPrYearMinValue
        }
        if (model.data.broadcastMinutesPrWeek > MinPrWeekMaxValue) {
            model.data.broadcastMinutesPrWeek = MinPrWeekMaxValue
        }
        if (model.data.broadcastMinutesPrWeek < MinPrWeekMinValue) {
            model.data.broadcastMinutesPrWeek = MinPrWeekMinValue
        }

    }

    private fun importConfiguration(btn: BootstrapButton) {
        var newConfig: ConfigurationValues? = null
        ConfigurationImportDlg().apply {
            onSubmitOk = {
                newConfig = ConfigurationValues.fromJsonString(fileData.data)
                newConfig!!.id = getCurrentId()
                newConfig!!.owner = getCurrentOwner()
                UIMainServices.backend.saveConfigurationValues(newConfig!!) {
                    UIMainServices.alerts.infoAdd("Konfiguration importeret og gemt i database")
                }
            }
            attach()
        }
//        if (newConfig != null) model.data = newConfig as ConfigurationValues
        parent!!.rerender()
    }

    private fun getCurrentId(): Long {
        return model.data.id
    }

    private fun getCurrentOwner(): Long {
        return model.data.owner
    }

    private fun export()  {
        window.parent.open("/configuration/${model.data.id}/export")
    }

    private fun submit() {
        val configurationValues = model.data
        UIMainServices.alerts.infoSet("Gemmer...")
        UIMainServices.backend.saveConfigurationValues(configurationValues) {
            UIMainServices.alerts.infoSet("Konfiguration gemt")
            rerenderRecursive()
        }
        UIMainServices.configurationService.refresh()
    }

    companion object {
        const val WeeksPrYearMinValue = 1
        const val WeeksPrYearMaxValue = 53
        const val MinPrWeekMinValue = 15
        const val MinPrWeekMaxValue = 7 * 24 * 60
    }
}

package pitchboard.ui.configuration.fieldeditors

import dk.rheasoft.pitchboard.data.CategoryValue
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import org.w3c.dom.HTMLDivElement

class CategoryValueListEditor( model: Model<List<CategoryValue>>) :
    KafffeComponentWithModel<List<CategoryValue>>(model) {

    private val currentModel: Model<CategoryValue> = Model.of(CategoryValue())
    private val primaryEditor = addChild(PrimaryListEditor(model))
    private val secondaryEditor = addChild(SecondaryListEditor(currentModel.property(CategoryValue::subCategories)))

    private val widthCategory: String by rerenderOnChange("24rem")
    private val widthWeight : String by rerenderOnChange("12rem")
    private val widthSubcategory : String by rerenderOnChange("24rem")

    fun reloadListFromModel() {
        if (model.data.isEmpty()) {
            model.data = listOf(CategoryValue.MandatoryValue)
        }
        primaryEditor.reloadListFromModel()
        setCurrent(model.data.first())
    }

    protected fun setCurrent(primary: CategoryValue) {
        if (primary != currentModel.data) {
            secondaryEditor.updateValueModel()
            secondaryEditor.focusAfterRerender = -1
            currentModel.data = primary
            secondaryEditor.reloadListFromModel()
        }
    }

    inner class SecondaryListEditor(model: Model<List<String>>) : ListEditor<String>(model) {
        override fun createNewElement(): String = ""

        override fun KafffeHtml<HTMLDivElement>.elementEditor(listElement: String, index: Int) {
            input {
                addClass("form-control")
                withStyle {
                    width = widthSubcategory
                }
                withElement {
                    value = listElement
                    type = "text"
                    onkeydown = { keyEvent ->
                        currentList[index] = value
                        elementKeyHandler(keyEvent, currentList[index] )}
                    onchange = { currentList[index] = value; it}
                    if (index == focusAfterRerender) {delayedFocus()}
                }
            }
        }

        override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = widthSubcategory
                        style.minWidth = widthSubcategory
                    }
                    text("Subkategori : ${currentModel.data.name}")
                }
            }
        }


    }

    inner class PrimaryListEditor(model: Model<List<CategoryValue>>) : ListEditor<CategoryValue>(model) {

        override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = widthCategory
                        style.minWidth = widthCategory
                    }
                    text("Kategori")
                }
                label {
                    withElement {
                        style.width = widthWeight
                        style.minWidth = widthWeight
                    }
                    text("Andel")
                }
            }
        }

        override fun createNewElement(): CategoryValue = CategoryValue()

        override fun KafffeHtml<HTMLDivElement>.elementEditor(listElement: CategoryValue, index: Int) {
            input {
                withStyle {
                    width = widthCategory
                }
                addClass("form-control")
                withElement {
                    value = listElement.name
                    type = "text"
                    onkeydown = { keyEvent ->
                        currentList[index].name = value
                        elementKeyHandler(keyEvent, listElement) }
                    onchange = { currentList[index].name = value; it}
                    onfocus = {
                        setCurrent(listElement)
                    }
                    if (index == focusAfterRerender) {
                        delayedFocus()
                    }
                }
            }
            input {
                addClass("form-control")
                withStyle {
                    width = widthWeight
                }
                withElement {
                    value = listElement.weight.toString()
                    type = "number"
                    min = "0"
                    onkeydown = { keyEvent ->
                        currentList[index].weight = value.toInt()
                        elementKeyHandler(keyEvent, listElement) }
                    onchange = { currentList[index].weight = value.toInt(); it}
                    onfocus = {
                        setCurrent(listElement)
                    }
                }
            }
        }

    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            addClass("")
            div {
                addClass("")
                withStyle {
                    display = "inline-block"
                }
                add(primaryEditor.html)
            }
            div {
                addClass("ms-2")
                withStyle {
                    display = "inline-block"
                }
                add(secondaryEditor.html)
            }
        }
}

package pitchboard.ui.documenttypes

import dk.rheasoft.pitchboard.data.DocumentLayout
import dk.rheasoft.pitchboard.data.DocumentType
import dk.rheasoft.pitchboard.data.Section
import kafffe.bootstrap.*
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.textArea
import kafffe.bootstrap.modifier.BootstrapTooltipModifier
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.StyleModifier.Companion.styleModifier
import kafffe.messages.Messages.Companion.formatDateTime
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import pitchboard.ui.UIMainServices


class DocumentTypeEdit(model: Model<DocumentType> = Model.of(DocumentType(-1, "", ""))) :
    KafffeComponentWithModel<DocumentType>(model) {
    private val typeModel: Model<DocumentType> get() = model

    /**
     * To be called when the document is ready loaded or user have slected the type for a new document.
     */
    fun documentLoaded() {
        selectTab(model.data.layout.firstOrNull()?.name ?: fieldDefTab)
        rerenderRecursive()
    }

    val tabsComponent = addChild(TabsComponent())

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {

        return div {
            div {
                addClass("rp-content")
                buildToolbar()
                div {
                    addClass("rp-content-body")
                    add(mainForm.html)
                    add(tabsComponent.html)
                    add(layoutForm.html)
                }
            }
        }
    }

    private fun KafffeHtml<HTMLDivElement>.buildToolbar() {
        div {
            addClass("btn-group mb-4")
            add(BootstrapButton(Model.of("Gem og afslut"), onClick = ::save).apply {
                iconClasses = "fas fa-save"
            }.html)
            add(BootstrapButton(Model.of("Gem"), onClick = ::saveOnly).apply {
                iconClasses = "fas fa-archive"
            }.html)
            add(BootstrapButton(Model.of("Eksporter"), onClick = ::export).apply {
                iconClasses = "fas fa-file-export"
            }.html)
            add(BootstrapButton(Model.of("Slet"), onClick = ::delete).apply {
                iconClasses = "fas fa-trash"
            }.html)
            add(BootstrapButton(Model.of("Annuller")) { UIMainServices.navigateTo("root/doctypes") }.apply {
                iconClasses = "fas fa-undo"
            }.html)
        }
    }

    private fun export(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        window.parent.open("/document/type/${model.data.id}/export")
    }

    private fun delete(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        hideTooltips()
        layoutForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                // TODO go through Service to update cache types !
                Modal.confirm(
                    Model.of("Vil du slette dokument type?"),
                    Model.of("""Documenttype navn: ${model.data.name}""")
                ) {
                    UIMainServices.backend.deleteDocumentType(model.data) { type: String ->
                        UIMainServices.navigateTo("root/doctypes")
                        if (type.equals("OK")) {
                            UIMainServices.alerts.infoAdd("""Har slettet dokumenttype: ${model.data.name} Id: (${model.data.id})""")
                            UIMainServices.documentTypeService.refresh()
                        }
                        else UIMainServices.alerts.errorAdd("""Fejl ved sletning af dokumenttype: ${model.data.name} Id: (${model.data.id}) Error: ${type}""")
                    }
                }
                UIMainServices.documentTypeService.refresh()
            }
            )
        })
    }


    private fun save(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        hideTooltips()
        layoutForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                // TODO go through Service to update cache types !
                UIMainServices.documentTypeService.refresh()
                UIMainServices.backend.saveDocumentType(model.data) { type: DocumentType ->
                    UIMainServices.documentTypeService.refresh()
                    UIMainServices.navigateTo("root/doctypes")
                    UIMainServices.alerts.infoAdd(buildAlertMessage(type))
                }
            }
            )
        })
    }

    private fun saveOnly(@Suppress("UNUSED_PARAMETER") bootstrapButton: BootstrapButton) {
        UIMainServices.alerts.clearAlerts()
        hideTooltips()
        layoutForm.processForm(onOk = {
            mainForm.processForm(onOk = {
                // TODO go through Service to update cache types !
                UIMainServices.backend.saveDocumentType(model.data) { type: DocumentType ->
                    UIMainServices.documentTypeService.refresh()
                    model.data = type
                    UIMainServices.alerts.infoAdd(buildAlertMessage(type))
                }
            })
        })
    }

    private fun buildAlertMessage(documentType: DocumentType): String {
        return """Dokument type: ${documentType.name} (Id: ${documentType.id}) er gemt"""
    }

    private val fieldDefTab = "Feltdefinitioner"
    private var currentTab: String = fieldDefTab

    private fun selectTab(tab: String) {
        // Sync values and render current tab
        layoutForm.processForm(onOk = {
            hideTooltips()
            currentTab = tab
            layoutForm.rebuild()
            tabsComponent.rerender()
        })
    }

    private fun hideTooltips() {
        BootstrapTooltipModifier.removeAll()
    }


    val mainForm = addChild(MainForm())

    inner class MainForm() : BootstrapForm<DocumentType>(model) {

        init {
            cssClassModifier("ps-4 pe-4")
            modifiers.add(StyleModifier {
                fontSize = "smaller"
                border = "1px solid darkcyan"
            })
            row {
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    input("name", Model.of("Navn"), model.property(DocumentType::name)).modifiers.add(
                        CssClassModifier("form-control-sm")
                    )
                    textArea(
                        "description",
                        Model.of("Beskrivelse"),
                        model.property(DocumentType::description)
                    ).modifiers.add(
                        CssClassModifier("form-control-sm")
                    )

                }
                col(ColWidth(ResponsiveSize.sm, 6)) {
                    row {
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "changed",
                                Model.of("Ændret"),
                                Model.ofGet { typeModel.data.lastUpdate.formatDateTime() }).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input("changedBy", Model.of("Ændret af"), model.property(DocumentType::updatedBy)).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "created",
                                Model.of("Oprettet"),
                                Model.ofGet { typeModel.data.created.formatDateTime() }).apply {
                                readOnly = true
                                modifiers.add(
                                    CssClassModifier("csaware-field form-control-sm")
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    val layoutForm = addChild(TabForm())
    var fieldsEditor: FieldDefinitionsEditor? = null

    private fun currentLayout(): DocumentLayout? =
        model.data.layout.find { it.name == currentTab }

    inner class TabForm() :
        BootstrapForm<DocumentLayout>(Model.ofGet { currentLayout() ?: DocumentLayout("", mutableListOf()) }) {
        fun clear() {
            removeAllChildren()
        }

        fun build() {
            if (currentTab == fieldDefTab) {
                addChild(FieldDefinitionsEditor(this@DocumentTypeEdit.model.property(DocumentType::fields)).also {
                    fieldsEditor = it
                })
            } else {
                // TODO sections and field list
                val layout = currentLayout()!!
                for ((index, section) in layout.sections.withIndex()) {
                    row(Model.of(section)) {
                        cssClassModifier("rp-type-edit-section")
                        col(ColWidth(ResponsiveSize.sm, 4)) {
                            input(
                                "sectionName$index",
                                Model.of("Sektionnavn"),
                                Model.of(section).property(Section::name)
                            )
                            // TODO Markdown ?
                            textArea(
                                "sectionDecription$index",
                                Model.of("Beskrivelse"),
                                model.property(Section::description)
                            )
                            textArea("sectionHelp$index", Model.of("Hjælp"), model.property(Section::help))
                        }
                        col(ColWidth(ResponsiveSize.sm, 8)) {
                            addChild(
                                SectionFieldsEditor(
                                    model.property(Section::fields),
                                    this@DocumentTypeEdit.model.property(DocumentType::fields)
                                ).apply { cssClassModifier("mt-2") })
                        }
                        group {
                            cssClassModifier("btn-group mb-4")
                            styleModifier { width = "auto" }
                            button(Model.of("Flyt sektion op "), { sectionMoveUp(index) }).apply {
                                color = BasicColor.info; iconClasses = "fas fa-arrow-up"; disabled = (index == 0)
                            }
                            button(Model.of("Flyt sektion ned ")) { sectionMoveDown(index) }.apply {
                                color = BasicColor.info; iconClasses = "fas fa-arrow-down"; disabled =
                                !(index < (layout.sections.size - 1))
                            }
                            button(Model.of("Slet sektionen ")) { sectionDelete(index) }.apply {
                                color = BasicColor.warning; iconClasses = "fas fa-trash"; disabled =
                                (layout.sections.size <= 1)
                            }
                        }
                        group {
                            cssClassModifier("btn-group ms-2 mb-4")
                            styleModifier { width = "auto" }
                            button(Model.of("Opret sektion nedenfor ")) { sectionCreateBelow(index) }.apply {
                                color = BasicColor.warning; iconClasses = "fas fa-plus"
                            }
                        }
                    }

                }
            }
        }

        fun rebuild() {
            clear();
            build();
            rerender()
        }
    }

    private fun sectionCreateBelow(index: Int) {
        UIMainServices.alerts.clearAlerts()
        layoutForm.processForm({
            currentLayout()?.sections?.add(index + 1, Section("Sektion ${index + 2}"))
            layoutForm.rebuild()
        })
    }

    private fun sectionMoveUp(index: Int) {
        UIMainServices.alerts.clearAlerts()
        layoutForm.processForm({
            val sections = currentLayout()?.sections
            if (index > 0 && sections != null) {
                val toMove = sections.removeAt(index)
                sections.add(index - 1, toMove)
            }
            layoutForm.rebuild()
        })
    }

    private fun sectionMoveDown(index: Int) {
        UIMainServices.alerts.clearAlerts()
        layoutForm.processForm({
            val sections = currentLayout()?.sections
            if (sections != null && index < sections.size - 1) {
                val toMove = sections.removeAt(index)
                sections.add(index + 1, toMove)
            }
            layoutForm.rebuild()
        })
    }

    private fun sectionDelete(index: Int) {
        UIMainServices.alerts.clearAlerts()
        layoutForm.processForm({
            val sections = currentLayout()?.sections
            if (sections != null && index < sections.size - 1) {
                sections.removeAt(index)
            }
            layoutForm.rebuild()
        })
    }

    inner class TabsComponent : KafffeComponent() {
        override fun KafffeHtmlBase.kafffeHtml() = ul {
            addClass("nav nav-tabs nav-fill mt-3")
            val layoutTabs = model.data.layout.map { it.name }
            val tabs = layoutTabs + fieldDefTab
            for (tab in tabs) {
                li {
                    addClass("nav-item")
                    a {
                        addClass("nav-link")
                        if (tab == currentTab) {
                            addClass("active")
                        }
                        text(tab)
                        withElement {
                            onclick = {
                                selectTab(tab)
                                it.preventDefault()
                            }
                        }
                    }
                }
            }
        }

    }

}

package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FieldDefinitionExtension
import dk.rheasoft.pitchboard.data.ValueList
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.FormLayout
import kafffe.bootstrap.form.checkbox
import kafffe.bootstrap.form.listeditor.StringListEditor
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.property

open class ExtEditor<ST : FieldDefinitionExtension>(parentConsumer: FormComponentConsumer<*>, model: Model<ST>) :
    FormLayout<ST>(parentConsumer, model) {

    protected fun FormComponentConsumer<*>.addValueListEditor(valueListModel: Model<ValueList>, idSuffix: String) {
        row(valueListModel) {
            labelStrategy = object : LabelStrategy {
                override fun label(key: String): Model<String> =
                    Model.of(
                        when (key) {
                            ValueList::values.name -> "Værdier"
                            ValueList::autocomplete.name -> "Brug liste til valg"
                            ValueList::isEnum.name -> "Tillad kun værdier fra liste"
                            else -> key
                        }
                    )
            }

            legend(Model.of("Værdisæt"))
            col() {
                addChild(StringListEditor(model.property(ValueList::values)))
            }
            col() {
                cssClassModifier("ms-4")
                checkbox(ValueList::autocomplete).apply { idInput = idInput + idSuffix }
                checkbox(ValueList::isEnum).apply { idInput = idInput + idSuffix }
            }
        }
    }
}

package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.FdeTextPair
import dk.rheasoft.pitchboard.data.TextPair
import kafffe.bootstrap.form.DropdownString
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLDivElement

class TextPairsEditor(val fieldId: String, model: Model<List<TextPair>>, val fdExt: FdeTextPair) : ListEditor<TextPair>(model) {

    val width1 : String by rerenderOnChange(fdExt.column1.width)
    val width2 : String by rerenderOnChange(fdExt.column2.width)

    override fun createNewElement(): TextPair {
        return TextPair(fdExt.column1.startValue, fdExt.column2.startValue)
    }



    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: TextPair, index: Int) {
        if (fdExt.column1.valueList.isEnum) {
            val valueModel = Model.of(element).property(TextPair::text1)
            val choices = fdExt.column1.valueList.values
            val dropdown = DropdownString("col_1_${fieldId}", valueModel, Model.of(choices))
            dropdown.apply {
                // we will need to keep the model in sync for document editors
                onchange { updateValueModel() }
                modifiers.add(StyleModifier { width = width1 })
            }
            add(dropdown.html)
        } else {
            val input1 =
                InputWithValueList("col_1_$index", Model.of(element).property(TextPair::text1), fdExt.column1.valueList)
            input1.apply {
                modifiers.add(StyleModifier { width = width1 })
                onCtrlEnterKey = {  addElement(index + 1) }
                onMoveDownKey = { onMoveKey(it, element, 1) }
                onMoveUpKey = { onMoveKey(it, element, -1) }
                if (index == focusAfterRerender) {
                    delayedFocus()
                }
            }
            add(input1.html)
        }

        if (fdExt.column2.valueList.isEnum) {
            val valueModel = Model.of(element).property(TextPair::text2)
            val choices = fdExt.column2.valueList.values
            val dropdown = DropdownString("col_2_${fieldId}", valueModel, Model.of(choices))
            dropdown.apply {
                // we will need to keep the model in sync for document editors
                onchange { updateValueModel() }
                modifiers.add(StyleModifier { width = width1 })
            }
            add(dropdown.html)
        } else {
            val input2 =
                InputWithValueList("col_2_$index", Model.of(element).property(TextPair::text2), fdExt.column2.valueList)
            input2.apply {
                modifiers.add(StyleModifier { width = width2 })
                input2.onMoveDownKey = { onMoveKey(it, element, 1) }
                input2.onMoveUpKey = { onMoveKey(it, element, -1) }
            }
            add(input2.html)
        }
    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        if (fdExt != null) {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = width1
                        style.minWidth = width1
                    }
                    text(fdExt.column1.label)
                }
                label {
                    withElement {
                        style.width = width2
                        style.minWidth = width2
                    }
                    text(fdExt.column2.label)
                }
            }
        }
    }

}

package pitchboard.ui.gruppe

import dk.rheasoft.pitchboard.data.EmailRole
import dk.rheasoft.pitchboard.data.Group
import dk.rheasoft.pitchboard.data.RoleType
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.Messages.Companion.formatDateTime
import kotlinx.dom.addClass
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService

class GroupAdmin() : KafffeComponent() {
    // TODO refresh userinformation
    val groups: List<Group> get() = UserInformationService.current.groups
    val currentGroup = Model.of(UserInformationService.current.selectedGroup)
    val titleLabel = addChild(Label(Model.of("Gruppe-administration")))

    val table = addChild(BootstrapTable.create<Group>(groups) {
        rowClickHandler = {group, _ -> groupRowClick(group)}

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))

        col(Model.of("Id"), { Label(it.id.toString()) })
        col(Model.of("Navn"), { Label(it.groupName) })
        col(Model.of("Beskrivelse"), { Label(it.description) })
        col(Model.of("Seneste opdatering"), { Label(it.lastUpdate.formatDateTime()) })
        col(Model.of("Opdateret af"), { Label(it.updatedBy) })
        col(Model.of("Oprettet"), { Label(it.created.formatDateTime()) })
    })

    var pageSize: Int = 10

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))

    val userRolesModel: Model<List<EmailRole>> = Model.of(listOf())
    val emailsModel: Model<List<String>> = Model.of(listOf())

    val groupUsersForm = addChild(GroupUsersForm(currentGroup, userRolesModel, emailsModel))
    val groupForm = addChild(GroupForm(currentGroup))

    fun refreshUserInfo() {
        UserInformationService.loadCurrent {
            rerenderRecursive()
        }
    }

    private fun loadUserEmails() {
        UIMainServices.backend.allUserEmails {
            emailsModel.data = it.emails
            groupUsersForm.rerenderRecursive()
        }
    }

    private fun loadUserRoles() {
        UIMainServices.backend.groupUserRoles(currentGroup.data) {
            userRolesModel.data = it.emailRoles
        }
    }


    init {
        refreshUserInfo()
        loadUserEmails()
        loadUserRoles()
    }

    private fun groupRowClick(grp: Group) {
        currentGroup.data = grp
        rerenderRecursive()
        loadUserRoles()
    }

    private fun newGroup(bootstrapButton: BootstrapButton) {
        currentGroup.data = Group()
        userRolesModel.data = listOf()
        rerenderRecursive()
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut =
        div {
            div {
                addClass("rp-content")
                div {
                    addClass("h4")
                    addClass("rp-content-header")
                    if (UserInformationService.hasAnyRole(RoleType.SYSADMIN)) {
                        add(
                            kafffe.bootstrap.BootstrapButton(kafffe.core.Model.of("Opret"), onClick = ::newGroup)
                                .apply {
                                    iconClasses = "fas fa-folder-plus"
                                    color = kafffe.bootstrap.BasicColor.primary
                                }.html
                        )
                    }
                    add(titleLabel.html.also { it.addClass("ms-2  me-auto") })
                }
                if (groups.size > 1) {
                    add(table.html)
                    if (groups.size > pageSize) {
                        add(paginator.html)
                    }
                }

                div {
                    addClass("mt-3")
                    add(groupForm.html)
                }

                if (!isCreatingGroup()) {
                    div {
                        addClass("mt-3")
                        add(groupUsersForm.html)
                    }
                }

            }
        }

    private inline fun isCreatingGroup(): Boolean = currentGroup.data.id <= 0

}

package pitchboard.ui.documents

import dk.rheasoft.pitchboard.data.PitchBoardDocument
import dk.rheasoft.pitchboard.data.QueryResult
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.BootstrapButton
import kafffe.bootstrap.BootstrapTable
import kafffe.bootstrap.BootstrapTableStyles
import kafffe.bootstrap.pagination.BootstrapPagination
import kafffe.bootstrap.pagination.Pager
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.messages.Messages.Companion.formatDateTime
import pitchboard.ui.UIMainServices
import kotlin.math.ceil

class DocumentList : KafffeComponent() {
    val titleLabel = addChild(Label(Model.of("Dokumenter")))

    val searchBar = addChild(SearchBar(::loadData))

    val table = addChild(BootstrapTable.create<PitchBoardDocument>(listOf()) {
        rowClickHandler = {document, _ -> showDocument(document)}

        addStyle(BootstrapTableStyles.striped)
        addStyle(BootstrapTableStyles.bordered)
        modifiers.add(CssClassModifier("csaware-hover"))

        col(Model.of("Id"), { Label(it.id.toString()) })
        col(Model.of("Titel"), { Label(it.name) })
        col(Model.of("Seneste opdatering"), { Label(it.lastUpdate.formatDateTime()) })
        col(Model.of("Opdateret af"), { Label(it.updatedBy) })
        col(Model.of("Type"), { Label(UIMainServices.documentTypeService[it.typeId]?.name ?: "") })
        col(Model.of("Kategori"), { Label(it.category.text1)})
        col(Model.of("Beskrivelse"), { Label(it.description) })
        col(Model.of("Oprettet"), { Label(it.created.formatDateTime()) })
//        col(Model.of("Første udsending"), { Label(it.firstBroadcast?.let { "${it.time.formatDateTime()} ${formatDuration(it.durationMinutes)}" } ?: "") })
        col(Model.of("Første udsendelse"), { Label(it.firstBroadcast?.let { "År: ${it.year} uge: ${it.week}"} ?: "") })
        col(Model.of("Handlinger"), {doc ->
            ofKafffeHtml{
                div {
                    addClass("btn-secondary")
                    button {
                        addClass("btn btn-secondary")
                        withElement {
                            id = "btnGroupDrop1"
                            type = "button"
                            setAttribute("data-bs-toggle", "dropdown")
                        }
                        text("Publicering... ")
                        i { addClass("fas fa-file-export") }
                    }
                    div {
                        addClass("dropdown-menu")
                        element?.setAttribute("aria-labelledby", "btnGroupDrop1")
                        val docType = UIMainServices.documentTypeService[doc.typeId ]
                        docType?.layout?.let { layouts ->
                            for (layout in layouts) {
                                a {
                                    addClass("dropdown-item")
                                    text(layout.name)
                                    withElement {
                                        onclick =
                                            { ev ->
                                                ev.preventDefault()
                                                ev.stopPropagation()
                                                UIMainServices.navigateTo("root/publish/${doc.id}/${layout.name}")
                                            }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        ).apply {rowClick = false}
    })

    var pageSize: Int = 25

    val pager = Pager(1)
    val paginator = addChild(BootstrapPagination(pager))
    init {
        pager.changeListeners.add({ loadData() })
        loadData()
    }

    fun loadData(filter: String = "") {
        UIMainServices.alerts.clearAlerts()
        val offset = pageSize * (pager.currentPage - 1)
        UIMainServices.backend.getPlanned(offset, pageSize, this::receiveData, filter)
    }

    private fun receiveData(response: QueryResult<PitchBoardDocument>) {
        val pageCount = ceil(response.result.size.toDouble() / pageSize.toDouble()).toInt()
        if (pager.nofPages != pageCount) {
            pager.nofPages = pageCount
        }
        table.data = response.result
    }



    fun showDocument(doc: PitchBoardDocument) {
        UIMainServices.navigateTo("root/document/${doc.id}")
    }

    fun showNewDocument() {
        UIMainServices.navigateTo("root/document/new")
    }

    override fun KafffeHtmlBase.kafffeHtml(): KafffeHtmlOut {
        return div {
            div {
                addClass("rp-content mt-2")
                div {
                    addClass("h4 rp-content-header d-flex gap-2")
                    add(BootstrapButton(Model.of("Opret"), onClick = {showNewDocument() }) .apply {
                        iconClasses = "fas fa-folder-plus"
                        color = BasicColor.primary
                    }.html)
                    add(titleLabel.html)
                    div {
                        withStyle { flexGrow = "1" }
                        add(searchBar.html)
                    }
                }
                add(table.html)
                div {
                    addClass("rp-content-footer ")
                    add(paginator.html)
                }
            }
        }
    }
}
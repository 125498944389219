package pitchboard.ui

import dk.rheasoft.pitchboard.data.*
import pitchboard.ui.backend.Backend
import kotlinx.browser.window

object UserInformationService {
    private var cachedAt = timestampNow().getTime() - 200000
    private var currenCache: UserInformation = UserInformation(User(), listOf(), mapOf(), -1L)

    val current: UserInformation
        get() {
            if ((timestampNow().getTime() - cachedAt) > 180000) {
                loadCurrent {}
            }
            return currenCache
        }

    fun loadCurrent(block: () -> Unit) {
        Backend(UserInformationService::errorHandler).getCurrentUserInformation {
            currenCache = it
            cachedAt = timestampNow().getTime()
            block()
        }
    }

    fun hasRole(role: RoleType) = current.hasRole(role)
    fun hasAnyRole(vararg roles: RoleType) = roles.any { hasRole(it) }
    fun hasAnyRole(roles: Iterable<RoleType>) = roles.any { hasRole(it) }

    private fun errorHandler(code: Int, text: String) {
        window.alert("User info failed: ($code) ${text}");
    }

    fun selectGroup(group: Group) {
        UIMainServices.backend.selectGroup(current.user, group) {
            window.location.reload()
        }
    }

}
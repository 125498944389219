package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Contextual
import kotlinx.serialization.Polymorphic
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.modules.subclass
import kotlin.collections.set

enum class PredefinedFields(val fieldDefinition: FieldDefinition) {
    title(
        FieldDefinition(
            "doc_title",
            "Titel",
            FieldType.TextOneline,
            description = "Dokumentets titel, bruges også i listning af dokumenter og fremsøgning"
        )
    ),
    description(
        FieldDefinition(
            "doc_description",
            "Beskrivelse",
            FieldType.TextPlain,
            description = "Dokumenttitel, bruges også i listning af dokumenter og fremsøgning"
        )
    ),
    firstBroadcastYearWeek(
        FieldDefinition(
            "doc_year_week_duration",
            "Første udsendelse",
            FieldType.YearWeekDuration,
            description = "Hvornår bliver det sendt første gang"
        )
    ),
    category(
        FieldDefinition(
            "gen_pgr_kategori",
            "Programkategori",
            FieldType.ChainedTextPair,
            description = "Hvilken kategori og underkategori hører udsendelsen under"
        )
    );

    val fieldId: String get() = fieldDefinition.id

    companion object {
        fun fieldIds() = values().map { it.fieldId }
        fun createList() = values().map { it.fieldDefinition }.toMutableList()
    }
}

/**
 * The properties are defined as var because, some may change during creation.
 */
@Serializable
data class PitchBoardDocument(
    var id: Long,
    /**
     * Reference  name to the type of metadata used to produce this document.
     */
    var typeId: Long,
    // name, description and firstBroadcast corrensponds to predifned field in data section (doc_name, doc_description, doc_first_broadcast)
    var name: String,
    var description: String = "",
    var firstBroadcast: YearWeekDuration? = null,
    var category: TextPair  = TextPair("", ""),

    @Contextual
    var created: Timestamp = timestampNow(),
    @Contextual
    var lastUpdate: Timestamp = timestampNow(),
    var updatedBy: String = "",
    var state: String = "initial", // TODO change to ENUM or Dropdown list?
    var owner: Long = -1,

    val data: MutableMap<String, DataElement> = mutableMapOf()
) {
    fun toJsonString() = RheaJson.toJsonString(this, serializer())

    fun isPlanned() : Boolean = firstBroadcast?.let {it.year > 0 || it.week > 0 } ?: false
    fun isIdea() = !isPlanned()

    /**
     * Gets the fieldID data as specific typed dataelement or set a default, if the field is not existing as the given type.
     */
    inline fun <reified T : DataElement> typedData(fieldId: String, default: T): T {
        val dataElement = data[fieldId]
        if (dataElement == null || dataElement !is T) {
            data[fieldId] = default
        }
        return data[fieldId] as T
    }

    companion object {
        fun fromJsonString(jsonStr: String) = RheaJson.fromJsonString(jsonStr, serializer())
    }
}

/**
 * QueryResult of PitchBoardDocument serialization
 */
private val qrDoc = QueryResult.serializer(PitchBoardDocument.serializer())
fun QueryResult<PitchBoardDocument>.toJsonString() =
    RheaJson.toJsonString(this, qrDoc)

fun fromPitchBoardDocumentJsonString(jsonStr: String): QueryResult<PitchBoardDocument> =
    RheaJson.fromJsonString(jsonStr, qrDoc)


@Polymorphic
interface DataElement

@Serializable
@SerialName("string")
data class StringData(var value: List<String> = listOf()) : DataElement {
    constructor(single: String) : this(listOf(single))
}

@Serializable
@SerialName("int")
data class IntData(var value: List<Int> = listOf()) : DataElement {
    constructor(single: Int) : this(listOf(single))
}

@Serializable
@SerialName("timestamp")
data class TimestampData(var value: List<@Contextual Timestamp> = listOf()) : DataElement {
    constructor(single: Timestamp) : this(listOf(single))
}

@Serializable
data class TimeAndPlace(
    @Contextual
    var from: Timestamp,
    @Contextual
    var to: Timestamp,
    var place: String = "",
    var comment: String = ""
)

@Serializable
@SerialName("time_place")
data class TimeAndPlaceData(var value: List<TimeAndPlace> = listOf()) : DataElement {
    constructor(single: TimeAndPlace) : this(listOf(single))
}

@Serializable
data class TimeAndDuration constructor(
    @Contextual
    var time: Timestamp,

    // TODO use a real duration class with unit.
    var durationMinutes: Int
)

@Serializable
@SerialName("time_duration")
data class TimeAndDurationData(var value: List<TimeAndDuration> = listOf()) : DataElement {
    constructor(single: TimeAndDuration) : this(listOf(single))
}

@Serializable
data class YearWeekDuration(
    var year: Int = 0,
    var week: Int = 0,
    var durationMinutes: Int = 0
)

@Serializable
@SerialName("year_week_duration")
data class YearWeekDurationData(var value: List<YearWeekDuration> = listOf()) : DataElement {
    constructor(single: YearWeekDuration) : this(listOf(single))
}

@Serializable
data class TextPair(var text1: String, var text2: String)

@Serializable
@SerialName("text_pair")
data class TextPairData(var value: List<TextPair> = listOf()) : DataElement {
    constructor(single: TextPair) : this(listOf(single))
}

@Serializable
data class TextTriple(var text1: String, var text2: String, var text3: String)

@Serializable
@SerialName("text_triple")
data class TextTripleData(var value: List<TextTriple> = listOf()) : DataElement {
    constructor(single: TextTriple) : this(listOf(single))
}

val serializationModulePitchBoardDocument = SerializersModule {
    polymorphic(DataElement::class, null) {
        subclass(StringData::class)
        subclass(IntData::class)
        subclass(TimestampData::class)
        subclass(TimeAndPlaceData::class)
        subclass(TimeAndDurationData::class)
        subclass(TextPairData::class)
        subclass(TextTripleData::class)
        subclass(YearWeekDurationData::class)
    }
    contextual(Timestamp::class, TimestampSerializer)
}


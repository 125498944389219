package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Serializable

/**
 * Type use in the Frontend and API, not directly store in the DB.
 */

@Serializable
data class EmailRole (var email: String = "", var roleType: RoleType = RoleType.EDIT )
/**
 * EmailRoleType json serialization
 */
fun EmailRole.toJsonString() = RheaJson.toJsonString(this, EmailRole.serializer())
fun EmailRole.Companion.fromJsonString(jsonStr: String): EmailRole =
    RheaJson.fromJsonString(jsonStr, serializer())

@Serializable
data class EmailRoles (var emailRoles: List<EmailRole> = listOf() )
/**
 * EmailAndRoles json serialization
 */
fun EmailRoles.toJsonString() = RheaJson.toJsonString(this, EmailRoles.serializer())
fun EmailRoles.Companion.fromJsonString(jsonStr: String): EmailRoles =
    RheaJson.fromJsonString(jsonStr, serializer())

@Serializable
data class UserEmails (var emails: List<String> = listOf() )
/**
 * UserEmails json serialization
 */
fun UserEmails.toJsonString() = RheaJson.toJsonString(this, UserEmails.serializer())
fun UserEmails.Companion.fromJsonString(jsonStr: String): UserEmails =
    RheaJson.fromJsonString(jsonStr, serializer())
package pitchboard.ui.documents

import dk.rheasoft.pitchboard.data.DocumentType
import dk.rheasoft.pitchboard.data.PitchBoardDocument
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.form.Dropdown
import kafffe.bootstrap.form.FormDialog
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.Model
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import pitchboard.ui.UIMainServices

class NewDocumentDialog(model : Model<PitchBoardDocument>) : FormDialog<PitchBoardDocument>(Model.of("Nyt dokument"), model) {
    val typeModel: Model<DocumentType> = Model.ofGetSet(
        {UIMainServices.documentTypeService[model.data.typeId]!!},
        {value -> model.data.typeId = value.id }
    )

    init {
        cssClassModifier("hgap-3")
        modal.modifiersContent.add(CssClassModifier("bg-info"))
        val selection =
            DocumentTypeSelection("dokumenttype", typeModel, UIMainServices.documentTypeService.types)
        selection.required = true
        decorateAndAdd(Model.of("Dokumenttype"), selection)
        submit(Model.of("Opret")).color = BasicColor.primary
        cancel(Model.of("Annuller")).color = BasicColor.secondary
    }

    override fun onCancel() {
        detach()
        UIMainServices.navigateTo("root/documents")
    }

    class DocumentTypeSelection(idInput: String, valueModel: Model<DocumentType>, choiceModel: Model<List<DocumentType>>) :
        Dropdown<DocumentType>(idInput, valueModel, choiceModel) {

        override fun display(choice: DocumentType) = choice.name
        override fun valueToString(value: DocumentType) = value.id.toString()
        override fun valueFromString(strValue: String) = UIMainServices.documentTypeService[strValue.toLong()]!!
    }
}
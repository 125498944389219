package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.*
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.KafffeHtml
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier
import kafffe.core.property
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.getDate
import pitchboard.ui.documents.fieldeditors.DatePickerHelper.timestampInputModifier

class TimeAndPlaceListEditor(val fieldId: String, model: Model<List<TimeAndPlace>>, val fdExt: FdeTimeAndPlace) :
    ListEditor<TimeAndPlace>(model) {

    var minutesStep: Int by rerenderOnChange(15)

    // Leave room for buttons
    var widthFromTime: String by rerenderOnChange(fdExt.from.width)
    var widthToTime: String by rerenderOnChange(fdExt.to.width) // * 2 (from and to)
    var widthMinTime: String by rerenderOnChange("8rem")
    var widthPlace: String by rerenderOnChange(fdExt.place.width)
    var widthComment: String by rerenderOnChange(fdExt.comment.width)


    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        if (fdExt != null) {
            div {
                addClass("form-inline")
                label {
                    withElement {
                        style.width = widthFromTime
                        style.minWidth = widthMinTime
                    }
                    text(fdExt.from.label)
                }.element!!
                label {
                    withElement {
                        style.width = widthToTime
                        style.minWidth = widthMinTime
                    }
                    text(fdExt.to.label)
                }.element!!
                label {
                    withElement {
                        style.width = widthPlace
                    }
                    text(fdExt.place.label)
                }.element!!
                label {
                    withElement {
                        style.width = widthComment
                    }
                    text(fdExt.comment.label)
                }.element!!
            }
        }
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: TimeAndPlace, index: Int) {
        var toElement: HTMLInputElement? = null
        input {
            addClass("form-control")
            withElement {
                type = "text"
                style.width = widthFromTime
                style.minWidth = widthMinTime
                timestampInputModifier(minutesStep).modify(this)
                value = DatePickerHelper.format(element.from)
                onchange = {
                    if (value.isNotBlank()) {
                        adjustTo(this, toElement!!)
                        getDate()?.let {element.from = it }
                        toElement!!.getDate()?.let { element.to = it}
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }.element!!
        toElement = input {
            addClass("form-control")
            withElement {
                type = "text"
                style.width = widthToTime
                style.minWidth = widthMinTime
                timestampInputModifier(minutesStep).modify(this)
                value = DatePickerHelper.format(element.to)
                onchange = {
                    if (value.isNotBlank()) {
                        getDate()?.let { element.to = it }
                    }
                }
                onkeydown = { keyEvent -> elementKeyHandler(keyEvent, element) }
            }
        }.element

        val inputPlace = InputWithValueList(
            "${fieldId}_place_$index",
            Model.of(element).property(TimeAndPlace::place),
            fdExt.place.valueList
        )
        inputPlace.modifiers.add(StyleModifier {
            width = widthPlace
        })
        inputPlace.onCtrlEnterKey = { addElement(index + 1)}
        inputPlace.onMoveDownKey = { onMoveKey(it, element, 1) }
        inputPlace.onMoveUpKey = { onMoveKey(it, element, -1) }
        if (index == focusAfterRerender) {
            inputPlace.delayedFocus()
        }
        add(inputPlace.html)

        val inputComment = InputWithValueList(
            "${fieldId}_comment_$index",
            Model.of(element).property(TimeAndPlace::comment),
            fdExt.comment.valueList
        )
        inputComment.modifiers.add(StyleModifier {
            width = widthComment
        })
        inputComment.onCtrlEnterKey = { addElement(index + 1)}
        inputComment.onMoveDownKey = { onMoveKey(it, element, 1) }
        inputComment.onMoveUpKey = { onMoveKey(it, element, -1) }
        add(inputComment.html)
    }

    override fun createNewElement(): TimeAndPlace {
        return TimeAndPlace(
            timestampNow(),
            timestampNow(),
            fdExt.place.startValue,
            fdExt.comment.startValue,
        )
    }

    private fun adjustTo(from: HTMLInputElement, to: HTMLInputElement) {
        console.log("From: ${from.getDate()} to: ${to.getDate()}")
        val fromDate = from.getDate()
        val toDate = to.getDate()
        if (fromDate != null) {
            if (toDate == null || (fromDate.isAfter(toDate))) {
                val newTo = DatePickerHelper.format(fromDate.plusHours(1))
                console.log("Setting to: ${newTo}")
                to.value = newTo
            }
        }
    }

}

package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.ChainedValue
import dk.rheasoft.pitchboard.data.FdeChainedTextPair
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.bootstrap.form.listeditor.StringListEditor
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import org.w3c.dom.HTMLDivElement

class ChainedTextPairExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeChainedTextPair>) :
    ExtEditor<FdeChainedTextPair>(parentConsumer, extModel) {

    private val currentModel: Model<ChainedValue> = Model.of(ChainedValue())
    private val secondaryEditor = StringListEditor(currentModel.property(ChainedValue::values))

    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeChainedTextPair::label1.name -> "Navn 1"
                        FdeChainedTextPair::label2.name -> "Navn 2"
                        FdeChainedTextPair::width1.name -> "Breddde 1"
                        FdeChainedTextPair::width2.name -> "Bredde 2"
                        else -> key
                    }
                )
        }

        row {
            col(ColWidth(ResponsiveSize.sm, 6)) {
                input(FdeChainedTextPair::label1)
                input(FdeChainedTextPair::width1)
            }
            col(ColWidth(ResponsiveSize.sm, 6)) {
                input(FdeChainedTextPair::label2)
                input(FdeChainedTextPair::width2)
            }
        }
        row {
            col(ColWidth(ResponsiveSize.sm, 6)) {
                label(Model.of("Primær værdier"))
                addChild(PrimaryListEditor(model.property(FdeChainedTextPair::values)))
            }
            col(ColWidth(ResponsiveSize.sm, 6)) {
                label(Model.of("Sekundær værdier: ")).cssClassModifier("me-2")
                label(currentModel.property(ChainedValue::value)).cssClassModifier("bg-secondary")
                addChild(secondaryEditor)
            }
        }
    }

    protected fun setCurrent(primaryValue: String) {
        secondaryEditor.updateValueModel()
        currentModel.data = ChainedValue()
        if (!primaryValue.isNullOrBlank()) {
            model.data.values
                .find { it.value.equals(primaryValue) }
                ?.let { chainedValue ->
                    currentModel.data = chainedValue
                }
        }
        secondaryEditor.reloadListFromModel()
    }

    inner class PrimaryListEditor(model: Model<List<ChainedValue>>) : ListEditor<ChainedValue>(model) {

        override fun createNewElement(): ChainedValue = ChainedValue("")

        override fun KafffeHtml<HTMLDivElement>.elementEditor(listElement: ChainedValue, index: Int) {
            input {
                addClass("form-control")
                withElement {
                    value = listElement.value
                    type = "text"
                    onchange = {
                        if (value.isNotBlank()) {
                            currentList[index].value = value
                            setCurrent(value)
                        }
                    }
                    onkeydown = { keyEvent -> elementKeyHandler(keyEvent, listElement) }
                    onfocus = {
                        setCurrent(listElement.value)
                    }
                    if (index == focusAfterRerender) {
                        delayedFocus()
                    }
                }
            }
        }
    }
}
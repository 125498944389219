package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FieldDefinition
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormDialog
import kafffe.bootstrap.form.textArea
import kafffe.core.*
import kafffe.core.modifiers.CssClassModifier
import kafffe.core.modifiers.CssClassModifier.Companion.cssClassModifier
import kafffe.core.modifiers.StyleModifier
import pitchboard.ui.documents.fieldeditors.MarkDownInput
import pitchboard.ui.documenttypes.FieldTypeDropdown

class FieldDefinitionDialog(
    model: Model<FieldDefinition>,
    val isNew: Boolean
) : FormDialog<FieldDefinition>(Model.of(
    dialogTitle(
        isNew
    )
), model) {

    val extensionEditor = ExtensionEditor(this, model)

    init {
        cssClassModifier("hgap-3")
        modal.modifiersContent.add(CssClassModifier("bg-info"))
        modal.modifiersContent.add(StyleModifier {
            maxHeight = "90vh"
            overflowY = "auto"
        })
        modal.modifiersModal.add(StyleModifier {
            maxWidth = "95vw"
            width = "200rem"
        })
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FieldDefinition::id.name -> "Id"
                        FieldDefinition::label.name -> "Navn"
                        FieldDefinition::type.name -> "Type"
                        FieldDefinition::description.name -> "Beskrivelse"
                        FieldDefinition::help.name -> "Hjælp"
                        else -> key
                    }
                )
        }
        row {
            col(ColWidth(ResponsiveSize.md, 3)) {
                input(FieldDefinition::id).apply {
                    required = true
                    readOnly = !isNew
                }
                input(FieldDefinition::label).required = true
                decorateAndAddComponent(
                    labelStrategy.label(FieldDefinition::type.name),
                    FieldTypeDropdown(
                        "typeSel",
                        model.property(FieldDefinition::type)
                    ) { newType ->
                        extensionEditor.changeType(newType)
                    }
                )
                textArea(FieldDefinition::description)
            }
            col(ColWidth(ResponsiveSize.md, 9)) {
                addChild(extensionEditor)
            }
        }

        val inp = MarkDownInput(
            FieldDefinition::help.name,
            model.property(FieldDefinition::help)
        )
        decorateAndAddComponent(labelStrategy.label(FieldDefinition::help.name), inp)

        submit(Model.of(if (isNew) "Tilføj" else "Opdater")).color = BasicColor.primary
        cancel(Model.of("Annuller")).color = BasicColor.secondary
    }

    override fun onCancel() {
        detach()
    }

    companion object {
        private fun dialogTitle(isNew: Boolean) = (if (isNew) "Ny" else "Edit") + " feltdefinition"
    }
}


package dk.rheasoft.pitchboard.data

import kotlin.js.Date

actual typealias Timestamp = Date

actual fun Timestamp.toJsonFormat(): String = this.toJSON().toString()

actual fun timestampParse(timeStr: String): Timestamp = Date(timeStr)

actual fun timestampNow(): Timestamp = Date(Date.now())
actual fun Timestamp.plusDays(days: Int): Timestamp
        = Date(getTime() + (days * 24 * 60 * 60 * 1000))

actual fun Timestamp.plusHours(hours: Int): Timestamp
     = Date(getTime() + (hours * 60 * 60 * 1000))

actual fun Timestamp.plusMinutes(minutes: Int): Timestamp
    = Date(getTime() + (minutes * 60 * 1000))

actual fun Timestamp.isAfter(other: Timestamp): Boolean {
    return this.getTime() > other.getTime()
}
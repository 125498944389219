package pitchboard.ui.documenttypes.fielddefinition

import dk.rheasoft.pitchboard.data.FdeColumn
import dk.rheasoft.pitchboard.data.FdeColumnWithValueList
import dk.rheasoft.pitchboard.data.FdeTimeAndPlace
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.form.FormComponentConsumer
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.property

class TimeAndPlaceExtEditor(parentConsumer: FormComponentConsumer<*>, extModel: Model<FdeTimeAndPlace>) :
    ExtEditor<FdeTimeAndPlace>(parentConsumer, extModel) {
    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        FdeColumnWithValueList::label.name -> "Navn"
                        FdeColumnWithValueList::startValue.name -> "Start værdi"
                        FdeColumnWithValueList::width.name -> "Bredde"
                        FdeColumnWithValueList::valueList.name -> "Værdier"
                        else -> key
                    }
                )
        }

        row {
            col(ColWidth(ResponsiveSize.sm, 4)) {
                addColumnEditor(Model.of("Fra"), model.property(FdeTimeAndPlace::from), "from")
                addColumnEditor(Model.of("Til"), model.property(FdeTimeAndPlace::to), "to")
            }
            col(ColWidth(ResponsiveSize.sm, 4)) {
                addColumnEditor(Model.of("Sted"), model.property(FdeTimeAndPlace::place), "place")
            }
            col(ColWidth(ResponsiveSize.sm, 4)) {
                addColumnEditor(Model.of("Kommentar"), model.property(FdeTimeAndPlace::comment), "comment")
            }
        }
    }

    private fun FormComponentConsumer<*>.addColumnEditor(
        groupLabel: Model<String>,
        columnModel: Model<FdeColumn>,
        idSuffix: String
    ) {
        group(columnModel) {
            useBorderedLegend(groupLabel)
            input(FdeColumn::label).apply{idInput = idInput + idSuffix}
            input(FdeColumn::width).apply{idInput = idInput + idSuffix}
        }
    }

    private fun FormComponentConsumer<*>.addColumnEditor(
        groupLabel: Model<String>,
        columnModel: Model<FdeColumnWithValueList>,
        idSuffix: String
    ) {
        group(columnModel) {
            useBorderedLegend(groupLabel)
            input(FdeColumnWithValueList::label).apply{idInput = idInput + idSuffix}
            input(FdeColumnWithValueList::startValue).apply{idInput = idInput + idSuffix}
            input(FdeColumnWithValueList::width).apply{idInput = idInput + idSuffix}
            addValueListEditor(model.property(FdeColumnWithValueList::valueList), idSuffix)
        }

    }
}


package pitchboard.ui.gruppe

import dk.rheasoft.pitchboard.data.RoleType
import kafffe.bootstrap.form.Dropdown
import kafffe.core.Model

class RoleTypeDropdown(
    val fieldId: String,
    val valueModel: Model<RoleType>
) : Dropdown<RoleType>(fieldId, valueModel, Model.of(RoleType.values().toList() - RoleType.SYSADMIN)) {

        override fun display(choice: RoleType): String = valueToString(choice)

        override fun valueFromString(strValue: String): RoleType =
            RoleType.values().find{strValue == valueToString(it)} ?: RoleType.EDIT

        override fun valueToString(value: RoleType): String =
            when (value) {
                RoleType.SYSADMIN -> "Systemadministrator"
                RoleType.GROUPADMIN -> "Administrator"
                RoleType.EDIT -> "Adgang til redigering"
                RoleType.VIEW -> "Adgang til læsning"
            }


    }
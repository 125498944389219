package pitchboard.ui.documents.fieldeditors

import dk.rheasoft.pitchboard.data.FdeChainedTextPair
import dk.rheasoft.pitchboard.data.TextPair
import kafffe.bootstrap.form.DropdownString
import kafffe.bootstrap.form.listeditor.ListEditor
import kafffe.core.*
import kafffe.core.modifiers.StyleModifier
import kafffe.core.modifiers.onchange
import org.w3c.dom.HTMLDivElement

class ChainedTextPairsEditor(val fieldId: String, model: Model<List<TextPair>>, val fdExt: FdeChainedTextPair) : ListEditor<TextPair>(model) {

    val width1 : String by rerenderOnChange(fdExt.width1)
    val width2 : String by rerenderOnChange(fdExt.width2)

    override fun createNewElement(): TextPair {
        return TextPair(fdExt.values.first().value, fdExt.values.first().values.first())
    }

    override fun KafffeHtml<HTMLDivElement>.elementEditor(element: TextPair, index: Int) {
        val dropdown1 = DropdownString("${fieldId}_dropwdown_$index", Model.of(element).property(TextPair::text1), Model.of(fdExt.values.map { it.value } ))
        val dropdown2 = DropdownString("${fieldId}_dropwdown_$index", Model.of(element).property(TextPair::text2), Model.ofGet { fdExt.values.find { it.value.equals(dropdown1.model.data) }?.values ?: listOf() })
        if (dropdown1.model.data.isNullOrBlank() && !dropdown1.choicesModel.data.contains("")) {
            // Initialize to first choice if nothing choosen
            dropdown1.model.data = dropdown1.choicesModel.data.firstOrNull() ?: ""
            dropdown2.model.data = dropdown2.choicesModel.data.firstOrNull() ?: ""
        }
        dropdown1.apply {
            modifiers.add(StyleModifier { width = width1 })
            onchange {
                updateValueModel()
                dropdown2.model.data = dropdown2.choicesModel.data.firstOrNull() ?: ""
                dropdown2.rerender()
            }
        }
        dropdown2.apply {
            modifiers.add(StyleModifier { width = width2 })
            onchange {
                updateValueModel()
            }
        }
        add(dropdown1.html)
        add(dropdown2.html)
    }

    override fun KafffeHtml<HTMLDivElement>.columnHeaders() {
        div {
            addClass("form-inline")
            label {
                withElement {
                    style.width = width1
                    style.minWidth = width1
                }
                text(fdExt.label1)
            }
            label {
                withElement {
                    style.width = width2
                    style.minWidth = width2
                }
                text(fdExt.label2)
            }
        }
    }

}

package pitchboard.ui.gruppe

import dk.rheasoft.pitchboard.data.ConfigurationValues
import dk.rheasoft.pitchboard.data.Group
import dk.rheasoft.pitchboard.data.RoleType
import kafffe.bootstrap.BasicColor
import kafffe.bootstrap.Modal
import kafffe.bootstrap.form.BootstrapForm
import kafffe.bootstrap.form.textArea
import kafffe.core.LabelStrategy
import kafffe.core.Model
import kafffe.core.modifiers.StyleModifier
import pitchboard.ui.UIMainServices
import pitchboard.ui.UserInformationService

class GroupForm(model: Model<Group>) : BootstrapForm<Group>(model) {

    private val isNew: Boolean get() = model.data.id <= 0

    init {
        labelStrategy = object : LabelStrategy {
            override fun label(key: String): Model<String> =
                Model.of(
                    when (key) {
                        Group::groupName.name -> "Navn"
                        Group::description.name -> "Beskrivelse"
                        else -> key
                    }
                )
        }
        group {
            useBorderedLegend(Model.ofGet { if (isNew) "Opret ny gruppe" else "Rediger gruppe" })
            group {
                submit(Model.ofGet { if (isNew) "Opret" else "Gem" }).apply {
                    iconClasses = "fas fa-save"
                    color = BasicColor.primary
                }
                if (UserInformationService.hasRole(RoleType.SYSADMIN) && !isNew) {
                    val deleteBtn = submit(label = Model.of("Slet"), onOk = { deleteGroup() }).apply {
                        iconClasses = "fas fa-trash"
                        color = BasicColor.primary
                    }
                    deleteBtn.modifiers.add(StyleModifier { if (isNew) display = "none" })
                    deleteBtn.modifiers.add(StyleModifier { marginLeft = "1em" })
                }
            }
            input(Group::groupName)
            textArea(Group::description)
        }

        onSubmitOk = {
            UIMainServices.alerts.infoSet("Gemmer...")
            UIMainServices.backend.saveGroup(model.data) { newGroup: Group ->
                UIMainServices.alerts.infoSet("Gruppe gemt")
                if (isNew) {
                    val configurationValues = ConfigurationValues()
                    configurationValues.owner = newGroup.id
                    UIMainServices.backend.saveConfigurationValues(configurationValues) {
                        UIMainServices.alerts.infoAdd("Ny konfiguration oprettet...")
                    }
                }
                model.data = newGroup
                parentOfType(GroupAdmin::class)?.rerenderRecursive()
            }
        }
    }

    private fun deleteGroup() {
        var numberOfDocuments: Int = 0
        var numberOfTypes: Int = 0
        UIMainServices.backend.countDocumentsInGroup(model.data.id) {
            numberOfDocuments = it
            UIMainServices.backend.countDocumentTypesInGroup(model.data.id) {
                numberOfTypes = it
                val configurationValues = UIMainServices.configurationService.current()
                val confirmString = """Dokumenter i gruppen: $numberOfDocuments
                    | Typer i gruppen: $numberOfTypes""".trimMargin()
                Modal.confirm(
                    Model.of("Vil du slette gruppe. Navn: ${model.data.groupName} (Id: ${model.data.id})"),
                    Model.of(confirmString.toString())
                ) {

                    UIMainServices.alerts.infoAdd("Delete of elements starts ...")
                    UIMainServices.backend.deleteGroup(model.data) {
                        UIMainServices.alerts.infoAdd("Sletning af gruppe gennemført")
                        if ("ok".equals(it, true)) {
                            val current = UserInformationService.current
                        }
                    }

                    // TODO reset group cache and select first in list
                    UserInformationService.selectGroup(model.data)
                }
            }
        }
    }

}
package dk.rheasoft.pitchboard.data

import kotlinx.serialization.*
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder

val timestampFormat = "yyyy-MM-dd'T'HH:mm:ss[.SSS]XX"

/**
 * We expect that JS and JVM provides a Date with support for a format "
 */
expect class Timestamp

expect fun Timestamp.toJsonFormat(): String
expect fun Timestamp.plusDays(days: Int): Timestamp
expect fun Timestamp.plusHours(hours: Int): Timestamp
expect fun Timestamp.plusMinutes(minutes: Int): Timestamp

expect fun Timestamp.isAfter(other: Timestamp): Boolean

expect fun timestampParse(timeStr: String): Timestamp

expect fun timestampNow(): Timestamp

@OptIn(ExperimentalSerializationApi::class)
@Serializer(forClass = Timestamp::class)
object TimestampSerializer: KSerializer<Timestamp> {

    override val descriptor: SerialDescriptor = PrimitiveSerialDescriptor("RSTimestamp", PrimitiveKind.STRING)

    override fun serialize(encoder: Encoder, value: Timestamp) {
        encoder.encodeString(value.toJsonFormat())
    }

    override fun deserialize(decoder: Decoder): Timestamp {
        return timestampParse(decoder.decodeString())
    }
}
package pitchboard.ui

import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase

class About : KafffeComponent() {
    data class UsedSw(val name: String, val link: String)

    val useSw = listOf<UsedSw>(
            UsedSw("Kotlin", "https://kotlinlang.org/"),
            UsedSw("Kotson", "https://github.com/SalomonBrys/Kotson"),
            UsedSw("Fuel", "https://fuel.gitbook.io"),
            UsedSw("Sparkjava", "http://sparkjava.com/"),
            UsedSw("JSON Web Token","https://www.jsonwebtoken.io/"),
            UsedSw("Bootstrap", "https://getbootstrap.com/docs/4.0/about/overview/"),
            UsedSw("Fontawesome", "https://fontawesome.com"),
//            UsedSw("Apache POI", "https://poi.apache.org/")
    )

    override fun KafffeHtmlBase.kafffeHtml() =
            div {
            addClass("card mt-2")
            h3 {
                addClass("rp-content-header text-center")
                + "Om RheaSoft PitchBoard"
            }
            div {
                addClass("card-body row")
                div {
                    addClass("col-lg-7 col-md-12")
                    p {
                       + "RheaSoft PitchBoard er et værktøj til oprettelse og vedlighold og publicering af information om TV progammer for lokal TV"
                    }
                    p {
                        a("https://RheaSoft.dk") {
                            element?.target = "_blank"
                            + "RheaSoft aps"
                        }
                    }
                    h2 {
                        text("Used software")
                    }
                    ul {
                        useSw.forEach {
                            li {
                                a(it.link) {
                                    element?.target = "_blank"
                                    text(it.name)
                                }
                            }
                        }
                    }

                }
            }
        }

}
package pitchboard.ui.dashboard

import dk.rheasoft.pitchboard.data.ConfigurationValues
import kafffe.bootstrap.ColWidth
import kafffe.bootstrap.ResponsiveSize
import kafffe.bootstrap.bootstrapCol
import kafffe.bootstrap.bootstrapRow
import kafffe.core.KafffeComponent
import kafffe.core.KafffeHtmlBase
import kafffe.core.Model

class CategoryOverviewPanel(
    val config: ConfigurationValues,
    val categoryCoverageModel: Model<Map<String, Map<String, Int>>>
) : KafffeComponent() {

    override fun KafffeHtmlBase.kafffeHtml() =

        bootstrapRow {
            bootstrapCol(ColWidth(ResponsiveSize.sm, 12)) {
                h4 { text( "Kategori dækning")}
                div {
                    val categoryValueList = config.categoryValueList.filter { it.weight > 0 }
                    val columnWdth= categoryValueList.map { "${it.weight}fr" }.joinToString(" ")
                    withStyle {
                        padding = "1px"
                        display= "grid"
                        this.setProperty("grid-template-columns", columnWdth )
                        this.setProperty("gap", "1rem")
                    }
                    withElement {
                        id = ""
                    }
                    for (category in categoryValueList) {
                        val categoryCoverage = categoryCoverageModel.data.get(category.name)
                        var overflow: Boolean = false
                        div {
                            withStyle {
                                border = "1px green solid"
                                height = "15vh"
                                position = "relative"
                            }
                            if (categoryCoverage != null) {
                                var bottomPos = 0
                                overflow = false
                                for (state in config.stateValueList.reversed())  {
                                    categoryCoverage[state.name]?.let { minutes: Int ->
                                        var heigthPct = (100 * minutes) / config.minutesPrYearForCategory(category.name)
                                        if ( (bottomPos + heigthPct) > 100) { // safeguard against overflow
                                            heigthPct = 100 - bottomPos
                                            overflow = true
                                        }
                                        div {
                                            withStyle {
                                                position = "absolute"
                                                bottom = "$bottomPos%"
                                                width = "100%"
                                                height = "$heigthPct%"
                                                backgroundColor = "${state.backgroundColor}"
                                                color = "${state.color}"
                                            }
                                            bottomPos += heigthPct
                                        }
                                    }
                                }
                            }
                            div {
                                withStyle {
                                    top = "0.5rem"
                                    left = "0.5rem"
                                    position = "absolute"
                                }
                                var label = if (overflow) """${category.name} > 100%""" else category.name
                                text (label)
                            }

                        }
                    }
                }
            }
        }

}
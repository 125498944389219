package dk.rheasoft.pitchboard.data

import kotlinx.serialization.Serializable

@Serializable
data class CategoryCoverageRecordFromDatabase (
    val id: Long,
    val name: String,
    val state: String,
    val category: String,
    val sub_category: String,
    val year: Int,
    val week: Int,
    val duration: Int)
{

}
/**
 * QueryResult of CategoryCoverageRecordFromDatabase serialization
 */
private val qrDoc = QueryResult.serializer(CategoryCoverageRecordFromDatabase.serializer())

fun QueryResult<CategoryCoverageRecordFromDatabase>.toJsonString() =
    RheaJson.toJsonString(this, qrDoc)

fun fromCategoryCoverageRecordsJsonString(jsonStr: String): QueryResult<CategoryCoverageRecordFromDatabase> =
    RheaJson.fromJsonString(jsonStr, qrDoc)
